/* eslint-disable no-unused-vars */
type HTTP_METHOD = 'POST' | 'GET';

export interface HttpService {
  POST: (url: string, body: any) => Promise<any>;
  GET: (url: string, querystring?: string) => Promise<any>;
}

//const APIURL = 'http://localhost:5168';
// const APIURL = 'https://localhost:44380';
const APIURL = '';

const httpService = (): HttpService => {
  const defaults = {
    baseURL: APIURL,
    error: {
      code: 'INTERNAL_ERROR',
      message: 'Something went wrong. Please check your internet connection or contact our support.',
      status: 503,
      data: {}
    },
    retry: true
  };

  const api = async (method: HTTP_METHOD, url: string, variables: any) => {
    return new Promise((resolve, reject) => {
      var fetchUrl = APIURL + url;
      if (method === 'GET' && variables !== undefined && variables.length > 0) fetchUrl = fetchUrl + variables;

      fetch(fetchUrl, {
        method: method.toString(),
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/plain'
        },
        body: method === 'POST' ? variables : undefined
      }).then(
        (response) => {
          if (response.ok) {
            let responseData = response.json();

            resolve(responseData);
          } else {
            reject(defaults.error);
          }
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  };

  // TODO: Better yet refactor the interface and refine the api call
  return {
    GET: (...args) => api('GET', args[0], args[1]),
    POST: (...args) => api('POST', args[0], args[1])
  };
};

export default httpService;
