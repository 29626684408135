/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AgentDetailViewModel, Itinerary } from '../../helpers/types';
import APIService from '../../services/services';
import Modal from 'react-modal';
import queryString from 'query-string';
import { useParams, useSearchParams } from 'react-router-dom';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import PhoneForm from '../layout/PhoneForm';
import EmailForm from '../layout/EmailForm';
import { callMe, getStateName } from '../../helpers/functions';

function ItineraryPage() {
  const { id, itineraryId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<AgentDetailViewModel | null>(null);
  const [openedList, setOpenedList] = useState<number[]>([]);
  const [itinerary, setItinerary] = useState<Itinerary>();
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const parsed = queryString.parse(searchParams.toString());

  // const onItemClicked = (item: Itinerary) => {
  //   if (openedList.includes(item.itineraryId)) {
  //     setOpenedList([...openedList.filter((x) => x !== item.itineraryId)]);
  //   } else {
  //     openedList.push(item.itineraryId);
  //     setOpenedList([...openedList]);
  //   }
  // };
  const getAgent = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchAgent({
        agentId: id ? parseInt(id) : 0,
        bioId: parsed.bioId as string | null,
        preview: parsed.preview ? (parsed.preview as unknown as boolean) : false
      })
      .then((res) => {
        if (res.responseStatus === 1) {
          setData(res.data);
        } else {
          setData(null);
        }
        getItinerary();
      });
  };

  const getItinerary = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchItinerary(
        id ?? '',
        parsed.preview ? (parsed.preview as unknown as boolean) : false,
        parsed.bioId as string | undefined,
        itineraryId
      )
      .then((result) => {
        if (result.responseStatus === 1) {
          setItinerary(result.data[0]);
        }
        setLoading(false);
      });
  };
  const onDayClicked = (item: number) => {
    if (openedList.includes(item)) {
      setOpenedList([...openedList.filter((x) => x !== item)]);
    } else {
      openedList.push(item);
      setOpenedList([...openedList]);
    }
  };

  useEffect(() => {
    if (expanded && itinerary) {
      setOpenedList(itinerary.itineraryDays.map((x) => x.day));
    } else {
      setOpenedList([]);
    }
  }, [expanded, itinerary]);

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      getAgent();
    }
  }, [id]);

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Fetching...</h2>
        </div>
      )}
      <div className="flex flex-col min-h-screen  mx-2 gap-2  2xl:container md:mx-auto">
        {data?.agent && itinerary ? (
          <div className="text-base text-black gap-1 bg-gtc8 p-1.5 -mx-2 md:mx-0">
            <a href="/find-an-elite-travel-agent" className="pr-1">
              Home {'>'}
            </a>
            <a href={`/find-an-elite-travel-agent?slctState=${data?.agent.state}`} className="pr-1">
              {getStateName(data?.agent.state || '')} {'>'}
            </a>
            <a href={`/travel-agent/${data?.agent.id}`} className="pr-1">
              {data?.agent.fullName} {'>'}
            </a>
            <div className="text-gtc9 inline pr-1">{itinerary.title}</div>
          </div>
        ) : (
          <></>
        )}
        {data && (
          <a href={data.agent.detailsUrl} className="cursor-pointer mb-3 w-fit text-sm  pl-1 hidden md:flex items-center ">
            <i role="presentation" className="fa-solid fa-arrow-left  mr-2"></i>
            Back to agent
          </a>
        )}
        <div className="flex flex-col gap-10 md:mt-0 -mt-2 mx-0 md:mx-4">
          <div className="flex flex-col-reverse md:flex-row md:gap-12 gap-6">
            <div className="flex-1 w-full">
              <img src={itinerary?.itineraryImage} className="w-full hidden md:block  object-cover" />
              <div className="mt-[30px] font-bold font-[Avenir-lt-Std] text-3xl uppercase">{itinerary?.title}</div>
              <div className="text-lg font-[lato] font-medium">Duration: {itinerary?.itineraryDays.length} Days</div>
              <div className="mt-[30px] font-[Avenir-lt-Std] text-base">{itinerary?.overview}</div>
              <div className="flex flex-col md:flex-row gap-10 mt-[30px] mr-20 md:mr-0">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-2 text-[28px] font-[lato] font-medium border-b border-black pb-2">
                    <div>{svgLogo}</div>
                    <div> Countries visited:</div>
                  </div>
                  <div>{itinerary?.visitedCountries}</div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-2 text-[28px] font-[lato] font-medium border-b border-black  pb-2">
                    <div>{svgLogo}</div>
                    <div>Places visited:</div>
                  </div>
                  <div>{itinerary?.visitedCities}</div>
                </div>
              </div>
            </div>

            {data && (
              <div className="flex flex-col bg-gtc6 p-1 w-full md:w-[115px] h-full items-center gap-2 text-center">
                <div className="text-center text-base md:text-xs font-bold">PLAN YOUR NEXT TRIP WITH</div>
                <img src={data.agent.photoUrl} className="w-[115px]" />
                <div className="font-bold font-[Avenir-lt-Std] text-xl uppercase">{data.agent.firstName}</div>
                <div className="text-xs">{data.agent.agencyName}</div>
                <div className="flex flex-row gap-1 w-full">
                  <button
                    className={[
                      ' w-full h-10 flex justify-center items-center flex-1',
                      // eslint-disable-next-line no-constant-condition
                      false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
                    ].join(' ')}
                    onClick={() => {
                      setModalOpen('Email');
                    }}
                    title={`Email ${data?.agent.firstName}`}>
                    <i role="presentation" className="fa-solid fa-envelope text-base text-white"></i>
                  </button>
                  <button
                    className={[
                      ' w-full h-10 flex justify-center items-center flex-1',
                      // eslint-disable-next-line no-constant-condition
                      false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
                    ].join(' ')}
                    onClick={() => {
                      setModalOpen('Phone');
                      if (data?.agent) callMe(data?.agent);
                    }}
                    title={`Call ${data?.agent.firstName}`}>
                    <i role="presentation" className="fa-solid fa-phone text-base text-white"></i>
                  </button>
                </div>
                <a
                  href={data.agent.detailsUrl}
                  className={[
                    ' w-full h-12 flex justify-center items-center py-3 text-xs text-white',
                    // eslint-disable-next-line no-constant-condition
                    false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
                  ].join(' ')}
                  title={`View ${data?.agent.firstName}`}>
                  VIEW BIO
                </a>
              </div>
            )}
            <div className=" md:hidden -mx-2">
              <img src={itinerary?.itineraryImage} className="w-full " />
            </div>
          </div>
          <div className="flex-1 flex flex-col   md:pr-40">
            <div className="justify-end pr-4 flex flex-row gap-2 cursor-pointer" onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <>
                  <div>Close All Days</div> {close}
                </>
              ) : (
                <>
                  <div>Expand All Days</div> {expand}
                </>
              )}
            </div>
            {itinerary?.itineraryDays.map((x) => (
              <div className="flex flex-col mt-[20px] gap-4  font-[Avenir-lt-Std] " key={x.itineraryDayId}>
                <div className="flex flex-row items-center justify-between flex-1 py-4 px-3 md:px-6 bg-gtc10 gap-4 -mx-2">
                  <div className="flex-1 text-base md:text-2xl uppercase flex flex-col md:flex-row gap-1">
                    <div className="">
                      <b>DAY {x.day}:</b>
                    </div>
                    <div className="flex flex-row gap-2">
                      {x.dayDestinations.map((y, index) => (
                        <div className="flex flex-row gap-2 items-center uppercase" key={y.locationId}>
                          <div>{y.location.split('|')[0]}</div>
                          {index + 1 < x.dayDestinations.length && <div>-</div>}
                        </div>
                      ))}
                    </div>
                  </div>
                  {openedList.includes(x.day) && (
                    <div className="flex flex-row gap-2 cursor-pointer text-base items-center" onClick={() => onDayClicked(x.day)}>
                      Close Day {close}
                    </div>
                  )}
                  {!openedList.includes(x.day) && (
                    <div className="flex flex-row gap-2 cursor-pointer text-base items-center" onClick={() => onDayClicked(x.day)}>
                      Expand Day {expand}
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    openedList.includes(x.day) ? 'max-h-[5000px]' : 'max-h-0'
                  } transition-all delay-50 duration-100 overflow-hidden flex flex-col md:flex-row gap-8 -mx-2`}>
                  {x.dayImage !== '' ? (
                    <img src={x.dayImage} className="w-full h-[248px] md:w-[500px] mx-auto md:mx-0 md:h-[280px] object-cover" />
                  ) : (
                    <div className="mr-10 hidden md:flex"></div>
                  )}
                  <div className="flex flex-col gap-2 mx-6 md:mx-0 justify-center">
                    <div className="font-[lato] text-2xl font-bold">{x.dayTitle}</div>
                    <div>{x.dayDescription}</div>
                    <div className="mt-4 font-[lato] text-[22px] font-bold">HIGHLIGHTS:</div>
                    {x.dayHighlights.map((x) => (
                      <div className="flex flex-row gap-1 my-1" key={x.highlightId}>
                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <circle cx="10" cy="10" r="10" fill="#F7F5F5" />
                          <path d="M5 9.01025L9.59619 13.6064L15.6066 7.59604" stroke="#49AA39" strokeWidth="2" />
                        </svg>
                        {x.highlight1}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            <div className="flex flex-col gap-2 my-10 mx-4 md:mx-0">
              <div className="text-2xl md:text-[35px]  font-[Avenir-lt-Std] ">FINAL THOUGHTS</div>
              <div className=" font-[Avenir-lt-Std] text-base">{itinerary?.finalThoughts}</div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {data?.agent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...data?.agent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && (
                // eslint-disable-next-line react/jsx-no-undef
                <PhoneForm agent={data?.agent} callback={() => setModalOpen('Email')} />
              )}
              {modalOpen === 'Email' && <EmailForm agent={data?.agent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

const svgLogo = (
  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
    <path
      d="M9.1516 13.3594C11.3226 10.189 14.5507 7.89451 18.2584 6.88641C21.9661 5.87832 25.9113 6.22245 29.3881 7.85722C32.865 9.49199 35.6465 12.3107 37.2351 15.809C38.8236 19.3074 39.1155 23.257 38.0585 26.9511C37.0016 30.6453 34.6646 33.843 31.466 35.972C28.2673 38.101 24.4156 39.0223 20.6001 38.5712C16.7846 38.1201 13.2543 36.3259 10.6408 33.5098C8.02739 30.6936 6.50137 27.0392 6.33585 23.2005L7.51099 23.3382C7.64155 25.6262 8.29413 27.8539 9.41871 29.8506C10.5433 31.8473 12.1101 33.56 13.9989 34.8574C16.6306 36.6555 19.7681 37.5656 22.9536 37.4547C26.1392 37.3439 29.2061 36.218 31.7069 34.2413C33.5485 32.7863 35.0219 30.9184 36.0082 28.7886C36.9944 26.6589 37.4656 24.3269 37.3839 21.9814C37.3021 19.6359 36.6696 17.3426 35.5375 15.2869C34.4054 13.2313 32.8054 11.4709 30.867 10.1482C28.8985 8.7909 26.6322 7.92733 24.2595 7.63042C21.8869 7.33351 19.4776 7.61197 17.2351 8.44229C14.9926 9.2726 12.9827 10.6304 11.3753 12.4009C9.76786 14.1715 8.61005 16.3029 7.99966 18.615L6.82806 18.4797C7.29415 16.649 8.07952 14.9149 9.14805 13.357"
      fill="url(#paint0_linear_2595_4491)"
    />
    <path
      d="M11.3629 14.9425C12.6776 13.0219 14.4707 11.4774 16.5652 10.4616C18.6597 9.44573 20.9832 8.99355 23.3066 9.14964C25.63 9.30573 27.8729 10.0647 29.814 11.3517C31.7551 12.6387 33.3274 14.4092 34.3757 16.4885C35.424 18.5679 35.9121 20.8842 35.7918 23.209C35.6716 25.5338 34.9471 27.7867 33.6899 29.7454C32.4327 31.7041 30.6862 33.301 28.6227 34.3785C26.5593 35.4559 24.2502 35.9767 21.9232 35.8895L22.0916 34.6462C25.0053 34.7196 27.8473 33.7412 30.0972 31.8903C32.3471 30.0394 33.8544 27.4398 34.3431 24.5675C34.8318 21.6952 34.2693 18.7423 32.7584 16.2496C31.2475 13.7569 28.8895 11.891 26.116 10.9938C23.3425 10.0965 20.3391 10.2278 17.6558 11.3636C14.9724 12.4995 12.7887 14.5639 11.5044 17.1788C10.2201 19.7937 9.92113 22.7842 10.6623 25.6028C11.4034 28.4213 13.135 30.8794 15.5401 32.5267C16.037 32.8617 16.5577 33.1597 17.0981 33.4186L16.9194 34.7226C16.1892 34.3997 15.49 34.0106 14.8305 33.5604C11.9043 31.5475 9.8957 28.4566 9.24559 24.9661C8.59548 21.4756 9.35695 17.8706 11.3629 14.9425Z"
      fill="url(#paint1_linear_2595_4491)"
    />
    <defs>
      <linearGradient id="paint0_linear_2595_4491" x1="9.15862" y1="13.3416" x2="35.8416" y2="31.6586" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9C9D9E" />
        <stop offset="0.421875" stopColor="#B8B9BA" />
        <stop offset="1" stopColor="#D5D5D5" />
      </linearGradient>
      <linearGradient id="paint1_linear_2595_4491" x1="11.3679" y1="14.9352" x2="33.4558" y2="30.0978" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9C9D9E" />
        <stop offset="0.421875" stopColor="#B8B9BA" />
        <stop offset="1" stopColor="#D5D5D5" />
      </linearGradient>
    </defs>
  </svg>
);

const close = (
  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_2595_4508)">
      <path d="M18.75 5.25L5.25 18.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.75 18.75L5.25 5.25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2595_4508">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const expand = (
  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.25 15V20.25H15M19.7719 19.7705L14.25 14.25M3.75 9V3.75H9M4.22812 4.22953L9.75 9.75M15 3.75H20.25V9M19.7705 4.22812L14.25 9.75M9 20.25H3.75V15M4.22953 19.7719L9.75 14.25"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ItineraryPage;
