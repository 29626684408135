import { useEffect, useState } from 'react';
import { AgentViewModel } from '../../helpers/types';
import { useSearchParams } from 'react-router-dom';
import APIService from '../../services/services';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

function ThankYouEmail() {
  const [agent, setAgent] = useState<AgentViewModel>();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);
  const parsed = queryString.parse(searchParams.toString());
  const getAgent = () => {
    console.log(parsed);
    setLoading(true);
    APIService()
      .gtcService.fetchAgent({
        agentId: parseInt(parsed.AgentId as string)
      })
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setAgent(res.data.agent);
        }
      });
  };

  useEffect(() => {
    getAgent();
  }, []);
  return (
    <>
      <Helmet>
        <script src="/js/fb_event.js" type="text/javascript" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main className="mx-auto">
        <section id="mainsec" className="w-full">
          <div
            className="h-96 justify-center px-4 items-center flex"
            style={{
              backgroundImage: "url('/images/hero/thank-you-page-header_Image_2110x410px.webp')",
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            <div className="flex flex-col items-center ">
              <div>
                <h1 className="text-2xl sm:text-65xl font-light text-gtc4 text-center">
                  Thank you for contacting us
                </h1>
              </div>
            </div>
          </div>
        </section>

        <article className="p-4 mx-auto bg-white 2xl:container">
          <p className="mb-0">
            <strong>Dear {parsed.FullName as string},</strong>
          </p>
          <p className="mt-0 pt-0">
            <strong>Thank you for your inquiry!</strong>
          </p>

          <p className="mt-2.5 mb-7">
            {`I'm excited to learn more about you to help make your travel dreams come true. You can
          expect to hear back from me within 24 hours to get started! In the meantime, check out the
          link below for more travel inspiration.`}
          </p>
          <div className="flex my-10 sm:justify-center">
            <a
              href="/advisor-inspiration"
              className="w-full py-2 font-normal text-center text-white bg-black border border-black sm:w-72 hover:font-semibold px-7 hover:cursor-pointer">
              Get Inspired
            </a>
          </div>
          <a
            href={`/travel-agent/${parsed?.AgentId}`}
            className="flex flex-col sm:flex-row gap-4 mb-24 cursor-pointer">
            <div>
              <img className="w-40" src={agent?.photoUrl} />
            </div>
            <div>
              <div className="uppercase font-bold text-xl">{agent?.fullName}</div>
              <div className="uppercase text-xs max-w-xs">{agent?.websiteDisplayTitle}</div>
            </div>
          </a>
        </article>
      </main>
    </>
  );
}

export default ThankYouEmail;
