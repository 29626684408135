import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../helpers/constants';
import SearchBox from '../../components/SearchBox';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { SwiperButtonNext } from '../../components/SwiperButtonNext';
import { SwiperButtonPrev } from '../../components/SwiperButtonPrev';
import APIService from '../../services/services';
import { Helmet } from 'react-helmet';
import { HeaderText } from '../../components/HeaderText';
import { MicIcon, BriefCaseIcon, GTCSIMPLEICON, MAPMARKERICON, AirplaneIcon } from '../../helpers/svgIcons';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import { StoryItemViewModel } from '../../helpers/types';
import MainWrapper from '../../components/MainWrapper';

const locations = ['Beverly Hills', 'New York', 'London'];
const secondSectionData = [
  {
    icon: <AirplaneIcon />,
    link: '/find-an-elite-travel-agent',
    title: 'leisure',
    desc: 'Elevate your travels with exclusive experiences and VIP treatment through the coveted world connections of a Global Travel Collection advisor.'
  },
  {
    icon: <BriefCaseIcon />,
    link: 'https://business.globaltravelcollection.com',
    title: 'business',
    desc: 'Enjoy seamless support via our travel experts, who demonstrate dedication to excellence in corporate, groups, events and incentive travel.'
  },
  {
    icon: <MicIcon />,
    link: '/entertainment-travel',
    title: 'entertainment',
    desc: 'Experience star treatment with our expertise in travel logistics for A-list celebrities, exclusive events, film, luxury fashion and musician tours.'
  }
];

function Home() {
  const [stories, setStories] = useState<StoryItemViewModel[]>([]);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [video, setVideo] = useState<string | null>(null);
  const getStories = () => {
    APIService()
      .gtcService.fetchStories()
      .then((res) => {
        if (res.responseStatus === 1) {
          var d = res.data.reverse();
          setStories(res.data.reverse());
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    getStories();
    Modal.setAppElement('#root');
    if (!modalIsOpen) setVideo(null);
  }, [modalIsOpen]);

  return (
    <>
      <Helmet>
        <meta name="title" content="International Luxury Travel Agents | Global Travel Collection" />
        <meta
          name="description"
          content="Our 1,700 GTC advisors and agencies are leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment industry."
        />
        <title>International Luxury Travel Agents | Global Travel Collection</title>
      </Helmet>
      <div className="mx-auto relative overflow-hidden -mt-24 mb-2 md:mb-28">
        <section className="relative h-[470px] md:h-[650px]  flex flex-col items-center justify-center text-center text-white pt-5 sm:py-0 px-3">
          <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
            <video
              className="min-w-full min-h-full absolute object-cover md:object-top object-[70%_0%]"
              src="/videos/home-hero-loop.mp4"
              autoPlay
              muted
              playsInline
              loop></video>
          </div>
          <div className="video-content text-center">
            <HeaderText title={'Wherever You’re Going, We’ve Been There'} />
          </div>
          {/* <section className="w-full absolute bottom-0 text-left text-black">
            <SearchBox />
          </section> */}
        </section>
        <MainWrapper>
          <div className=" md:pt-32 pt-8 flex flex-col gap-4 md:gap-20">
            <section className="flex md:flex-row flex-col-reverse gap-4 md:gap-20 items-center -mx-4 md:mx-0">
              <img src="/images/home/1.png" className="w-[470px] h-[455px] object-cover " />
              <div className="flex flex-col gap-8 md:max-w-3xl items-center md:items-start px-4 md:px-0">
                <h2 className="uppercase text-31-2 text-center">A COLLECTION OF TRAVEL CURATORS</h2>
                <h4 className="text-gtc1 text-center md:text-left text-base">
                  We are the world’s largest collection of international luxury travel agencies. Approximately 1,700 GTC advisors and
                  agencies are industry leaders in providing premium travel services to leisure travelers, corporate executives and the
                  entertainment industry. The combined global reach and leverage translates into value, recognition, and preferential
                  treatment for its world travelers. Wherever you want to go, however you want to get there, whatever you want to do—we have
                  been there.
                </h4>
                <a href="/find-an-elite-travel-agent" className="flex items-center justify-center uppercase  dark-btn w-[201px] ">
                  FIND AN ADVISOR
                </a>
              </div>
            </section>

            <section className="flex md:flex-row flex-col-reverse gap-4 md:gap-20 items-center mt-6 md:mt-0">
              <div className="flex flex-col gap-12">
                {secondSectionData.map((x) => (
                  <div className="flex flex-col items-center md:items-start md:flex-row gap-8" key={x.title}>
                    <a href={x.link}>
                      <div>{x.icon}</div>
                    </a>
                    <div className="flex flex-col gap-1 items-center md:items-start">
                      <div className="uppercase text-31-2">{x.title}</div>
                      <div className="text-gtc1 text-base text-center md:text-left">{x.desc}</div>
                    </div>
                  </div>
                ))}
              </div>
              <img src="/images/home/2.png" className="w-[328px] h-[449px] object-cover hidden md:block" />
            </section>

            <section className="-mx-10 md:-mx-5 overflow-hidden">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                initialSlide={1}
                pagination={false}
                modules={[Pagination]}
                breakpoints={{
                  640: {
                    pagination: false,
                    slidesPerView: 1
                  }
                }}>
                <div className="absolute inset-y-0 left-12 md:left-4 z-10 md:flex items-center top-[26.5rem] md:top-0">
                  <SwiperButtonPrev className="" />
                </div>
                {stories.map((story) => (
                  <SwiperSlide key={story.id}>
                    <div className="swiper-slide px-5">
                      <div className="w-full">
                        <a title={story.title} href={story.url} className="cursor-pointer">
                          <img className="w-full h-[450px] object-cover" src={story.heroImage} alt={story.title} title={story.title} />
                        </a>
                      </div>
                      <div className=" bg-black min-h-[77px] py-8 md:py-4 flex items-center px-8 md:px-6">
                        <p
                          className="m-0 text-[24px] leading-[31.2px]  text-white uppercase font-normal w-full
                       font-ivypresto-display  md:pt-0 line-clamp-2 text-center md:text-left mt-4 md:mt-0
                      ">
                          {story.title}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

                <div className="absolute inset-y-0 right-12 md:right-4 z-10 md:flex items-center top-[26.5rem] md:top-0">
                  <SwiperButtonNext className="" />
                </div>
              </Swiper>
            </section>

            <section className="flex flex-col gap-4 md:gap-8 items-center md:mt-0 mt-8">
              <a href="/as-seen-in-media" className="text-31-2 ">
                AS SEEN IN THE MEDIA
              </a>
              <div className="flex flex-col gap-8 md:gap-10 items-center  border-t border-gtcA4 pt-10">
                <div className="grid grid-cols-2 md:flex md:flex-row gap-8 md:gap-10">
                  <a href="/as-seen-in-media">
                    <img
                      src="/images/home/media/nbc.png"
                      className="p-6 w-[150px] h-[100px]  md:w-[170px] md:h-[120px]   shadow-xl object-contain"
                    />
                  </a>
                  <a href="/as-seen-in-media">
                    <img
                      src="/images/home/media/extra.png"
                      className="p-6 w-[150px] h-[100px]  md:w-[170px] md:h-[120px]    shadow-xl object-contain"
                    />
                  </a>
                  <a href="/as-seen-in-media">
                    <img
                      src="/images/home/media/cnn.png"
                      className="p-6  w-[150px] h-[100px]  md:w-[170px] md:h-[120px]    shadow-xl object-contain"
                    />
                  </a>
                  <a href="/as-seen-in-media">
                    <img
                      src="/images/home/media/ap.png"
                      className="p-6  w-[150px] h-[100px]  md:w-[170px] md:h-[120px]   shadow-xl object-contain py-8"
                    />
                  </a>
                </div>
                <div className="grid grid-cols-2 md:flex md:flex-row gap-8 md:gap-10 ">
                  <a href="/as-seen-in-media">
                    <img
                      src="/images/home/media/nypost.png"
                      className="p-6  w-[150px] h-[100px]  md:w-[170px] md:h-[120px]    shadow-xl object-contain"
                    />
                  </a>
                  <a href="/as-seen-in-media">
                    <img
                      src="/images/home/media/wsj.png"
                      className="p-6  w-[150px] h-[100px]  md:w-[170px] md:h-[120px]    shadow-xl object-contain"
                    />
                  </a>
                  <a href="/as-seen-in-media" className="col-span-2 mx-auto ">
                    <img
                      src="/images/home/media/today.png"
                      className="p-6 w-[150px] h-[100px]  md:w-[170px] md:h-[120px]    shadow-xl object-contain"
                    />
                  </a>
                </div>
              </div>
            </section>

            <section className="bg-black  text-white p-5 md:p-10 flex md:flex-row flex-col-reverse gap-6 md:gap-10 -mx-4 md:mx-0 overflow-hidden">
              <div className="flex flex-col items-center md:items-start gap-8 flex-1">
                <GTCSIMPLEICON />
                <div className="uppercase text-31-2 text-center">Who is Global Travel Collection?</div>
                <div className="text-center  text-gtc3 md:text-left max-w-xl text-[16px]">
                  Global Travel Collection (GTC) represents the largest and most sophisticated community of premier travel agencies
                  worldwide, serving leisure, corporate, incentive, and entertainment travel needs.
                </div>
                <div className="flex flex-col items-center md:items-start md:flex-row gap-8 md:gap-4">
                  <button
                    onClick={() => {
                      setVideo('videos/header-modal-desktop.mp4');
                      setIsOpen(true);
                    }}
                    className=" dark-btn  w-fit md:w-[132px]  text-lg md:text-base border-white border  h-[45px] py-0">
                    View Video
                  </button>
                  <a
                    href="/about-gtc"
                    className="flex items-center justify-center   dark-btn w-full md:w-[247px] text-lg md:text-base border-white border h-[45px] py-0">
                    View Our Family Of Brands
                  </a>
                </div>
              </div>
              <div className="relative">
                <img src="/images/home/gtc-loc.png" className="ml-auto w-[286px] md:w-full" />
                <div className="flex flex-col gap-5 absolute bottom-14 md:bottom-10 md:-left-14 left-0">
                  {locations.map((x) => (
                    <div
                      className="flex flex-row gap-1 md:gap-2  px-6 md:px-6 py-2 md:py-3 bg-white text-black text-lg md:text-2xl items-center"
                      key={x}>
                      <div className="scale-75 md:scale-100">
                        <MAPMARKERICON />
                      </div>
                      <div className="text-gtc1 text-base font-medium">{x}</div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section className="mb-10">
              <SearchBox />
            </section>
          </div>
        </MainWrapper>
        <ScrollToTopButton />
      </div>

      <Modal isOpen={modalIsOpen && video !== null} style={customStyles}>
        <div className="modalVideo">
          <button onClick={() => setIsOpen(false)} className="close-modal text-center cursor-pointer">
            ×
          </button>
          <video
            id="modalVideo"
            autoPlay
            controls
            poster="/images/hero/video-full-poster.jpg"
            onEnded={() => setIsOpen(false)}
            src={video || ''}></video>
        </div>
      </Modal>
    </>
  );
}

export default Home;
