import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';
import { useRef, useState } from 'react';
import { isOdd } from '../../helpers/functions';
import { GTCSIMPLEICON } from '../../helpers/svgIcons';
import StarRatings from 'react-star-ratings';
import MainWrapper from '../../components/MainWrapper';

function TheatricalProductionTravel() {
  // Create refs for each section
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const videoRef = useRef<HTMLVideoElement | null>(null); // Create a reference to the video element
  const [isPlaying, setIsPlaying] = useState<boolean>(false); // State to track play/pause
  const [showFilmCredit, setShowFilmCredit] = useState<boolean>(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current?.pause(); // Pause the video
      } else {
        videoRef.current?.play(); // Play the video
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  const data = [
    {
      img: '/images/theatrical-production-travel/1.png',
      title: 'UNRIVALED EXPERIENCE ',
      desc: `Our dedicated theater team is committed to delivering exclusive pricing and expert contract negotiations for all your travel needs. Whether it’s securing hotels, corporate apartments, commercial or charter flights, tour buses, car rentals, or black car and limo services, we handle it all with precision. When it comes to getting your tour on the road or in the air, you can count on us to provide comprehensive support every step of the way.`
    }
  ];
  const onMoreClicked = () => {
    if (showFilmCredit) {
      scrollToSection(ref1, () =>
        setTimeout(() => {
          setShowFilmCredit(!showFilmCredit);
        }, 200)
      );
    } else {
      setShowFilmCredit(!showFilmCredit);
    }
  };

  // Scroll function with offset
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>, callback?: () => void) => {
    if (ref.current) {
      const offset = 80;
      const sectionPosition = ref.current.offsetTop - offset;

      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth'
      });
      if (callback) {
        callback();
      }
    }
  };

  const title = (text: string) => <p className="text-31-2 uppercase">{text}</p>;

  return (
    <>
      <Helmet>
        <meta name="title" content="Theatrical Production Travel Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta name="description" content="Theatrical Production Travel Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <title>Theatrical Production Travel Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/theatrical-production-travel"></link>
      </Helmet>
      <Hero title="Theatrical Production Travel" img="/images/theatrical-production-travel/hero.jpg" />
      <MainWrapper>
        <div className="pt-6 md:pb-40 pb-10 mx-auto bg-white min-h-screen flex flex-col gap-8 md:gap-16 items-center font-[Avenir]">
          {/* <!-- Search Buttons Section --> */}
          <div className="text-center w-full mb-8">
            <div className="flex md:flex-row flex-col md:gap-14 gap-4 justify-center">
              <button onClick={() => scrollToSection(ref1)} className="light-btn w-full md:w-[240px] uppercase">
                Our theater credits
              </button>
              <button onClick={() => scrollToSection(ref2)} className="light-btn w-full md:w-[240px] uppercase">
                Private jets
              </button>
            </div>
          </div>

          {/* <!-- Theatrical Production Travel Section --> */}
          <div className="flex flex-col  md:gap-8 items-center text-center">
            {title('ALL THE WORLD’S A STAGE')}
            <p className="text-gtc1 max-w-[1024px] text-left text-20-8">
              Embark on your next theatrical tour with confidence, knowing your Global Travel Collection advisor is backing every travel
              logistic. No matter your role — crew member, director, talent or any position between — our advisors provide customized
              solutions to simplify every part of your theatrical journey. We understand the unique challenges of touring and the level of
              coordination required to meet a large production crew’s needs. Plus, we specialize in furnished housing tailored for Broadway
              and West End productions. Let your advisor handle the logistics so you can focus on the show.
            </p>
          </div>

          {/* <!--  Categories Section --> */}
          <div className="grid grid-cols-1 gap-12 md:gap-16  text-center md:text-left md:mt-10 -mx-4 md:mx-0">
            {data.map((x, i) => (
              <div className={['flex flex-col items-center  gap-8 md:gap-16', isOdd(i) ? ' md:flex-row-reverse' : 'md:flex-row'].join(' ')}>
                <img src={x.img} alt={x.title} className="w-full h-[390px] md:w-[455px] md:h-[441px] object-cover md:mr-6" />
                <div className="flex flex-col md:gap-4 items-center md:items-start px-4 md:px-0">
                  {title(x.title)}
                  <p className="text-gtc1 text-20-8 text-left">{x.desc}</p>
                </div>
              </div>
            ))}
          </div>
          {/* <!--  Our Films Section --> */}
          <div className="flex flex-col gap-8 items-center text-center px-4 md:px-0  w-full" ref={ref1}>
            {title('Our theater credits')}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-10 items-center mt-4 px-4 md:px-0 w-full">
              {titles.slice(0, 8).map((x) => (
                <div
                  className="flex items-start justify-center p-6 shadow-lg text-20-8 text-black uppercase font-bold
               xl:min-w-[290px] xl:min-h-[61px] "
                  key={x}>
                  {x}
                </div>
              ))}
            </div>
            <div
              className={[
                'grid grid-cols-1 md:grid-cols-4 gap-10 mt-4 transition-all delay-150 duration-300 overflow-hidden w-full',
                showFilmCredit ? 'max-h-[50000px]  pb-10' : 'max-h-0'
              ].join(' ')}>
              {titles.slice(8, titles.length).map((x) => (
                <div
                  className="flex items-start justify-center p-6 shadow-lg text-20-8 text-black uppercase font-bold
               xl:min-w-[290px] xl:min-h-[61px] "
                  key={x}>
                  {x}
                </div>
              ))}
            </div>
            <button onClick={onMoreClicked} className="dark-btn w-[302px] uppercase ">
              {showFilmCredit ? 'HIDE THEATRICAL CREDITS' : 'VIEW ALL THEATRICAL CREDITS'}
            </button>
          </div>

          {/* <!-- The SELECT Hotel Collection Section --> */}
          <div className="flex flex-col gap-4 md:gap-12 items-center text-center   md:mt-10 w-full">
            <div className=" px-4 md:px-0">
              {title('The SELECT Hotel Collection')}
              <p className="text-20-8 font-[350] text-gtc1 max-w-[1024px] mt-4 text-left md:text-center">
                Our thoughtful SELECT hotel collection provides the perfect accommodations for your theatrical production team. Whether
                you're in New York City for rehearsals or traveling abroad for a performance, you'll benefit from a range of tailored
                amenities and VIP services designed to enhance your experience. Enjoy a seamless stay that meets the unique demands of your
                production team, ensuring everyone can focus on delivering exceptional performances.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10  -mx-4 md:mx-0">
              {hotelCollections.map((x) => (
                <div className=" mx-auto  shadow-lg overflow-hidden relative">
                  {/* <!-- Image Section --> */}
                  <div className="">
                    <img className="h-[484px] w-[full] object-cover" src={x.img} alt={x.img} />
                  </div>

                  {/* <!-- Content Section --> */}
                  <div
                    className="p-4 text-center absolute bottom-0 justify-center flex flex-col items-center w-full"
                    style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                    {title(x.title)}
                    <p className="text-[16px] text-gt474">{x.desc}</p>
                    <StarRatings
                      rating={5}
                      starRatedColor="#606062"
                      numberOfStars={5}
                      name="rating"
                      starDimension="23px"
                      starSpacing="2px"
                    />
                  </div>
                </div>
              ))}
            </div>
            <a href="https://internovatravel.com" className="flex items-center justify-center uppercase  dark-btn w-[302px] mt-4">
              VIEW COLLECTION
            </a>
          </div>

          <div className="w-full">
            {/* <!-- Private Jet Section --> */}
            <div className="bg-black md:p-10  flex flex-col md:flex-row items-center md:gap-20 -mx-4 md:mx-0 mt-6" ref={ref2}>
              <img src="/images/film-and-tv/3.jpg" alt="Jet Image" className="w-full h-[390px] md:w-[482px] md:h-[403px] object-cover " />
              <div className="flex flex-col gap-4 md:gap-8 py-8 px-2 md:px-0 md:py-0 md:items-start items-center">
                <GTCSIMPLEICON />
                <p className="text-2xl font-[Avenir-medium] uppercase leading-[36px] text-white">Private jets</p>
                <div className="text-base leading-[20.8px] text-gtc3 max-w-[390px] md:max-w-[628px] text-left">
                  At Global Travel Collection, we understand not all charters are created equal. Whether you're taking a regional flight or
                  jetting halfway around the globe, the journey should be tailored to your production team’s unique needs. And when your
                  travel plans evolve, our dedicated team is on hand to provide personalized support. This flexibility guarantees you
                  receive the assistance you need, all while securing exclusive rates. With Global Travel Collection, you can travel with
                  confidence knowing that your comfort and schedule are our top priority.
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
}

const titles = [
  'AN AMERICAN IN PARIS',
  'ANASTASIA',
  'BACK TO THE FUTURE',
  "BAND'S VISIT",
  'BAT OUT OF HELL',
  'BEAUTY AND THE BEAST',
  'BLUE MAN GROUP',
  'BOOK OF MORMON',
  'BRONX TALE',
  'CABARET',
  'CAROLE KING',
  'CHICAGO',
  'CINDERELLA',
  'COME FROM AWAY',
  'DEAR EVAN HANSEN',
  'DONNY AND MARIE',
  'ELF',
  'FIDDLER ON THE ROOF',
  'FINDING NEVERLAND',
  'FOREIGNER',
  'HALF TIME',
  'HIP HOP NUTCRACKER',
  'JOHN GORE ORGANIZATION',
  'JUKEBOX HERO',
  'KING AND I',
  'LED ZEPPELIN EXPERIENCE',
  'LES MISERABLES',
  'LITTLE DANCER',
  'LITTLE MERMAID',
  'MARIA CALLAS HOLOGRAM',
  'MISS SAIGON',
  'PROM',
  'PHANTOM OF THE OPERA',
  'PINK FLOYD EXPERIENCE',
  'ROCKTOPIA',
  'SIMON AND GARFUNKEL STORY',
  'THE LIGHTNING THIEF',
  'THE PRICE IS RIGHT LIVE',
  'WAITRESS',
  'WIZARD OF OZ'
];

const hotelCollections = [
  {
    img: '/images/theatrical-production-travel/hotels/1.png',
    title: 'Sugar Beach',
    desc: 'Soufriere / St. Lucia'
  },
  {
    img: '/images/theatrical-production-travel/hotels/2.png',
    title: 'One&Only Palmilla',
    desc: 'Los Cabos / Mexico'
  },
  {
    img: '/images/theatrical-production-travel/hotels/3.png',
    title: 'Aman – Amangiri',
    desc: 'Utah / USA'
  }
];

export default TheatricalProductionTravel;
