import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';
import { useRef, useState } from 'react';
import { isOdd } from '../../helpers/functions';
import { GTCSIMPLEICON } from '../../helpers/svgIcons';
import MainWrapper from '../../components/MainWrapper';

function FilMTVProduction() {
  // Create refs for each section
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const [showFilmCredit, setShowFilmCredit] = useState<boolean>(false);

  const data = [
    {
      img: '/images/film-and-tv/1.jpg',
      title: 'Unparalleled expertise',
      desc: 'We provide comprehensive support for all travel and logistics-related services, including expert contract negotiations to ensure our clients receive exclusive deals. Global Travel Collection advisors offer budgeting assistance during pre-production, while our arrangements for commercial and charter air travel simplify logistics. Additionally, we coordinate car and truck rentals, meet-and-greet services and airport concierge support that guarantees a smooth arrival for everyone involved. Every detail is meticulously managed, allowing you to focus on your production.'
    },
    {
      img: '/images/film-and-tv/2.jpg',
      title: 'ENTERTAINMENT HOUSING',
      desc: `Our advisors expertly manage hotel contracts for both crew and talent, ensuring comfortable and convenient accommodations tailored to their needs. For long-term film projects, we go a step further by coordinating furnished apartments and home rentals, allowing cast and crew to settle in and feel at home during their stay. Our personalized approach ensures every lodging option meets the specific requirements of your production team, whether it's proximity to set, amenities or budget considerations.`
    }
  ];

  const onMoreClicked = () => {
    if (showFilmCredit) {
      scrollToSection(ref1, () =>
        setTimeout(() => {
          setShowFilmCredit(!showFilmCredit);
        }, 200)
      );
    } else {
      setShowFilmCredit(!showFilmCredit);
    }
  };

  // Scroll function with offset
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>, callback?: () => void) => {
    if (ref.current) {
      const offset = 80;
      const sectionPosition = ref.current.offsetTop - offset;

      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth'
      });
      if (callback) {
        callback();
      }
    }
  };

  const title = (text: string) => <p className="text-31-2 uppercase">{text}</p>;

  return (
    <>
      <Helmet>
        <meta name="title" content="Film and TV Production Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta name="description" content="Film and TV Production Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <title>Film and TV Production Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/film-and-tv-production-travel"></link>
      </Helmet>
      <Hero title="Film and TV Production" img="/images/film-and-tv/hero.jpg" />
      <MainWrapper>
        <div className="pt-6 md:pb-40 pb-10 mx-auto bg-white min-h-screen flex flex-col gap-8 md:gap-16 items-center font-[Avenir]">
          {/* <!-- Search Buttons Section --> */}
          <div className="text-center w-full">
            <div className="flex md:flex-row flex-col md:gap-14 gap-4 justify-center">
              <button onClick={() => scrollToSection(ref1)} className="light-btn w-full md:w-[240px] uppercase">
                Our film credits
              </button>
              <button onClick={() => scrollToSection(ref2)} className="light-btn w-full md:w-[240px] uppercase">
                Private jets
              </button>
            </div>
          </div>

          {/* <!-- Film and TV Production Section --> */}
          <div className="flex flex-col  md:gap-8 items-center text-center">
            {title('THE STAR TREATMENT')}
            <p className="text-gtc1 max-w-[1024px] text-left  text-20-8">
              Global Travel Collection advisors offer unparalleled attention and specialized expertise in travel logistics for celebrities
              and crew in the film and television industry. Whether you’re part of a production crew, a director or the lead talent, we
              deliver customized solutions designed to streamline every aspect of your journey. With our extensive experience, you can focus
              on creating captivating content while we handle the logistics, ensuring your production process is as smooth and efficient as
              possible. Trust our advisors to elevate your travel experience, so you can concentrate on what truly matters: capturing the
              perfect shot.
            </p>
          </div>

          {/* <!--  Categories Section --> */}
          <div className="grid grid-cols-1 gap-8 md:gap-16 md:mt-10 text-center md:text-left -mx-4 md:mx-0">
            {data.map((x, i) => (
              <div className={['flex flex-col items-center gap-8 md:gap-16', isOdd(i) ? ' md:flex-row-reverse' : 'md:flex-row'].join(' ')}>
                <img src={x.img} alt={x.title} className="w-full h-[390px] md:w-[455px] md:h-[441px] object-cover md:mr-6" />
                <div className="flex flex-col md:gap-4 items-center md:items-start px-4 md:px-0">
                  {title(x.title)}
                  <p className="text-gtc1 text-20-8 text-left">{x.desc}</p>
                </div>
              </div>
            ))}
          </div>
          {/* <!--  Our Films Section --> */}
          <div className="flex flex-col gap-2 md:gap-8 items-center text-center px-0  md:mt-10 w-full" ref={ref1}>
            {title('Our film credits')}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-40 items-center mt-4 px-4 md:px-0">
              {icons.map((x) => (
                <img src={x} key={x} className="w-[165px] h-[98px]" />
              ))}
            </div>
            <div
              className={[
                'grid grid-cols-1 md:grid-cols-3 gap-10 mt-10 transition-all delay-150 duration-300 overflow-hidden',
                showFilmCredit ? 'max-h-[50000px]  pb-10' : 'max-h-0'
              ].join(' ')}>
              {productionCompanies.map((x) => (
                <div className="flex flex-col items-center p-4 shadow-md border border-gray-100">
                  {title(x.company)}
                  <p className="text-base leading-[20.8px] text-gtc1 text-center">{x.title}</p>
                </div>
              ))}
            </div>
            <button onClick={onMoreClicked} className="dark-btn w-[243px] uppercase">
              {showFilmCredit ? 'HIDE FILM CREDITS' : '  VIEW ALL FILM CREDITS'}
            </button>
          </div>

          <div className="w-full">
            {/* <!-- Private Jet Section --> */}
            <div className="bg-black md:p-10  flex flex-col md:flex-row items-center md:gap-20 -mx-4 md:mx-0 mt-6" ref={ref2}>
              <img src="/images/film-and-tv/3.jpg" alt="Jet Image" className="w-full h-[390px] md:w-[482px] md:h-[403px] object-cover " />
              <div className="flex flex-col gap-4 md:gap-8 py-8 px-2 md:px-0 md:py-0 md:items-start items-center">
                <GTCSIMPLEICON />
                <p className="text-2xl font-[Avenir-medium] uppercase leading-[36px] text-white">Private jets</p>
                <div className="text-base leading-[20.8px] text-gtc3 max-w-[390px] md:max-w-[628px] text-left">
                  At Global Travel Collection, we understand not all charters are created equal. Whether you're taking a regional flight or
                  jetting halfway around the globe, the journey should be tailored to your production team’s unique needs. And when your
                  travel plans evolve, our dedicated team is on hand to provide personalized support. This flexibility guarantees you
                  receive the assistance you need, all while securing exclusive rates. With Global Travel Collection, you can travel with
                  confidence knowing that your comfort and schedule are our top priority.
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
}

const icons = [
  '/images/film-and-tv/logos/disnep.svg',
  '/images/film-and-tv/logos/hbo.svg',
  '/images/film-and-tv/logos/lionsgate.svg',
  '/images/film-and-tv/logos/marvel.svg',
  '/images/film-and-tv/logos/miramax.svg',
  '/images/film-and-tv/logos/paramount.svg',
  '/images/film-and-tv/logos/universal.svg',
  '/images/film-and-tv/logos/WarnerBros.svg'
];
const productionCompanies = [
  { company: '3311 Productions', title: 'Brigsby Bear' },
  { company: 'ABC', title: 'The Astronaut Wives Club' },
  { company: 'Acacia', title: 'Wind River, Shock and Awe' },
  { company: 'AMBI Group', title: 'Finding Steve McQueen' },
  { company: 'Alcon Entertainment', title: 'Point Break' },
  { company: 'Amblin', title: 'The Haunted' },
  { company: 'Anarchos Productions', title: 'Sense8' },
  { company: 'Assemble Media', title: 'Mainstream' },
  { company: 'Bron Studios', title: 'Harry Haft, Those Who Wish Me Dead, Fonzo' },
  { company: 'CBS Films', title: 'Five Feet Apart' },
  { company: 'Columbia Pictures', title: '13 Going on 30, The Juror' },
  { company: 'Discovery Channel', title: 'Klondike' },
  { company: 'Echo Films', title: 'Morning Show' },
  { company: 'Flower Films', title: 'Freak Show' },
  { company: 'Imagine Entertainment', title: 'The Good Lie' },
  { company: 'Informant Films', title: 'Boychoir' },
  { company: 'Open Road Films', title: 'Chef' },
  { company: 'Mockingbird Pictures', title: 'The Sweet Lie' },
  { company: 'Royalty', title: 'Dune' },
  { company: 'Savoy Pictures', title: 'Let It Be Me' },
  { company: 'Samuel Goldwyn Company', title: 'Big Night' },
  { company: 'Starz', title: 'Hightown, Sweetbitter' },
  { company: 'Syfy', title: 'Opposite Worlds' },
  { company: 'Summit Entertainment', title: "I Alex Cross, Ender's Game" },
  { company: 'Walt Disney Pictures', title: 'Jungle Cruise, LOL' },
  { company: 'Netflix', title: "The Prom, To All the Boys I've Loved Before (Season 2), Tall Girl, Mudbound" },
  { company: 'Legendary', title: 'Godzilla vs. Kong, Detective Pikachu 2, Lost in Space (Season 2), Skyscraper' },
  { company: 'Marvel Studios', title: 'Captain Marvel, Ant-Man & the Wasp, Avengers: Infinity War, Black Panther, Thor: Ragnarok' },
  { company: 'Sony Pictures', title: 'Once Upon a Time in Hollywood, White Boy Rick, Anger Management, Truth' },
  {
    company: 'Twentieth Century Fox',
    title: 'Ford v Ferrari, Dawn of the Planet of the Apes, The Maze Runner, X-Men: First Class, Gulliver'
  },
  {
    company: 'Lionsgate',
    title:
      "Chasing the Cure, Antebellum, Chaos Walking, John Wick Chapter 3, Step Up: High Water, Vida, Dear White People, Hered, Grenafel, Imperium, Love the Coopers, Hunger Games: Mockingjay Part 1 & 2, Ender's Game, The Next Three Days"
  },
  {
    company: 'STX Entertainment',
    title:
      '21 Bridges, Hustlers, My Spy, Ugly Dolls, Valley of the Boom, Work It, Adrift, Happy Time Murders, Den of Thieves, I Feel Pretty, Mile 22, Second Act, A Bad Moms Christmas, The Foreigner, Bad Moms, The Space Between Us, Free State of Jones, Hardcore Henry'
  },
  {
    company: 'Paramount Pictures',
    title:
      'Gemini Man, Yellowstone, Suburbicon, Terminator Genisys, World Trade Center, War of the Worlds, The Stepford Wives, The School of Rock, Homeland Security, Vanilla Sky, Failure to Launch, Star Trek, Shaft, Zoolander'
  },
  {
    company: 'MGM',
    title:
      "Candyman, Respect, Fargo Season 4, Condor, The Sun Is Also a Star, Child's Play, Get Shorty Season 3, It Runs in the Family, Fame"
  },
  {
    company: 'Warner Bros',
    title:
      'Nancy Drew and the Hidden Staircase, The Way Back, A Star is Born, Godstorm, Going in Style, Dolphin Tale 2, Prisoners, Live by Night, Transcendence, Argo, A Couple of Dicks, Green Lantern, Prisoners, The Cinderella Story'
  },
  {
    company: 'Warner Bros',
    title:
      'Interview with the Vampire, Deep Blue, Smoochy, Collateral Damage, Batman Forever, A Perfect Analyze This, Analyze That, Eyes Wide Shut, Under Siege 2: Dark Territory'
  }
];

export default FilMTVProduction;
