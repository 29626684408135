import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { GoogleRecpatchaKey, customStyles } from '../../helpers/constants';
import { EmailUtilityViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { useNavigate } from 'react-router-dom';
import { Hero } from '../../components/Hero';
import ReCAPTCHA from 'react-google-recaptcha';

const emailTopics = ['Media Inquiries', 'Technical Issues', 'Become a GTC Independent Advisor (IC)', 'Other'];

function AboutUs() {
  const navigate = useNavigate();
  const contactUsRef = useRef<null | HTMLDivElement>(null);
  // eslint-disable-next-line no-unused-vars
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [video, setVideo] = useState<string | null>(null);

  // eslint-disable-next-line no-unused-vars
  const [isProcessing, setIsProcessing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<EmailUtilityViewModel>(
    //@ts-ignore
    {
      fullName: '',
      fromEmail: '',
      comments: '',
      toEmail: ''
    }
  );
  const [reCaptcha, setReCaptcha] = useState<string | null>(null);
  const executeScrollPlace = () => {
    setTimeout(() => {
      contactUsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
  };

  const onRecaptchChange = (value: any) => {
    setReCaptcha(value);
  };

  const handleSubmit = (event: any) => {
    if (reCaptcha === null) {
      event.preventDefault();
      alert('Please check the recaptcha');
      return;
    }
    event.preventDefault();
    console.log(data);
    setIsProcessing(true);
    APIService()
      .gtcService.sendEmailUtility(data)
      .then(() => {
        setIsProcessing(false);
        navigate('/thank-you-contact-us');
      });
    //navigate('/thank-you-contact-us');
  };

  useEffect(() => {
    if (window.location.href.indexOf('#contact-us') > -1) {
      executeScrollPlace();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta name="title" content="About Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta
          name="description"
          content="Global Travel Collection (GTC) is the world’s largest collection of international luxury travel agencies"
        />
        <title>About Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/about-gtc"></link>
      </Helmet>

      <Hero title="ABOUT US" img="/images/new-hero/aboutus.webp" />

      <article className="mx-auto bg-black">
        <section className="md:px-12 px-0 mx-auto">
          <section className=" text-white flex flex-col md:flex-row gap-4 px-8 md:px-0 py-10 mx-auto  2xl:container">
            <div className="flex-1">
              <div className="mb-7 lex justify-center">
                <h2 className="text-3xl md:text-4xl text-center uppercase font-medium">Home of the Independent Travel Advisor</h2>
              </div>
              <div className="flex items-center flex-col">
                <h3 className="text-xl font-light font-[Avenir-lt-Std]">Who is Global Travel Collection?</h3>
                <div className="w-40 h-1 bg-white my-2"></div>
              </div>
              <div className="text-center flex flex-col gap-4 text-lg font-light  font-[Avenir-lt-Std]">
                <p>
                  Global Travel Collection (GTC) is the world’s largest collection of international luxury travel agencies, including the
                  well-established networks of Protravel International, Tzell Travel Group, Altour, Colletts Travel, In The Know Experiences
                  and All Star Travel Group.
                </p>
                <p>
                  Approximately 1,700 GTC advisors and agencies are industry leaders in providing premium travel services to leisure
                  travelers, corporate executives and the entertainment industry. The combined global reach and leverage translates into
                  value, recognition, and preferential treatment for its world travelers.
                </p>
              </div>
              <div className="flex flex-row gap-4 mt-10 justify-center">
                <a
                  href="/join-us"
                  className="py-2 font-normal text-center text-white bg-black border border-white
            hover:font-semibold px-7 hover:cursor-pointer w-fit text-2xl font-[Avenir-lt-Std]">
                  BECOME A GTC TRAVEL ADVISOR
                </a>
              </div>
            </div>
          </section>
        </section>
      </article>
      <section className="my-1 md:my-2 grid grid-cols-2 md:grid-cols-3 py-10 md:py-14 gap-10 md:gap-16 md:mx-auto md:px-8 px-4 w-full  max-w-[90rem]">
        <img src="/images/about-us/1.png" alt="All star" className="mx-auto" />
        <img src="/images/about-us/2.png" alt="All star" className="mx-auto" />
        {/* <img src="/images/about-us/3.png" alt="All star" className="mx-auto" /> */}
        <img src="/images/about-us/4.png" alt="All star" className="mx-auto" />

        <img src="/images/about-us/5.png" alt="All star" className="mx-auto" />
        <img src="/images/about-us/6.png" alt="All star" className="mx-auto" />
        {/* <img src="/images/about-us/7.png" alt="All star" className="mx-auto" /> */}
        <img src="/images/about-us/8.png" alt="All star" className="mx-auto" />
      </section>

      <section className="md:mx-16 mx-0">
        <div className="mx-auto bg-black  2xl:container">
          <div className=" text-white flex flex-col md:flex-row gap-4 px-8 md:px-14 py-10  2xl:container mx-auto">
            <div className="flex-1">
              <div className="mb-7">
                <h2 className="font-medium text-3xl md:text-4xl text-center uppercase">A DIVISION OF INTERNOVA TRAVEL GROUP</h2>
              </div>

              <div className="text-center flex flex-col gap-4 text-lg font-light font-[Avenir-lt-Std]">
                <p>
                  Internova Travel Group’s international family of travel brands stand out across the travel industry. Headquartered in New
                  York City and active around the world, our extensive network of travel advisors assists travelers across the globe.
                </p>
              </div>
              <div className="flex flex-row gap-4 mt-10 justify-center">
                <img src="/images/about-us/internova.png" alt="internova" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-1 md:gap-6 pt-10 md:pt-10 md:px-16 px-0 items-center" id="contact-us" ref={contactUsRef}>
        <div className="text-3xl md:text-[32px] uppercase md:font-medium mb-1">Contact us</div>
        <div className="flex flex-col md:flex-row gap-1 md:gap-6  justify-center  font-[Avenir-lt-Std]">
          <div className="flex  flex-col">
            <img src="/images/about-us/newyork.png" alt="New York" className="w-[500px]" />
            <div className="flex flex-col gap-1 items-center py-6">
              <div className="uppercase text-black text-2xl font-normal text-center px-12 md:px-0">New York Headquarters</div>
              <div className="text-center text-base text-gtc1 leading-7">
                (212) 944-2121 <br />
                1633 Broadway floor 35 & 36 <br />
                New York, NY 10019
              </div>
            </div>
          </div>
          <div className="flex  flex-col">
            <img src="/images/about-us/beverly.png" alt="Beverly" className="w-[500px]" />
            <div className="flex flex-col gap-1 items-center py-6">
              <div className="uppercase text-black text-2xl font-normal  text-center px-12 md:px-0">Beverly Hills Headquarters</div>
              <div className="text-center text-base text-gtc1 leading-7">
                (310) 271-9566 <br />
                345 North Maple Drive, Suite 250 <br />
                Beverly Hills, CA 90210
              </div>
            </div>
          </div>
          <div className="flex  flex-col ">
            <img src="/images/about-us/london.png" alt="London" className="w-[500px]" />
            <div className="flex flex-col gap-1 items-center py-6">
              <div className="uppercase text-black text-2xl font-normal  text-center px-12 md:px-0">London Headquarters</div>
              <div className="text-center text-base text-gtc1 leading-7">
                101 St. Martins Lane, First Floor <br />
                London WC2N 4AZ
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="md:mx-16 mx-4">
        <section className="2xl:container mx-auto flex flex-col md:flex-row gap-6 py-0 md:py-8  md:mb-6">
          <form className=" flex md:w-[487px] flex-col gap-4  sf-form" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <div className="text-base md:text-xl">Name</div>
              <input
                id="Name"
                maxLength={80}
                style={{
                  WebkitAppearance: 'none'
                }}
                name="name"
                type="text"
                required
                aria-label="Name"
                aria-required={true}
                onChange={(event) => {
                  data.fullName = event.target.value;
                  setData({ ...data });
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-base md:text-xl">Email</div>
              <input
                id="Email"
                maxLength={80}
                style={{
                  WebkitAppearance: 'none'
                }}
                name="email"
                type="email"
                aria-label="Email"
                aria-required={true}
                required
                onChange={(event) => {
                  data.fromEmail = event.target.value;
                  setData({ ...data });
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-base md:text-xl">Email Topic</div>
              <select
                id="EmailTopic"
                name="EmailTopic"
                aria-label="Email Topic"
                title="Which best describes you?"
                style={{
                  WebkitAppearance: 'none'
                }}
                required
                aria-required={true}
                onChange={(event) => {
                  data.toEmail = event.target.value;
                  setData({ ...data });
                }}>
                <option value="">Select Email Topic</option>
                {emailTopics.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-base md:text-xl">Your message</div>
              <textarea
                id="yourMessage"
                name="yourMessage"
                style={{
                  WebkitAppearance: 'none'
                }}
                aria-label="Your message"
                aria-required={true}
                required
                rows={3}
                onChange={(event) => {
                  data.comments = event.target.value;
                  setData({ ...data });
                }}
                //type="text"
                wrap="soft"></textarea>
            </div>
            <div className="flex justify-center">
              <ReCAPTCHA sitekey={GoogleRecpatchaKey} onChange={onRecaptchChange} />
            </div>
            <div className="flex-1 flex flex-col gap-6 items-center my-0 md:my-2">
              <button
                className={[
                  'py-2 font-normal text-center text-white  border border-black px-7   text-sm',
                  isProcessing ? 'bg-gray-500 cursor-not-allowed' : 'bg-black cursor-pointer  hover:font-semibold'
                ].join(' ')}
                disabled={isProcessing}
                type="submit">
                {isProcessing ? `Sending Email...` : `Send Email`}
              </button>
            </div>
          </form>
          <div className="flex-1 flex flex-col gap-9 font-[Avenir-lt-Std] ">
            <div className="flex-1 flex flex-col gap-4">
              <div className="text-3xl md:font-light font-normal">GENERAL INQUIRES</div>
              <div className="text-lg md:text-2xl text-gt474 !leading-[34px]">
                For media inquiries, technical issues with the website, or becoming a GTC Independent Advisors (IC), please contact us using
                the form. For travel inquiries, please contact one of{' '}
                <a href="/find-an-elite-travel-agent" className="font-bold underline">
                  our agents
                </a>{' '}
                directly.
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-4">
              <div className="text-3xl md:font-light font-normal ">LEISURE TRAVEL INQUIRIES</div>
              <div className="text-lg md:text-2xl text-gt474 !leading-[34px]">
                We would love the opportunity to share our knowledge and expertise with you on your next journey. We are passionate about
                helping you travel better with us, please use our agent locator.
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-4">
              <div className="text-3xl md:font-light font-normal">BUSINESS TRAVEL INQUIRIES</div>
              <div className="text-lg md:text-2xl text-gt474 !leading-[34px]">
                We look forward to helping you with your business travel management needs. You can visit{' '}
                <a href="https://business.globaltravelcollection.com" className="font-bold underline">
                  business.globaltravelcollection.com
                </a>{' '}
                to find out more about our corporate travel services.
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-4">
              <div className="text-3xl md:font-light font-normal">CAREER INQUIRES</div>
              <div className="text-lg md:text-2xl text-gt474 !leading-[34px]">
                For career inquiries, please visit our{' '}
                <a href="https://careers-internova.icims.com/jobs/intro" className="font-bold underline">
                  career site
                </a>{' '}
                to view all opportunities.
              </div>
            </div>

            <div className="flex-1 flex flex-col gap-6 md:px-12 px-2 py-5 bg-gtc11 -mx-4 md:mx-0">
              <div className="text-3xl">OUR MAILING ADDRESS</div>
              <div className="hidden-tw md:flex">
                <div className="text-lg md:text-2xl text-gt474">1633 Broadway, Floor 35</div>
                <div className="text-lg md:text-2xl text-gt474">New York, NY 10019</div>
              </div>
              <div className="text-lg md:text-2xl text-gt474 md:hidden">1633 Broadway, Floor 35 New York, NY 10019</div>
            </div>
          </div>
        </section>
      </section>
      <Modal isOpen={modalIsOpen && video !== null} style={customStyles}>
        <div className="modalVideo">
          <button onClick={() => setIsOpen(false)} className="close-modal text-center cursor-pointer">
            ×
          </button>
          <video
            id="modalVideo"
            autoPlay
            controls
            // poster="/images/hero/video-full-poster.jpg"
            onEnded={() => setIsOpen(false)}
            src={video || ''}></video>
        </div>
      </Modal>
    </>
  );
}

export default AboutUs;
