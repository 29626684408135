import { HeaderText } from './HeaderText';

interface HeaderTextProps {
  title: string;
  img: string;
  class?: string;
}
export const Hero = (prop: HeaderTextProps) => {
  return (
    <section id="mainsec" className="w-full -mt-24 ">
      <div
        className={['h-[470px] md:h-[650px] justify-center px-4 items-center flex', prop.class].join(' ')}
        style={{
          backgroundImage: `url('${prop.img}')`,
          backgroundSize: 'cover',
          backgroundPosition: '50%'
        }}>
        <div className="flex flex-col items-center ">
          <div>
            <HeaderText title={prop.title} />
          </div>
        </div>
      </div>
    </section>
  );
};
