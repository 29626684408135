import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';
import { useRef, useState } from 'react';
import { isOdd } from '../../helpers/functions';
import MainWrapper from '../../components/MainWrapper';

function Entertainment() {
  // Create refs for each section
  const filmTvRef = useRef(null);
  const theatreRef = useRef(null);
  const musicRef = useRef(null);
  const videoRef = useRef<HTMLVideoElement | null>(null); // Create a reference to the video element
  const [isPlaying, setIsPlaying] = useState<boolean>(false); // State to track play/pause

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current?.pause(); // Pause the video
      } else {
        videoRef.current?.play(); // Play the video
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };
  const data = [
    {
      img: '/images/entertainment-travel/et1.jpg',
      title: 'FILM AND TV PRODUCTION TRAVEL',
      desc: `Global Travel Collection ensures detailed care and dedicated expertise for travel logistics associated with celebrities, film and television. Whether you're part of a production crew, a director or the main talent traveling for a project, we provide tailored solutions that streamline your travel experience. With our expertise, you can focus on capturing the perfect shot while we take care of the logistics, making your production process as smooth and efficient as possible.`,
      ref: filmTvRef,
      learnMoreLink: '/film-and-tv-production-travel',
      videoLink: '/videos/GTC-altour.mp4',
      poster: '/images/entertainment-travel/poster.png'
    },
    {
      img: '/images/entertainment-travel/et2.jpg',
      title: 'THEATRICAL PRODUCTION TRAVEL',
      desc: `We specialize in comprehensive housing and production logistics tailored specifically for the world of theatrical tours. Our team consists of seasoned experts who understand the unique demands of touring professionals, ensuring every aspect of the journey is seamless. Additionally, we excel in short-term housing solutions for Broadway productions in New York City, catering to creative teams and visiting stars. `,
      ref: theatreRef,
      learnMoreLink: '/theatrical-production-travel',
      videoLink: undefined
    },
    {
      img: '/images/entertainment-travel/et3.jpg',
      title: 'MUSIC TOURS',
      desc: `Solo artists, rock bands, large orchestras and everything between — we provide customized travel solutions specifically for touring musicians and their crew. From luxury accommodations to seamless transportation and even equipment logistics, we've got you covered every leg of the tour. Our clients sell out shows in new cities, connect with loyal fans and create unforgettable memories — all without the stress of planning. `,
      ref: musicRef,
      learnMoreLink: undefined,
      videoLink: undefined
    }
  ];

  // Scroll function with offset
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const offset = 80;
      const sectionPosition = ref.current.offsetTop - offset;

      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta name="title" content="Entertainment travel Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta name="description" content="Entertainment travel Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <title>Entertainment travel Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/entertainment-travel"></link>
      </Helmet>
      <Hero title="Entertainment Travel" img="/images/entertainment-travel/hero-entertainment-travel.jpg" />
      <MainWrapper>
        <div className="pt-6 md:pb-40 pb-10 mx-auto bg-white  min-h-screen flex flex-col gap-8 md:gap-16 items-center font-[Avenir] ">
          {/* <!-- Search Buttons Section --> */}
          <div className="text-center w-full ">
            <div className="flex md:flex-row flex-col md:gap-14 gap-4 justify-center w-full">
              <button onClick={() => scrollToSection(filmTvRef)} className="light-btn w-full md:w-[200px] ">
                Film and TV
              </button>
              <button onClick={() => scrollToSection(theatreRef)} className="light-btn w-full md:w-[200px]">
                Theater
              </button>
              <button onClick={() => scrollToSection(musicRef)} className="light-btn w-full md:w-[200px]">
                Music
              </button>
            </div>
          </div>

          {/* <!-- Entertainment Travel Section --> */}
          <div className="flex flex-col gap-4 md:gap-8 items-center text-center">
            <h2 className="text-31-2">WHY GLOBAL TRAVEL COLLECTION?</h2>
            <p className="text-gtc1 max-w-[1024px] text-left md:text-center text-20-8">
              With core offices in the heart of the entertainment world — including New York City and London — Global Travel Collection has
              long been the first choice for some of the most recognized names in the entertainment industry. In addition to our expertise
              in showbiz logistics, you can expect discreet luxury services that cater to the unique needs of A-list clients, as well as
              their production and support teams.
            </p>
          </div>

          {/* <!-- Travel Categories Section --> */}
          <div className="grid grid-cols-1 gap-12 md:gap-16  text-center md:text-left md:mt-10 -mx-4 md:mx-0">
            {data.map((x, i) => (
              <div
                className={['flex flex-col items-center gap-8 md:gap-16', isOdd(i) ? ' md:flex-row-reverse' : 'md:flex-row'].join(' ')}
                ref={x.ref}>
                {x.videoLink !== undefined ? (
                  <div className="relative h-[218px] w-full md:w-[156.43%] md:h-[255px]  overflow-hidden">
                    <video
                      poster={x.poster}
                      loop
                      ref={videoRef}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src={x.videoLink}
                      controls
                      onClick={handlePlayPause}
                    />
                  </div>
                ) : (
                  <img src={x.img} alt={x.title} className="w-full h-[390px] md:w-[455px] md:h-[441px] object-cover md:mr-6" />
                )}
                <div className="flex flex-col gap-4 items-center md:items-start px-4 md:px-0">
                  <h3 className="text-31-2 ">{x.title}</h3>
                  <p className="text-gtc1 text-left">{x.desc}</p>
                  {x.learnMoreLink != undefined && (
                    <div className=" div-btn-wrapper">
                      <a href={x.learnMoreLink}>LEARN MORE</a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </MainWrapper>
    </>
  );
}

export default Entertainment;
