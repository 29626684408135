/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { AgentConnection, AgentDetailViewModel, AgentMap } from '../../../helpers/types';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import StarRatings from 'react-star-ratings';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 20,
  lng: -37
};

interface AgentMapProps {
  data: AgentDetailViewModel;
}

export const AgentMapView = ({ data }: AgentMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: data.googleApiKey
  });
  const selectedPlaceRef = useRef<null | HTMLDivElement>(null);
  const [map, setMap] = useState(null);
  const [showInfo, setShowWindow] = useState(false);
  const [selected, setSelected] = useState<AgentMap>();

  const onLoad = useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(
    //   selected
    //     ? {
    //         lat: selected.latitude,
    //         lng: selected.longitude
    //       }
    //     : center
    // );
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const executeScrollPlace = () => {
    setTimeout(() => {
      selectedPlaceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  };

  useEffect(() => {
    if (selected) {
      executeScrollPlace();
    }
  }, [selected]);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className="flex flex-col gap-4">
      <div>
        <h2 className="font-bold text-3xl mt-0 mb-0">My Travel Experience</h2>
        <div className="text-sm font-thin">Places I’ve personally traveled to or destinations I’ve booked for my clients.</div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex-1">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={
              selected
                ? {
                    lat: selected.latitude,
                    lng: selected.longitude
                  }
                : center
            }
            zoom={1.5}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {data.agent.agentMaps?.map((x) => (
              <Marker
                key={x.mapId}
                position={{ lat: x.latitude, lng: x.longitude }}
                icon={'https://maps.google.com/mapfiles/ms/icons/red.png'}
                onClick={() => {
                  setSelected(x);
                  setShowWindow(true);
                }}
              />
            ))}
            {showInfo && selected && (
              <InfoWindow
                position={{
                  lat: selected.latitude,
                  lng: selected.longitude
                }}
                onCloseClick={() => setShowWindow(!showInfo)}>
                <div className="flex flex-row justify-between">
                  <div className="text-sm  md:text-sm font-thin cursor-pointer flex-1">
                    <div>{`${selected.locale} ${selected.locale ? ',' : ''} ${selected.area} ${selected.region}`}</div>
                    <StarRatings
                      rating={selected.reviewRating}
                      starRatedColor="#FD6A4A"
                      numberOfStars={5}
                      name="rating"
                      starDimension="14px"
                      starSpacing="2px"
                    />
                    <div className="text-sm font-light">{selected.reviewTitle}</div>
                  </div>
                  <div className="flex flex-col gap-1 flex-1 ">
                    {selected.bookedIt && (
                      <div className="text-xs font-light flex flex-row gap-2 items-center">
                        <i role="presentation" className="fa fa-plane" aria-hidden="true"></i>
                        {`Booked it`}
                      </div>
                    )}

                    {selected.beenThere && (
                      <div className="text-xs font-light flex flex-row gap-2 items-center">
                        <i role="presentation" className="fa fa-suitcase" aria-hidden="true"></i>
                        {`Been there`}
                      </div>
                    )}
                  </div>

                  <div className="md:flex flex-col w-20 md:w-32">
                    {selected.locationPhoto && <img src={selected.locationPhoto} className="object-contain w-full " alt="" />}
                  </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
        <div className=" inline-block text-black overflow-y-auto bg-white md:flex-1" style={{ height: '400px' }}>
          {data.agent.agentMaps.map((x) => (
            <div
              key={x.mapId}
              ref={selected && x.mapId === selected.mapId ? selectedPlaceRef : null}
              onClick={() => {
                setSelected(x);
                setShowWindow(true);
              }}
              className={[
                'flex flex-row p-4 gap-4 justify-between border-b border-gtc3 cursor-pointer',

                selected && selected.mapId === x.mapId ? 'bg-gtc3' : ''
              ].join(' ')}>
              <div className="flex flex-row justify-between">
                <div className="text-sm  md:text-sm font-thin cursor-pointer flex-1">
                  <div>{`${x.locale} ${x.locale ? ',' : ''} ${x.area} ${x.region}`}</div>
                  <StarRatings
                    rating={x.reviewRating}
                    starRatedColor="#FD6A4A"
                    numberOfStars={5}
                    name="rating"
                    starDimension="14px"
                    starSpacing="2px"
                  />
                  <div
                    className="text-sm font-light"
                    // onClick={() => (refs[x.reviewId] ? executeScroll(refs[x.reviewId], 'center') : {})}
                  >
                    {x.reviewTitle}
                  </div>
                </div>
                <div className="flex flex-col gap-1 flex-1 ">
                  {x.bookedIt && (
                    <div className="text-xs font-light flex flex-row gap-2 items-center">
                      <i role="presentation" className="fa fa-plane" aria-hidden="true"></i>
                      {`Booked it`}
                    </div>
                  )}

                  {x.beenThere && (
                    <div className="text-xs font-light flex flex-row gap-2 items-center">
                      <i role="presentation" className="fa fa-suitcase" aria-hidden="true"></i>
                      {`Been there`}
                    </div>
                  )}
                </div>

                {/* <div
                className="text-sm font-light"
                onClick={() => (refs[x.reviewId] ? executeScroll(refs[x.reviewId], 'center') : {})}>
                {x.review.length > 50
                  ? parser(
                      `${sanitizeHtml(
                        x.review.slice(0, 50)
                      )}...<span class="mx-2 cursor-pointer">Read More</span>`
                    )
                  : x.review}
              </div> */}
                {/* {x.locationTip && (
                <>
                  <div className="text-sm  font-semibold mt-4">Must Do:</div>
                  <div className="text-sm  font-light">{x.locationTip}</div>
                </>
              )} */}
                <div className="md:flex flex-col  w-32">
                  {x.locationPhoto && <img src={x.locationPhoto} className="object-contain w-full " alt="" />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AgentMapView;
