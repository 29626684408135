import { Link } from 'react-router-dom';

export interface FooterLinkProps {
  to: string;
  name: string;
}

function FooterLink(prop: FooterLinkProps) {
  return (
    <Link to={prop.to} className="hover:text-gray-300 underline text-center">
      {prop.name}
    </Link>
  );
}

export default FooterLink;
