import React, { useState, useEffect } from 'react';
import { DOUBLEARROWUPICON } from '../helpers/svgIcons';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Scroll to top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      type="button"
      onClick={scrollToTop}
      className={`fixed bottom-5 right-2 bg-white border border-black text-white p-3 rounded-full shadow-lg transition-opacity duration-300 ${
        isVisible ? 'opacity-60' : 'opacity-0'
      }`}
      style={{ zIndex: 1000 }}
      aria-label="Scroll to top">
      <DOUBLEARROWUPICON />
    </button>
  );
};

export default ScrollToTopButton;
