import { useState, useEffect } from 'react';
import { StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';

function AdvisorInspiration() {
  const [stories, setStories] = useState<StoryItemViewModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [showAll, setShowAll] = useState<boolean>(false);

  const getStories = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchStories()
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setStories(res.data);
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    getStories();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="title" content="Luxury Travel Experience Inspiration and Ideas | Global Travel Collection" />
        <meta
          name="description"
          content="Global Travel Collection’s stories bring you a world without borders. See ideas and inspriations from luxury expeirences around the world brought to you by agents and travelers."
        />
        <title>Luxury Travel Experience Inspiration and Ideas | Global Travel Collection</title>
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>
      <Hero title="TRAVEL JOURNAL" img="/images/new-hero/journal.webp" />

      <article className="p-4 mx-auto bg-white 2xl:container mt-4">
        {loading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
            <h2 className="text-center text-white text-xl font-semibold">Searching...</h2>
          </div>
        ) : (
          <section className="mx-2 md:mx-20">
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
              {stories.slice(0, 9).map((x) => (
                <li key={x.id}>
                  <div className="relative">
                    <a title={x.title} href={x.url}>
                      <img className="w-full object-cover" src={x.thumbnailImage} alt={x.title} width={410} height={615} />
                    </a>

                    <div className="text-center bg-black min-h-[120px] py-4 flex justify-center items-center">
                      <p className="m-0 text-xs md:text-sm lg:text-base text-white uppercase font-normal font-[Avenir-lt-Std] px-2">
                        {x.title}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div className={`${showAll ? 'max-h-[50000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full mt-4">
                {stories.slice(9, stories.length).map((x) => (
                  <div key={x.id}>
                    <div className="relative">
                      <a title={x.title} href={x.url} className="cursor-pointer">
                        <img className="w-full object-cover" src={x.thumbnailImage} alt={x.title} width={410} height={615} />
                      </a>

                      <div className="text-center bg-black min-h-[120px] py-4 flex justify-center items-center">
                        <p className="m-0 text-xs md:text-sm lg:text-base text-white uppercase font-normal font-[Avenir-lt-Std] px-2">
                          {x.title}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}

        {stories.length > 9 && !loading && (
          <div className="flex justify-center mt-4">
            <button
              className="py-2 font-normal text-center text-white
                   bg-black border border-black
                hover:font-semibold px-7 hover:cursor-pointer  "
              onClick={() => setShowAll(!showAll)}>
              {showAll ? 'View Fewer' : 'View More'}
            </button>
          </div>
        )}
      </article>
    </>
  );
}

export default AdvisorInspiration;
