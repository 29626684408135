import React from 'react';
import { Helmet } from 'react-helmet';

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Sorry, we can&apos;t find this page | GTC</title>

        <meta name="title" content="404 Not Found | GTC " />

        <meta name="description" content="Sorry, this page can't be located, or has been moved" />

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="flex flex-col items-center h-screen my-10 mx-4 md:mx-48">
        <h1 className="text-red-500 uppercase text-4xl tracking-wide text-center" aria-label="You are lost" role="contentinfo">
          OH NO, YOU’RE LOST!
        </h1>

        <p className="my-4 text-center leading-snug">
          Sadly, the page you are looking for does not exist. What does exist are Global Travel Collection Advisors who can guide you to
          your dream destination much better than this sign will.
        </p>

        <p className="font-bold my-4 text-center">
          Find your travel agent&nbsp;
          <a href="/find-an-elite-travel-agent" className="text-red-500 hover:text-black">
            here
          </a>
          &nbsp;or return to our&nbsp;
          <a href="/" className="text-red-500 hover:text-black">
            homepage
          </a>
          .
        </p>

        <p className="my-4">
          <img src="/images/404-signs.webp" alt="Travel Signspost" className="max-h-96" loading="lazy" />
        </p>

        <div className="image-gallery-slide image-gallery-description" />
      </div>
    </>
  );
}

export default NotFound;
