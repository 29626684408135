import React, { useEffect, useState } from 'react';
import APIService from '../../../services/services';

function Destination({ destination, city, interest, state, language, metroRegion, zip, supplier, callback }: any) {
  const type = 'FacetedDestinations';
  const [showFull, setShowFull] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [itemToShow, setItemToShow] = useState(5);
  const [showDetail, setShowDetail] = useState(true);
  const [showArea] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = () => {
    setItemToShow(5);
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type,
        radius: ''
      })
      .then((response) => {
        setLoading(false);
        if (response.responseStatus === 1) {
          setData(response.data);
        } else {
          setData([]);
        }
      });
  };

  const getName = (dest: any) => {
    switch (dest.locationType) {
      case 'Reg':
        return dest.region;
      case 'Are':
        return dest.area;
      case 'Locale':
        return dest.locale;
      case 'Cit':
        return dest.locale;
      default:
        return '';
    }
  };

  const getDestinationFullName = (dest: any) => {
    switch (dest.locationType) {
      case 'Reg':
        return dest.region;
      case 'Are':
        return `${dest.area} | ${dest.region}`;
      case 'Locale':
        return `${dest.locale} | ${dest.area} | ${dest.region}`;
      case 'Cit':
        return `${dest.locale} | ${dest.area} | ${dest.region}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!showArea) {
      setShowDetail(false);
    }
  }, [showArea]);

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);

  return (
    <>
      <div>
        <div className="my-2 text-[1.1rem] font-semibold text-base uppercase text-black bg-gtc6 py-3 px-1 md:px-0 md:py-0 md:bg-white md:border-none border-b border-black">
          DESTINATION
        </div>
        {loading ? (
          <div className="flex animate-pulse flex-row  h-full justify-center">
            <div className=" bg-gtc4 h-44 w-full mr-4 mb-2"></div>
          </div>
        ) : (
          <>
            {data.length > 0 || destination ? (
              <div className="text-4xl flex flex-col">
                <div className="flex flex-col items-start ">
                  <div className="flex flex-col items-start ">
                    {destination && (
                      <div
                        onClick={() => (destination ? callback(0) : setItemToShow(itemToShow === 5 ? 0 : 5))}
                        className="text-sm  cursor-pointer text-black hover:text-green-light flex flex-row items-center gap-1"
                        role="button"
                        tabIndex={0}>
                        All Regions
                      </div>
                    )}
                    {destination && data.length > 0 && (
                      <>
                        {data[0].locationType === 'Cit' && (
                          <>
                            <div
                              className="text-sm   text-green-light flex flex-row gap-1 items-center cursor-pointer"
                              onClick={() => callback(data[0].region)}
                              role="button"
                              tabIndex={0}>
                              {data[0].region}
                              {/* <IoChevronDown className="text-green-light text-right" /> */}
                            </div>
                            <div
                              className="text-sm   text-green-light flex flex-row gap-1 items-center ml-1 cursor-pointer"
                              onClick={() => callback(`${data[0].area} | ${data[0].region}`)}
                              role="button"
                              tabIndex={0}>
                              {data[0].area}

                              {/* <IoChevronDown className="text-green-light text-right" /> */}
                            </div>
                          </>
                        )}
                        {data[0].locationType === 'Are' && (
                          <>
                            <div
                              className="text-sm   text-green-light flex flex-row gap-1 items-center cursor-pointer"
                              onClick={() => setShowDetail(!showDetail)}
                              role="button"
                              tabIndex={0}>
                              {data[0].region}
                              {!showDetail && (
                                // <IoChevronForward className="text-green-light text-right" />
                                <></>
                              )}
                              {showDetail && (
                                // <IoChevronDown className="text-green-light text-right" />
                                <></>
                              )}
                            </div>
                          </>
                        )}
                        {data[0].locationType === 'Locale' && (
                          <>
                            <div
                              className="text-sm   text-green-light flex flex-row gap-1 items-center cursor-pointer"
                              onClick={() => callback(data[0].region)}
                              role="button"
                              tabIndex={0}>
                              {data[0].region}
                              {/* <IoChevronDown className="text-green-light text-right" /> */}
                            </div>

                            <div
                              className="text-sm   text-green-light flex flex-row gap-1 items-center cursor-pointer"
                              onClick={() => callback(`${data[0].area} | ${data[0].region}`)}
                              role="button"
                              tabIndex={0}>
                              {data[0].area}
                              {/* <IoChevronDown className="text-green-light text-right" /> */}
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {destination && data.length === 0 && (
                      <>
                        <div className="text-sm   text-green-light">{destination}</div>
                      </>
                    )}

                    {showDetail && (
                      <div className={[destination ? 'pl-1' : ''].join(' ')}>
                        <div>
                          {data.slice(0, itemToShow).map((x, index) => (
                            <div
                              onClick={() => callback(getDestinationFullName(x))}
                              className="text-sm  cursor-pointer text-black hover:text-green-light"
                              key={`${index}-search-dest`}
                              role="button"
                              tabIndex={0}>
                              {getName(x)}
                            </div>
                          ))}
                        </div>
                        <div
                          className={`${
                            showFull ? 'max-h-[5000px]' : 'max-h-0'
                          } transition-all delay-150 duration-300 overflow-hidden w-full`}>
                          {data.slice(itemToShow, data.length).map((x, index) => (
                            <div
                              onClick={() => callback(getDestinationFullName(x))}
                              className="text-sm  cursor-pointer text-black hover:text-green-light"
                              key={`${index}-search-dest`}
                              role="button"
                              tabIndex={0}>
                              {getName(x)}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {data.length > itemToShow && itemToShow !== 0 && showDetail && (
                    <button
                      type="button"
                      className="text-sm  cursor-pointer font-semibold text-black text-[1rem] hover:text-green-light mt-2 self-end md:self-start"
                      onClick={() => setShowFull(!showFull)}>
                      {!showFull ? (
                        <div className="flex flex-row gap-1 items-center">
                          More Destinations
                          {` (${data.length - itemToShow})`}
                          <i role="presentation" className="fa fa-angle-double-down"></i>
                        </div>
                      ) : (
                        <div className="flex flex-row gap-1 items-center">
                          Close
                          <i role="presentation" className="fa fa-angle-double-up"></i>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-sm">Please broaden your search criteria</div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Destination;
