import PropTypes from 'prop-types';
import { AgentViewModel } from '../../helpers/types';
// import { useNavigate } from 'react-router-dom';

interface PhoneFormProps {
  agent: AgentViewModel;
  callback: () => void;
}

function PhoneForm({ agent, callback }: PhoneFormProps) {
  return (
    <div className="p-4 flex flex-col gap-6 max-w-5xl">
      <div className="flex flex-col justify-center items-center gap-1">
        <div className="text-sm md:text-lg  text-center mx-10">You can reach</div>

        <div className="text-sm md:text-base text-center font-bold">
          {`${agent.firstName} ${agent.lastName}`}
          <br />
        </div>
        <div className="text-sm md:text-base  font-semibold text-gray-800 flex flex-row gap-1">
          at
          <a
            href={`tel:${agent.phoneNumber}`}
            target="_blank"
            rel="noreferrer"
            className="no-underline text-blue-700">
            {`${agent.phoneNumber}`}
          </a>
        </div>
      </div>
      <button
        className="py-2 font-normal text-center text-white bg-black border border-black
                hover:font-semibold px-7 hover:cursor-pointer   text-sm w-64"
        onClick={callback}>
        {`Didn't reach me? Send me a message and I'll call you back`}
      </button>
    </div>
  );
}

PhoneForm.propTypes = {
  agent: PropTypes.any.isRequired
};
export default PhoneForm;
