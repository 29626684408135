import GTCService, { APIService } from './API';
import httpService, { HttpService } from './httpService';

export type Services = {
  apiService: APIService;
  httpService: HttpService;
};

const GTCServices = () => {
  const http = httpService();
  const apiService = GTCService(http);

  return {
    gtcService: apiService,
    httpService: http
  };
};

export default GTCServices;
