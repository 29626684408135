import React, { useEffect, useState } from 'react';
import APIService from '../../../services/services';
const itemToShow = 5;

function Language({ destination, city, interest, state, language, metroRegion, zip, supplier, callback }: any) {
  const type = 'FacetedLanguages';
  const [data, setData] = useState<any[]>([]);
  const [showFull, setShowFull] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type,
        radius: ''
      })
      .then((response) => {
        setLoading(false);
        if (response.responseStatus === 1) {
          setData(
            [
              {
                language: 'English'
              }
            ].concat(response.data)
          );
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);

  return (
    <div>
      <div className="my-2 text-[1.1rem] font-semibold text-base uppercase text-black bg-gtc6 py-3 px-1 md:px-0 md:py-0 md:bg-white md:border-none border-b border-black">
        AGENT LANGUAGE
      </div>
      {loading ? (
        <div className="flex animate-pulse flex-row  h-full justify-center">
          <div className=" bg-gtc4 h-44 w-full mr-4 mb-2"></div>
        </div>
      ) : (
        <>
          {data.length > 0 && (
            <div className="text-4xl flex flex-col">
              <div className="flex flex-col items-start ">
                <div className="flex flex-col items-start ">
                  {data.slice(0, itemToShow).map((x) => (
                    <div
                      key={x.language}
                      onClick={() => (x.language === 'English' ? {} : callback(x.language))}
                      className={[
                        'text-sm  ',
                        x.language === language ? 'text-gray-400' : 'text-black hover:text-green-light',
                        x.language === 'English' ? '' : ' cursor-pointer'
                      ].join(' ')}
                      role="button"
                      tabIndex={0}>
                      {x.language}
                    </div>
                  ))}
                  <div
                    className={`${showFull ? 'max-h-[5000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
                    {data.slice(itemToShow, data.length).map((x) => (
                      <div
                        key={x.language}
                        onClick={() => callback(x.language)}
                        className={[
                          'text-sm  cursor-pointer ',
                          x.language === language ? 'text-gray-400' : 'text-black hover:text-green-light'
                        ].join(' ')}
                        role="button"
                        tabIndex={0}>
                        {x.language}
                      </div>
                    ))}
                  </div>
                </div>
                {data.length > 5 && (
                  <button
                    type="button"
                    className="text-sm  cursor-pointer font-semibold text-black text-[1rem] hover:text-black mt-2  self-end md:self-start"
                    onClick={() => setShowFull(!showFull)}>
                    {!showFull ? (
                      <div className="flex flex-row gap-1 items-center">
                        {/* <IoPlaySharp /> */}
                        More Languages
                        {` (${data.length - 5})`}
                        <i role="presentation" className="fa fa-angle-double-down"></i>
                      </div>
                    ) : (
                      <div className="flex flex-row gap-1 items-center">
                        Close
                        <i role="presentation" className="fa fa-angle-double-up"></i>
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Language;
