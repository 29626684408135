import React, { useEffect, useState } from 'react';
import APIService from '../../../services/services';

const itemToShow = 5;

function Interest({ destination, city, interest, state, language, metroRegion, zip, supplier, callback }: any) {
  const type = 'FacetedInterests';
  const [data, setData] = useState<any[]>([]);
  const [showFull, setShowFull] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchFaceted({
        destination: destination || '',
        agencyCity: city || '',
        interest: interest || '',
        state: state || '',
        language: language || '',
        metroRegion: metroRegion || '',
        zip: zip || '',
        supplier: supplier || '',
        type,
        radius: ''
      })
      .then((response) => {
        setLoading(false);
        if (response.responseStatus === 1) {
          setData(response.data);
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);

  // useEffect(() => {
  //   if (interest) {
  //     grouped.map((x) => {
  //       if (x.items.find((y) => y.label === interest)) {
  //         x.show = true;
  //       }
  //     });
  //     setGrouped([...grouped]);
  //     trigger();
  //   }
  // }, [interest]);

  // useEffect(() => {
  //   groupedSpecialities();
  // }, [data]);

  return (
    <>
      <div>
        <div className="my-2 text-[1.1rem] font-semibold text-base uppercase text-black bg-gtc6 py-3 px-1 md:px-0 md:py-0 md:bg-white md:border-none border-b border-black">
          TRAVEL SPECIALTY
        </div>
        {loading ? (
          <div className="flex animate-pulse flex-row  h-full justify-center">
            <div className=" bg-gtc4 h-44 w-full mr-4 mb-2"></div>
          </div>
        ) : (
          <>
            {data.length > 0 ? (
              <div className="text-4xl flex flex-col">
                <div className="flex flex-col items-start ">
                  <div className="flex flex-col items-start w-full ">
                    <div className="flex flex-col items-start pl-1 w-48 gap-1">
                      {data.slice(0, itemToShow).map((y) => (
                        <div
                          key={y.interest}
                          onClick={() => callback(y.interest)}
                          className={[
                            'text-sm cursor-pointer font-normal',
                            y.interest === interest ? 'text-black' : 'text-gray-800 hover:text-black'
                          ].join(' ')}
                          role="button"
                          tabIndex={0}>
                          {y.interest}
                        </div>
                      ))}
                      <div
                        className={`${
                          showFull ? 'max-h-[5000px]' : 'max-h-0'
                        } transition-all delay-150 duration-300 overflow-hidden w-full flex  gpa-1 flex-col`}>
                        {data.slice(itemToShow, data.length).map((y) => (
                          <div
                            key={y.interest}
                            onClick={() => callback(y.interest)}
                            className={[
                              'text-sm  cursor-pointer font-normal',
                              y.interest === interest ? 'text-black' : 'text-gray-800 hover:text-black'
                            ].join(' ')}
                            role="button"
                            tabIndex={0}>
                            {y.interest}
                          </div>
                        ))}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="text-sm  cursor-pointer font-semibold text-black text-[1rem] hover:text-black mt-2  self-end md:self-start"
                      onClick={() => setShowFull(!showFull)}>
                      {!showFull ? (
                        <div className="flex flex-row gap-1 items-center">
                          {/* <IoPlaySharp /> */}
                          More Specialty
                          {` (${data.length - 5})`}
                          <i role="presentation" className="fa fa-angle-double-down"></i>
                        </div>
                      ) : (
                        <div className="flex flex-row gap-1 items-center">
                          Close
                          <i role="presentation" className="fa fa-angle-double-up"></i>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-sm">Please broaden your search criteria</div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Interest;
