/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { AgentDetailViewModel, MediaEntity } from '../../../helpers/types';
import Modal from 'react-modal';
import { modalCustomStyles, modalGalleryCustomStyles } from '../../../helpers/constants';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import AgentAlbum from './AgentAlbum';
import AgentStories from './AgentStories';

interface TravelExperienceProps {
  data: AgentDetailViewModel;
}

function TravelExperience({ data }: TravelExperienceProps) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const parsed = queryString.parse(searchParams.toString());

  return (
    <>
      <div className="flex flex-col gap-4 pb-4">
        <h2 className="font-bold text-3xl mt-0 mb-0">
          {parsed.bioId != null ? 'Related Travel Experiences' : 'Travel Experiences'}
        </h2>
        <AgentAlbum data={data} />
        <AgentStories data={data} />
      </div>
    </>
  );
}

export default TravelExperience;
