import { objToQueryString } from '../helpers/functions';
import {
  AgentDetailQuery,
  AgentDetailViewModel,
  AgentSearchParameters,
  AgentSearchResult,
  EmailAgentViewModel,
  EmailUtilityViewModel,
  FacetedFilter,
  Interests,
  Itinerary,
  PhoneAgentViewModel,
  PromoDetailView,
  PromoItemViewModel,
  ResponseDTO,
  StoryDetailView,
  StoryItemViewModel
} from '../helpers/types';
import { HttpService } from './httpService';
/* eslint-disable no-unused-vars */

export interface APIService {
  fetchInterests: () => Promise<ResponseDTO<Interests[]>>;
  fetchStates: () => Promise<ResponseDTO<any[]>>;
  fetchDestinations: () => Promise<ResponseDTO<any[]>>;
  fetchAgents: (filter: AgentSearchParameters) => Promise<ResponseDTO<AgentSearchResult>>;
  getBookWithUs: () => Promise<ResponseDTO<any[]>>;
  getFeatured: () => Promise<ResponseDTO<any[]>>;
  fetchFaceted: (payload: FacetedFilter) => Promise<ResponseDTO<any[]>>;
  sendAgentEmailLead: (payload: EmailAgentViewModel) => Promise<ResponseDTO<boolean>>;
  sendAgentPhoneLead: (payload: PhoneAgentViewModel) => Promise<ResponseDTO<boolean>>;
  fetchAgent: (payload: AgentDetailQuery) => Promise<ResponseDTO<AgentDetailViewModel>>;
  fetchAgentBlog: (payload: AgentDetailQuery) => Promise<ResponseDTO<AgentDetailViewModel>>;
  sendReview: (reviewId: string, isHelpful: boolean, agentId: number) => Promise<ResponseDTO<boolean>>;
  sendSocialLead: (agentId: number, agencyId: number, referralURL: string, socialSite: string) => Promise<ResponseDTO<boolean>>;
  fetchStories: (categoryName?: string, row?: number) => Promise<ResponseDTO<StoryItemViewModel[]>>;
  fetchStory: (storyId: number, agentId?: number, preview?: boolean) => Promise<ResponseDTO<StoryDetailView>>;
  fetchPromos: (type?: string) => Promise<ResponseDTO<PromoItemViewModel[]>>;
  fetchPromo: (promoId: number, agentId?: number, preview?: boolean) => Promise<ResponseDTO<PromoDetailView>>;
  fetchItinerary: (agentId: string, preview?: boolean, bioId?: string, itineraryId?: string) => Promise<ResponseDTO<Itinerary[]>>;
  sendEmailUtility: (payload: EmailUtilityViewModel) => Promise<ResponseDTO<string>>;
  seenInMedia: () => Promise<ResponseDTO<PromoDetailView>>;
}

const GTCService = (httpService: HttpService): APIService => {
  const fetchInterests = (): Promise<ResponseDTO<any[]>> => {
    return httpService.GET('/lookup/getInterests').then(async (res: ResponseDTO<any[]>) => {
      return res;
    });
  };

  const fetchStates = (): Promise<ResponseDTO<any[]>> => {
    return httpService.GET('/lookup/getStates').then(async (res: ResponseDTO<any[]>) => {
      return res;
    });
  };

  const fetchDestinations = (): Promise<ResponseDTO<any[]>> => {
    return httpService.GET('/lookup/getDestinations').then(async (res: ResponseDTO<any[]>) => {
      return res;
    });
  };

  const fetchAgents = (filter: AgentSearchParameters): Promise<ResponseDTO<AgentSearchResult>> => {
    return httpService.POST('/agents/all', JSON.stringify(filter)).then(async (res: ResponseDTO<AgentSearchResult>) => {
      return res;
    });
  };

  const fetchFaceted = (payload: FacetedFilter): Promise<ResponseDTO<any[]>> => {
    return httpService.POST(`/lookup/${payload.type}`, JSON.stringify(payload)).then(async (res: ResponseDTO<any[]>) => {
      return res;
    });
  };

  const sendAgentEmailLead = (payload: EmailAgentViewModel): Promise<ResponseDTO<boolean>> => {
    return httpService.POST(`/agent/sendEmailLead`, JSON.stringify(payload)).then(async (res: ResponseDTO<boolean>) => {
      return res;
    });
  };

  const sendAgentPhoneLead = (payload: PhoneAgentViewModel): Promise<ResponseDTO<boolean>> => {
    return httpService.POST(`/agent/sendPhoneLead`, JSON.stringify(payload)).then(async (res: ResponseDTO<boolean>) => {
      return res;
    });
  };
  const fetchAgent = (payload: AgentDetailQuery): Promise<ResponseDTO<AgentDetailViewModel>> => {
    return httpService.GET(`/agent/get`, objToQueryString(payload)).then(async (res: ResponseDTO<AgentDetailViewModel>) => {
      return res;
    });
  };

  const sendReview = (reviewId: string, isHelpful: boolean, agentId: number): Promise<ResponseDTO<boolean>> => {
    return httpService
      .POST(`/agent/sendReview?reviewId=${reviewId}&isHelpful=${isHelpful}&agentId=${agentId}`, {})
      .then(async (res: ResponseDTO<boolean>) => {
        return res;
      });
  };

  const sendSocialLead = (agentId: number, agencyId: number, referralURL: string, socialSite: string): Promise<ResponseDTO<boolean>> => {
    return httpService
      .POST(`/agent/sendSocialLead?agentId=${agentId}&agencyId=${agencyId}&referralURL=${referralURL}&socialSite=${socialSite}`, {})
      .then(async (res: ResponseDTO<boolean>) => {
        return res;
      });
  };

  const fetchAgentBlog = (payload: AgentDetailQuery): Promise<ResponseDTO<AgentDetailViewModel>> => {
    return httpService.GET(`/blog/get`, objToQueryString(payload)).then(async (res: ResponseDTO<AgentDetailViewModel>) => {
      return res;
    });
  };

  const getBookWithUs = (): Promise<ResponseDTO<any[]>> => {
    return httpService.GET('/home/bookWithUs').then(async (res: ResponseDTO<any[]>) => {
      return res;
    });
  };
  const getFeatured = (): Promise<ResponseDTO<any[]>> => {
    return httpService.GET('/home/featuredContents').then(async (res: ResponseDTO<any[]>) => {
      return res;
    });
  };

  const fetchStories = (categoryName?: string, row?: number): Promise<ResponseDTO<StoryItemViewModel[]>> => {
    var query = '';
    if (categoryName !== undefined && row !== undefined) {
      query += `?categoryName=${categoryName}&row=${row}`;
    }

    return httpService.GET('/story/getall' + query).then(async (res: ResponseDTO<StoryItemViewModel[]>) => {
      return res;
    });
  };
  const fetchPromos = (type?: string): Promise<ResponseDTO<PromoItemViewModel[]>> => {
    return httpService
      .GET(`/promotion/getall?type=${type !== undefined ? type : 'ALL'}`)
      .then(async (res: ResponseDTO<PromoItemViewModel[]>) => {
        return res;
      });
  };

  const fetchStory = (storyId: number, agentId?: number, preview?: boolean): Promise<ResponseDTO<StoryDetailView>> => {
    return httpService
      .GET(`/story/get?storyId=${storyId}&agentId=${agentId ?? 0}&preview=${preview}`)
      .then(async (res: ResponseDTO<StoryDetailView>) => {
        return res;
      });
  };

  const fetchPromo = (storyId: number, agentId?: number, preview?: boolean): Promise<ResponseDTO<PromoDetailView>> => {
    return httpService
      .GET(`/promotion/get?promoId=${storyId}&agentId=${agentId ?? 0}&preview=${preview}`)
      .then(async (res: ResponseDTO<PromoDetailView>) => {
        return res;
      });
  };
  const fetchItinerary = (agentId: string, preview?: boolean, bioId?: string, itineraryId?: string): Promise<ResponseDTO<Itinerary[]>> => {
    return httpService
      .GET(`/agent/itineraries?agentId=${agentId ?? ''}&bioId=${bioId ?? 0}&preview=${preview}&itineraryId=${itineraryId ?? 0}`)
      .then(async (res: ResponseDTO<Itinerary[]>) => {
        return res;
      });
  };

  const sendEmailUtility = (payload: EmailUtilityViewModel): Promise<ResponseDTO<string>> => {
    return httpService.POST(`/utility/sendEmailUtility`, JSON.stringify(payload)).then(async (res: ResponseDTO<string>) => {
      return res;
    });
  };

  const seenInMedia = (): Promise<ResponseDTO<PromoDetailView>> => {
    return httpService.GET(`/promotion/AsSeenInMedia`).then(async (res: ResponseDTO<PromoDetailView>) => {
      return res;
    });
  };

  return {
    fetchInterests,
    fetchStates,
    fetchDestinations,
    fetchAgents,
    getBookWithUs,
    getFeatured,
    fetchFaceted,
    sendAgentEmailLead,
    sendAgentPhoneLead,
    fetchAgent,
    fetchAgentBlog,
    sendReview,
    sendSocialLead,
    fetchStories,
    fetchStory,
    fetchPromos,
    fetchPromo,
    fetchItinerary,
    sendEmailUtility,
    seenInMedia
  };
};

export default GTCService;
