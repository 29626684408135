import { Helmet } from 'react-helmet';

function Career() {
  return (
    <>
      <Helmet>
        <meta name="title" content="Carreers Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta name="description" content="Carreers Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <title>Carreers Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/careers"></link>
      </Helmet>
      <section className="w-full">
        <div
          className="large-hero justify-center px-4 bg-cover"
          role="img"
          aria-label="hero img"
          style={{ backgroundImage: "url('/images/career/Hero image 1024 px.webp')" }}>
          <div
            className="flex flex-col items-center"
            aria-label="Elevate your travel business by joining Global Travel Collection"
            role="main">
            <div>
              <h1 className="text-2xl md:text-65xl font-light text-gtc4 text-center">
                Elevate your travel business by joining Global Travel Collection
              </h1>
            </div>

            <div className="mt-10">
              <a
                href="/join-us"
                className="w-auto py-2 font-normal text-center bg-white text-black
            hover:font-semibold px-7 hover:cursor-pointer">
                Join Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <article className="p-4 mx-auto bg-white 2xl:container min-h-screen flex flex-col gap-8">
        <section className="flex flex-col gap-4">
          <h2 className="uppercase font-bold text-xl">Welcome To Your Community</h2>
          <div>
            Global Travel Collection (GTC) is the preferred thought leader in luxury world travel, representing the most sophisticated
            community of premier travel agencies worldwide.
          </div>
          <div>
            Our agencies are powered by state-of-the-art products, allowing advisors to receive personalized support, opportunities for
            professional growth, as well as first-hand access to industry perks.
          </div>
          <div>
            Our expertise in worldwide destinations, international faring and competitive products — in addition to a superior network of
            contacts in the airline, hotel, cruise and hospitality industry — make GTC’s community the most powerful selling force in the
            travel industry.
          </div>
        </section>

        <section className="px-2 md:px-7 bg-gtc4 md:mx-10 -mx-4 flex flex-col gap-4">
          <div className="md:p-4 p-2 border-b-2 pb-8 border-white flex flex-col gap-2">
            <h3 className="uppercase text-lg">Maximize your earnings</h3>
            <div>
              with proven lead-generation programs, innovative marketing solutions and career-accelerating professional development.
            </div>
          </div>
          <div className="md:p-4 p-2 border-b-2 pb-8 border-white  flex flex-col gap-2">
            <h3 className="uppercase text-lg">Utilize unparalleled access</h3>
            <div>
              to the industry’s top air, hotel and cruise partnerships, as well as value-added amenity programs that put your clients first.
            </div>
          </div>
          <div className="md:p-4 p-2 pb-8  flex flex-col gap-2">
            <h3 className="uppercase text-lg">Enjoy universal recognition</h3>
            <div>as an elite travel agent and industry influencer that’s respected by suppliers around the world.</div>
          </div>
        </section>

        <section className="flex flex-col gap-10">
          <h2 className="uppercase  text-xl my-2">Take your career to the highest level</h2>

          <div className="flex md:flex-row flex-col gap-8">
            <img
              src="/images/career/Image 01 424x398 px.webp"
              className="order-first md:order-none"
              alt="Training & ProfessionAL Development"
            />
            <div className=" pb-8  flex flex-col gap-2">
              <h4 className="uppercase text-lg border-b-2 mb-2 pb-2 border-gray-500">Training & ProfessionAL Development</h4>
              <div>
                Dive into the travel-selling experience through exceptional business development. Our learning platform provides a path for
                discovering the tools and technology provided by GTC. You’ll develop skills through interactive lessons and tip sheets, as
                well as weekly interactive and recorded webinars — all available on demand 24/7.
              </div>
              <div>
                Our professional development programs guide agents toward entrepreneurial growth through expanding knowledge, skills and the
                capability to drive better business performance. You’ll also receive assistance in creating powerful business plans and
                mastering professional fees.
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-8">
            <div className="pb-8  flex flex-col gap-2">
              <h4 className="uppercase text-lg border-b-2 mb-2 pb-2 border-gray-500">Airline Support</h4>
              <div>
                Receive important news, updates and advisories in your inbox. Our experienced, knowledgeable Revenue Management team
                maximizes value for your clients, including managed air programs with more than 100 carriers, offering commission, private
                fares and upgrades.
              </div>
              <div>
                Furthermore, our Air Specialty Desk assists advisors who refer clients to an air expert for managing complex itineraries and
                fare construction. You’ll receive 24/7 emergency help, plus premium sales support from major airlines.
              </div>
            </div>
            <img src="/images/career/Image 02 424x398 px.webp" className="order-first md:order-none" alt="Airline Support" />
          </div>

          <div className="flex md:flex-row flex-col gap-8">
            <img src="/images/career//Image 03 424x398 px.webp" alt="Agent Services" className="order-first md:order-none" />
            <div className="pb-8  flex flex-col gap-2">
              <h4 className="uppercase text-lg border-b-2 mb-2 pb-2 border-gray-500">Agent Services</h4>
              <div>
                Benefit from cutting-edge lead generation strategies and innovative, personalized marketing solutions that keep you
                top-of-mind among your clients. As a human-first community, we’ll inspire your clients’ future travels through thoughtfully
                curated and highly visual content that builds trust and relationships.
              </div>
              <div>
                GTC delivers customized emails, raises your Google rankings and provides social media collateral optimized for the platforms
                you use most. You’ll also be seen among our Agent Profiler platform, elevating your online presence and attracting your
                ideal clients.
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-8">
            <div className=" pb-8  flex flex-col gap-2">
              <h4 className="uppercase text-lg border-b-2 mb-2 pb-2 border-gray-500">Technology</h4>
              <div>
                Enjoy seamless integration with powerful online booking tools, including Concur, Certify and Sabre Red 360 — plus advanced
                proprietary travel management reporting. GTC’s global distribution system supports auto-ticketing, advanced quality control
                and the exportation of data to third parties.
              </div>
              <div>
                Our community technologically thrives with easy access to robust solutions, including traveler profiles, risk management
                systems and itinerary operations. Moreover, GTC’s IT team efficiently supports every challenge from general computer issues
                to complex network concerns.
              </div>
            </div>
            <img src="/images/career/Image 04 424x398 px.webp" alt="Technology" className="order-first md:order-none" />
          </div>
          <div className="flex justify-center mt-10">
            <a
              href="/join-us"
              className="w-auto py-2 font-normal text-center text-white bg-black border border-black 
				hover:font-semibold px-7 hover:cursor-pointer">
              {' '}
              Join us
            </a>
          </div>
        </section>

        <section className="flex flex-col md:flex-row gap-10 md:gap-4 bg-black ">
          <div className=" career-ring">
            <div className="grid grid-cols-2 gap-10 flex-1 p-8 relative">
              <img src="/images/career/logos/Logo_In-The-Know_White.webp" className="w-32" alt="INK" />
              <img src="/images/career/logos/Logo_Colletts Travel_White.webp" className="w-32" alt="Collets" />

              <img src="/images/career/logos/Logo_Protravel_White-Logo.webp" className="w-32" alt="Protravel" />
              <img src="/images/career/logos/Logo_All Star_White.webp" className="w-32" alt="All star" />

              <img src="/images/career/logos/Logo_R.-Cruso-_-Sons_White.webp" className="w-32" alt="Cruso" />
              <div className="grid grid-cols-2">
                <img src="/images/career/logos/Logo_Andrew Harper_White.webp" className="w-10" alt="Andrew Harper" />
                <img src="/images/career/logos/Logo_tzell_White.webp" className="w-10" alt="Tzell" />
              </div>
            </div>
          </div>

          <div
            className="flex flex-col gap-2 text-white flex-1 p-8"
            style={{
              backgroundImage: "url('/images/career/Background image.webp')",
              backgroundPositionY: ' 60%;'
            }}>
            <div className="uppercase font-bold">Travel Industry Leadership</div>
            <div>
              {`Trusted and respected around the globe, Global Travel Collection's advisors and
              executives have won numerous prestigious awards and serve on luxury advisory boards
              across the industry.`}
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default Career;
