import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PromoItemViewModel, StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import GetInspired from '../layout/advisor-offer/GetInspired';
import { blogTypeToPromoType } from '../../helpers/functions';
import { Hero } from '../../components/Hero';

interface Category {
  name: string;
  desc: string;
  title: string;
}

const categories: Category[] = [
  {
    name: 'Cruise Specials',
    desc: 'Our agents are avid cruisers who know these ships and can answer all your questions.',
    title: 'GET INSPIRED TO CRUISE'
  },
  {
    name: 'Exclusive Experiences',
    desc: 'Whether you are looking for a quick getaway, independent journey or a legendary experience, our expert travel agents are here for you.',
    title: 'GET INSPIRED TO TRAVEL'
  },

  {
    name: 'Hotel Specials',
    desc: 'Our agents are hotel and resort specialists who can answer any questions you may have about these properties and more.',
    title: 'GET INSPIRED TO TRAVEL'
  },
  {
    name: 'Vacation Packages',
    desc: 'Our agents have expert knowledge on all types of vacation packages and can help you tailor the perfect vacation of your dreams.',
    title: 'GET INSPIRED TO TRAVEL'
  },
  {
    name: 'Featured Destinations',
    desc: 'Our agents have expert knowledge on every destination and can help you tailor a luxury vacation to the destination of your dreams.',
    title: 'GET INSPIRED TO TRAVEL'
  }
];

function AdvisorOffers() {
  // eslint-disable-next-line no-unused-vars
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [promos, setPromos] = useState<PromoItemViewModel[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingStories, setLoadingStories] = useState<boolean>(false);

  const [showAll, setShowAll] = useState<boolean>(false);

  const [stories, setStories] = useState<StoryItemViewModel[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const parsed = queryString.parse(window.location.search);

  const filter = () => {
    return (
      <div className="bg-black p-4 md:p-8 flex flex-col gap-8 items-center">
        <div className="text-white text-sm md:text-2xl text-center">
          Search for travel specials by the following categories, then contact one of our expert travel agents to learn more.
        </div>
        <div className="grid md:grid-cols-5 gap-2  md:gap-8 w-full">
          {categories.map((x) => (
            <div
              className={[
                'cursor-pointer border-white p-4 text-center border text-white w-full text-sm lg:text-xl items-center flex justify-center',
                selectedCategory?.name === x.name ? 'font-bold' : 'font-normal'
              ].join(' ')}
              key={x.name}
              onClick={() => navigate(`/advisor-offers?type=${x.name}`)}
              role="button"
              tabIndex={0}>
              {x.name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const categoryText = () => {
    return selectedCategory !== undefined && selectedCategory.name !== 'ALL' ? (
      <div className="flex flex-col gap-4 items-center justify-center mt-10">
        <div className="text-3xl">{selectedCategory.name}</div>
        <div className="text-xl text-center md:text-left">{selectedCategory.desc}</div>
      </div>
    ) : (
      <></>
    );
  };

  const getPromos = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchPromos(blogTypeToPromoType(selectedCategory?.name || ''))
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setPromos(res.data);
        } else {
          setPromos([]);
        }
      });
  };

  const getStories = () => {
    setLoadingStories(true);
    APIService()
      .gtcService.fetchStories(selectedCategory?.name, 99)
      .then((res) => {
        setLoadingStories(false);
        if (res.responseStatus === 1) {
          setStories(res.data);
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    if (parsed?.type !== undefined) {
      if (categories.find((x) => x.name === parsed.type) !== undefined) {
        setSelectedCategory(categories.find((x) => x.name === parsed.type));
      } else {
        setSelectedCategory(
          //@ts-ignore
          {
            name: 'ALL'
          }
        );
      }
    } else {
      setSelectedCategory(
        //@ts-ignore
        {
          name: 'ALL'
        }
      );
    }
  }, [searchParams]);
  useEffect(() => {
    if (selectedCategory != undefined && selectedCategory.name != 'ALL') getStories();
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory != undefined) getPromos();
  }, [selectedCategory]);
  return (
    <div className="font-[Avenir-lt-Std]">
      <Helmet>
        <meta name="title" content="Find Your Next Dream Vacation | Global Travel Collection " />
        <meta
          name="description"
          content="When you work with a Global Travel Collection advisor, you receive access to exclusive
          perks, upgrades, and experiences. No matter how you choose to travel, Global Travel
          Collection will help you plan an unforgettable experience that’s right for you."
        />
        <title>Find Your Next Dream Vacation | Global Travel Collection</title>
        <link rel="canonical" href="/advsior-offers"></link>
      </Helmet>
      <Hero title="TRAVEL OFFERS" img="/images/new-hero/specials.webp" />

      <section className="-mt-1 md:-mt-20  2xl:container mx-auto md:px-24">{filter()}</section>
      <article className="px-4 mx-auto bg-white 2xl:container mt-4">
        <>{categoryText()}</>
        <section className="mb-24 mt-10 mx-2 md:mx-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full ">
            {loading ? (
              <div className="w-full h-[800px] z-50 overflow-hidden bg-gray-700 opacity-10 flex flex-col items-center justify-center col-span-2">
                <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
                {/* <h2 className="text-center text-white text-xl font-semibold">Loading...</h2> */}
              </div>
            ) : (
              <>
                {promos.slice(0, 6).map((promoItem) => (
                  <div key={promoItem.id}>
                    <div className="relative">
                      <a title={promoItem.title} href={promoItem.url} className="cursor-pointer">
                        <img
                          className="w-full object-cover "
                          src={promoItem.thumbnailImage}
                          alt={promoItem.title}
                          width={1920}
                          height={550}
                        />
                      </a>
                      <div className="text-center bg-black min-h-[120px] py-4 flex justify-center items-center">
                        <p className="m-0 text-xs  md:text-sm text-white uppercase font-normal font-[Avenir-lt-Std] px-2">
                          {promoItem.title}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={`${showAll ? 'max-h-[50000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mt-4">
              {promos.slice(6, promos.length).map((promoItem) => (
                <div key={promoItem.id}>
                  <div className="relative">
                    <a title={promoItem.title} href={promoItem.url} className="cursor-pointer">
                      <img className="w-full" src={promoItem.thumbnailImage} alt={promoItem.title} width={1920} height={550} />
                    </a>
                    <div className="text-center bg-black min-h-[120px] py-4 flex justify-center items-center">
                      <p className="m-0 text-xs  md:text-sm text-white uppercase font-normal font-[Avenir-lt-Std] px-2">
                        {promoItem.title}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {promos.length > 6 && (
            <div className="flex justify-center mt-4">
              <button
                className="py-2 font-normal text-center text-white
                   bg-black border border-black
                hover:font-semibold px-7 hover:cursor-pointer  "
                onClick={() => setShowAll(!showAll)}>
                {showAll ? 'View Fewer' : 'View More'}
              </button>
            </div>
          )}
        </section>
      </article>
      <div className="bg-black">
        <div className="flex 2xl:container mx-auto ">
          {selectedCategory && selectedCategory.name !== 'ALL' && stories.length > 0 && !loadingStories && (
            <GetInspired data={stories} title={selectedCategory.title} />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdvisorOffers;
