import { Helmet } from 'react-helmet';

function ThankYouContactUs() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main className="mx-auto min-h-full">
        <section id="mainsec" className="w-full">
          <div
            className="h-96 justify-center px-4 items-center flex"
            style={{
              backgroundImage: "url('/images/hero/Thank-you-contact-us.webp')",
              backgroundSize: 'cover',
              backgroundPosition: '50%'
            }}>
            <div className="flex flex-col items-center ">
              <div>
                <h1 className="text-2xl sm:text-65xl font-light text-gtc4 text-center">
                  Thank you for contacting us
                </h1>
              </div>
            </div>
          </div>
        </section>

        <article className="p-4 mx-auto bg-white 2xl:container">
          <div className="mx-2 md:mx-20">
            <p className="mb-0">Dear Traveler,</p>

            <div className="my-2.5">
              <p>
                Thank you for your inquiry.
                <br />
                {`We're`} excited to learn more about you to help make your travel dreams come true.
                You can expect to hear back from us via email
                <b> within 24 hours </b>
                to get started!
              </p>
            </div>
            <p>The Global Travel Collection Team</p>
          </div>
        </article>
      </main>
    </>
  );
}

export default ThankYouContactUs;
