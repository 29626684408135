interface HeaderTextProps {
  title: string;
}
export const HeaderText = (prop: HeaderTextProps) => {
  return (
    <h1
      className="text-[30px] sm:text-[45px] font-normal text-black text-center font-ivypresto-display"
      role="main"
      aria-label={prop.title}>
      {prop.title}
    </h1>
  );
};
