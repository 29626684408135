/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { AgentTestimonialsEntity, ReviewsEntity } from '../../../helpers/types';
import React from 'react';

const itemToShow = 5;

interface ClientTestimonialProps {
  testimonials: AgentTestimonialsEntity[];
}

function ClientTestimonial({ testimonials }: ClientTestimonialProps) {
  return (
    <div className="flex flex-col gap-4 text-sm">
      <h2 className="font-bold text-3xl mt-0 mb-0">Client Testimonials</h2>
      <div className="flex flex-col gap-8">
        {testimonials.map((x) => (
          <div key={x.title} className="flex flex-col gap-4">
            <div className="text-base">{x.title}</div>
            <div>{x.text}</div>
            <div className="text-right">Provided by {x.credit}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientTestimonial;
