import { Link } from 'react-router-dom';

export interface HeaderLinkProps {
  to: string;
  name: string;
  hasBorder?: boolean;
  isMobile?: boolean;
}

function HeaderLink(prop: HeaderLinkProps) {
  return (
    <Link
      to={prop.to}
      className={[
        'md:font-normal text-xs lg:text-sm',
        prop.hasBorder ? 'border py-2 px-4 w-fit' : '',
        prop.isMobile ? 'text-white  hover:text-gray-200 border-white ' : 'text-black  hover:text-gray-900 border-black '
      ].join(' ')}>
      {prop.name}
    </Link>
  );
}

export default HeaderLink;
