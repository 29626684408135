import { useEffect, useState } from 'react';

import APIService from '../../services/services';

import { PromoDetailView } from '../../helpers/types';
import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';

function SeenInMedia() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<PromoDetailView>();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const getPromo = () => {
    setLoading(true);
    APIService()
      .gtcService.seenInMedia()
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setData(res.data);
        } else {
          setData(undefined);
        }
      });
  };

  useEffect(() => {
    getPromo();
  }, []);
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
        <meta name="title" content={`${data?.promo.title} | Global Travel Collection`} />
        <meta name="description" content={`${data?.promo.title}`} />
        <title>{data?.promo.title || ''} | Global Travel Collection</title>
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>

      {loading ? (
        <div className="w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-10 flex flex-col items-center justify-center col-span-2">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <>
          {data && (
            <div className="flex flex-col items-center mb-14">
              <Hero title={data.promo.title} img={data.promo.heroImageUrl} />

              <div className="md:mx-auto bg-white 2xl:container px-2 md:px-4 mt-10">
                <section className=" mx-2 md:mx-20">
                  <section className="mt-4">
                    <div className="flex flex-col-reverse md:flex-row gap-4 md:gap-10">
                      <div className="w-full divBlogStory" dangerouslySetInnerHTML={{ __html: data.promo.promotionBody }} />
                    </div>
                  </section>
                </section>
              </div>
              <a href="https://internova.com/category/global-travel-collection" className="default-btn border-white border ">
                VIEW MORE
              </a>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SeenInMedia;
