/* eslint-disable no-unused-vars */
import { AgentConnection, AgentViewModel } from '../../../helpers/types';
import APIService from '../../../services/services';
import { getMoreReferralData, removeUTMCookies } from '../../../helpers/functions';
import { useNavigate } from 'react-router-dom';

interface AgentConnectionProps {
  agent?: AgentViewModel;
}

function AgentConnectionView({ agent }: AgentConnectionProps) {
  const navigate = useNavigate();
  //TODO: Send social leads
  const onClick = (connection: AgentConnection) => {
    let referralUrl = window.location.href;
    if (!referralUrl.toLowerCase().includes('utm_')) {
      referralUrl = window.location.href + getMoreReferralData();
    }

    if (agent)
      APIService()
        .gtcService.sendSocialLead(parseInt(agent.id), agent.agencyId, referralUrl, connection.contactValue)
        .then((response) => {
          if (response.responseStatus === 1) {
            removeUTMCookies();
            window.location.href = connection.contactValue;
          } else {
            window.location.href = connection.contactValue;
          }
        })
        .catch(() => {
          window.location.href = connection.contactValue;
        });
  };

  return (
    <>
      {(agent?.agentConnections?.filter((x) => x.contactMethod !== 'by Email' && x.contactMethod !== 'by Phone')?.length || 0) > 0 && (
        <div className="flex flex-row gap-4 justify-center my-2">
          {agent?.agentConnections
            .filter((x) => x.contactMethod !== 'by Email' && x.contactMethod !== 'by Phone')
            .map((x) => (
              <div onClick={() => onClick(x)} key={x.contactMethod} className="cursor-pointer">
                {x.contactMethod === 'Facebook' && <i role="presentation" className="fa-brands fa fa-facebook text-2xl"></i>}
                {x.contactMethod === 'LinkedIn' && <i role="presentation" className="fa-brands fa fa-linkedin text-2xl"></i>}
                {x.contactMethod === 'Twitter' && <i role="presentation" className="fa-brands fa fa-twitter text-2xl "></i>}
                {x.contactMethod === 'Instagram' && <i role="presentation" className="fa-brands fa fa-instagram text-2xl"></i>}
                {x.contactMethod === 'Pinterest' && <i role="presentation" className="fa-brands fa fa-pinterest text-2xl"></i>}
                {x.contactMethod === 'YouTube' && <i role="presentation" className="fa-brands fa fa-youtube text-2xl"></i>}
                {x.contactMethod === 'My Blog' && <i role="presentation" className="fa-solid fa fa-blog text-2xl"></i>}
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default AgentConnectionView;
