import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { EmailUtilityViewModel } from '../../helpers/types';
import APIService from '../../services/services';

const emailTopics = ['Media Inquiries', 'Technical Issues', 'Become a GTC Independent Advisor (IC)', 'Other'];

function ContactUs() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [isProcessing, setIsProcessing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<EmailUtilityViewModel>(
    //@ts-ignore
    {
      fullName: '',
      fromEmail: '',
      comments: '',
      toEmail: ''
    }
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log(data);
    setIsProcessing(true);
    APIService()
      .gtcService.sendEmailUtility(data)
      .then(() => {
        setIsProcessing(false);
        navigate('/thank-you-contact-us');
      });
    //navigate('/thank-you-contact-us');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-[Avenir-lt-Std]">
      <Helmet>
        <meta name="title" content="Contact Us Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta
          name="description"
          content="For media inquiries, technical issues with the website, or becoming a GTC Independent Advisors (IC), please contact us using the form. For travel inquiries, please contact one of our agents directly."
        />
        <title>Contact Us Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/contact-us"></link>
      </Helmet>
      <section id="mainsec" className="w-full ">
        <div
          aria-label="Hero Image"
          role="img"
          className="h-96 justify-center px-4 items-center flex"
          style={{
            backgroundImage: "url('/images/hero/Contact Us Page Image_2x.webp')",
            backgroundSize: 'cover',
            backgroundPosition: '50%'
          }}>
          <div className="flex flex-col items-center ">
            <div>
              <h1 className="text-2xl sm:text-65xl font-light text-gtc4 text-center">Contact Us</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="2xl:container mx-auto flex flex-col md:flex-row gap-6 py-[50px] md:px-12 px-4">
        <form className="flex-1 flex flex-col gap-4 md:mr-20 sf-form" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Name</div>
            <input
              id="Name"
              maxLength={80}
              name="name"
              type="text"
              required
              aria-label="Name"
              aria-required={true}
              onChange={(event) => {
                data.fullName = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Email</div>
            <input
              id="Email"
              maxLength={80}
              name="email"
              type="email"
              aria-label="Email"
              aria-required={true}
              required
              onChange={(event) => {
                data.fromEmail = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Email Topic</div>
            <select
              id="EmailTopic"
              name="EmailTopic"
              aria-label="Email Topic"
              title="Which best describes you?"
              required
              aria-required={true}
              onChange={(event) => {
                data.toEmail = event.target.value;
                setData({ ...data });
              }}>
              <option value="">Select Email Topic</option>
              {emailTopics.map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Your message</div>
            <textarea
              id="yourMessage"
              name="yourMessage"
              aria-label="Your message"
              aria-required={true}
              required
              rows={3}
              onChange={(event) => {
                data.comments = event.target.value;
                setData({ ...data });
              }}
              //type="text"
              wrap="soft"></textarea>
          </div>
          <div className="flex-1 flex flex-col gap-6 items-center my-10">
            <button
              className={[
                'py-2 font-normal text-center text-white  border border-black px-7   text-sm',
                isProcessing ? 'bg-gray-500 cursor-not-allowed' : 'bg-black cursor-pointer  hover:font-semibold'
              ].join(' ')}
              disabled={isProcessing}
              type="submit">
              {isProcessing ? `Sending Email...` : `Send Email`}
            </button>
          </div>
        </form>
        <div className="flex-1 flex flex-col gap-6">
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl">GENERAL INQUIRES</div>
            <div className="text-lg md:text-2xl">
              For media inquiries, technical issues with the website, or becoming a GTC Independent Advisors (IC), please contact us using
              the form. For travel inquiries, please contact one of our agents directly.
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl">LEISURE TRAVEL INQUIRIES</div>
            <div className="text-lg md:text-2xl">
              We would love the opportunity to share our knowledge and expertise with you on your next journey. We are passionate about
              helping you travel better with us, please use our agent locator.
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl">BUSINESS TRAVEL INQUIRIES</div>
            <div className="text-lg md:text-2xl">
              We look forward to helping you with your business travel management needs. You can visit{' '}
              <a href="https://business.globaltravelcollection.com" className="font-bold underline">
                business.globaltravelcollection.com
              </a>{' '}
              to find out more about our corporate travel services.
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl">CAREER INQUIRES</div>
            <div className="text-lg md:text-2xl">
              For career inquiries, please visit our{' '}
              <a href="https://careers-internova.icims.com/jobs/intro" className="font-bold underline">
                career site
              </a>{' '}
              to view all opportunities.
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-6 md:px-12 px-4 py-5 bg-gtc11 -mx-4 md:mx-0">
            <div className="text-xl md:text-3xl">OUR MAILING ADDRESS</div>
            <div>
              <div className="text-lg md:text-2xl">1633 Broadway, Floor 35</div>
              <div className="text-lg md:text-2xl">New York, NY 10019</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
