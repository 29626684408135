import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import { Hero } from '../../components/Hero';

const optionsDescribeBusiness = [
  { value: 'Corporate', label: 'Corporate' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Group', label: 'Group' },
  { value: 'Incentive', label: 'Incentive' },
  { value: 'Leisure', label: 'Leisure' },
  { value: 'Luxury', label: 'Luxury' }
];

const optionsMembership = [
  { value: 'Signature', label: 'Signature' },
  { value: 'Virtuoso', label: 'Virtuoso' },
  { value: 'American Express', label: 'American Express' },
  { value: 'Ensemble Travel', label: 'Ensemble Travel' },
  { value: 'Other', label: 'Other' },
  { value: 'None', label: 'None' }
];

const formSubmitUrl = 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';

/* eslint-disable react/no-unescaped-entities */
function JoinUs() {
  // eslint-disable-next-line no-unused-vars
  const [interestType, setInterestType] = useState<'Independent' | 'Employee' | 'Partner'>('Independent');
  const [searchParams, setSearchParams] = useSearchParams();
  const [formType, setFormType] = useState<'New' | 'Experienced'>();
  const [reCaptcha, setReCaptcha] = useState<string | null>(null);
  const [leadSource, setLeadSource] = useState<string>('globaltravelcollection.com');
  const [country, setCountry] = useState<string>('');
  const [isStateRequired, setIsStateRequired] = useState<boolean>(false);
  const baseUrl = window.location.protocol + '//' + window.location.host;
  const thankYouNew = baseUrl + '/thankyou-new-agent';
  const thankYouExperienced = baseUrl + '/thankyou-exprienced-agent';

  const onRecaptchChange = (value: any) => {
    setReCaptcha(value);
  };

  const handleSubmit = (event: any) => {
    if (reCaptcha === null) {
      event.preventDefault();
      alert('Please check the recaptcha');
      return;
    }
  };
  const handleSubmitNew = (event: any) => {
    if (reCaptcha === null) {
      event.preventDefault();
      alert('Please check the recaptcha');
      return;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setInterestType('Independent');
    }, 200);
  }, []);
  useEffect(() => {
    if (interestType === 'Employee') {
      window.location.href = 'https://internova.com/careers';
    } else if (interestType === 'Partner') {
      window.location.href = 'https://rfp.internovatravel.com/icp/welcome';
    }
  }, [interestType]);

  useEffect(() => {
    setReCaptcha(null);
    setCountry('');
    setIsStateRequired(false);
  }, [formType]);

  useEffect(() => {
    if (country === 'United States' || country === 'USA') {
      // Make state required
      setIsStateRequired(true);
    } else {
      setIsStateRequired(false);
    }
  }, [country]);

  useEffect(() => {
    const parsed = queryString.parse(searchParams.toString());
    if (parsed?.lead) {
      if (parsed.lead.toString().toLowerCase() === 'itke') {
        setLeadSource('intheknowexperiences.com');
      } else if (parsed.lead.toString().toLowerCase() === 'tzell') {
        setLeadSource('tzell.com');
      } else if (parsed.lead.toString().toLowerCase() === 'protravel') {
        setLeadSource('protravelinc.com');
      }
    }
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Join Us Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/join-us"></link>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <Hero title="JOIN US" img="/images/new-hero/joinus.webp" class="!bg-[50%_20%] !bg-auto md:!bg-cover md:!bg-[50%_25%]" />

      <article className="p-4 mx-auto bg-white 2xl:container">
        <section className="flex flex-col md:items-center gap-6 mt-6">
          <div className="flex flex-col gap-4 text-center">
            <div className="text-2xl md:text-4xl uppercase font-medium">Maximize your opportunities</div>
            <div className="text-gtc1 text-xl">Please complete the brief form below and out team will contact you shortly:</div>
          </div>
          <div className="flex flex-col gap-4 md:text-center">
            <div className="text-gtc1 text-xl">I am interested in joining GTC as a(n):</div>
            <div className="flex flex-col md:flex-row gap-6 text-[19px] md:text-[26px] font-light font-[Avenir]">
              <div className="flex flex-row gap-2 radio">
                <input
                  type="radio"
                  autoComplete="off"
                  className="accent-black"
                  defaultChecked
                  value="Independent"
                  checked={interestType === 'Independent'}
                  onChange={() => setInterestType('Independent')}
                />
                <label htmlFor="new" onClick={() => setInterestType('Independent')} className="hover:cursor-pointer radio-label">
                  Independent Travel Advisor
                </label>
              </div>
              <div className="flex flex-row gap-2 radio">
                <input
                  autoComplete="off"
                  type="radio"
                  className="accent-black"
                  value="Employee"
                  checked={interestType === 'Employee'}
                  onChange={() => setInterestType('Employee')}
                />
                <label htmlFor="experienced" onClick={() => setInterestType('Employee')} className="hover:cursor-pointer radio-label">
                  Employee and/or House Agent
                </label>
              </div>
              <div className="flex flex-row gap-2 radio">
                <input
                  type="radio"
                  autoComplete="off"
                  className="accent-black"
                  value="Partner"
                  checked={interestType === 'Partner'}
                  onChange={() => setInterestType('Partner')}
                />
                <label htmlFor="experienced" onClick={() => setInterestType('Partner')} className="hover:cursor-pointer radio-label">
                  Preferred Partner
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 md:items-center mt-4">
            <div className="text-gtc1 text-xl">What is your level of experience?</div>
            <div className="flex flex-col md:flex-row gap-6 text-[19px] md:text-[26px] font-light">
              <div className="flex flex-row gap-2 radio">
                <input
                  type="radio"
                  className="accent-black"
                  name="agentType"
                  value="new"
                  checked={formType === 'New'}
                  onChange={() => setFormType('New')}
                />
                <label htmlFor="new" onClick={() => setFormType('New')} className="hover:cursor-pointer radio-label">
                  I am new to the travel industry
                </label>
              </div>
              <div className="flex flex-row gap-2 radio">
                <input
                  type="radio"
                  className="accent-black"
                  name="agentType"
                  value="experienced"
                  checked={formType === 'Experienced'}
                  onChange={() => setFormType('Experienced')}
                />
                <label htmlFor="experienced" onClick={() => setFormType('Experienced')} className="hover:cursor-pointer radio-label">
                  I am an experienced travel agent
                </label>
              </div>
            </div>
          </div>
        </section>
        <section className="flex justify-center flex-col mt-10 gap-10 md:mx-32">
          {formType === 'Experienced' && (
            <form
              action={formSubmitUrl}
              method="POST"
              id="experiencedForm"
              onSubmit={handleSubmit}
              className="w-full justify-center flex flex-col gap-10 sf-form ">
              <input type="hidden" name="oid" value="00D300000000U26" />
              <input type="hidden" name="retURL" value={thankYouExperienced} />

              <div className="flex flex-col md:flex-row gap-10 justify-center ">
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="first_name">
                      <span className="text-red-500">*</span>First Name
                    </label>
                    <input
                      id="first_name"
                      maxLength={40}
                      name="first_name"
                      size={20}
                      type="text"
                      pattern="^[A-Z][a-zA-Z\-\. ]+$"
                      title="Please enter only alphabets and starting with uppercase"
                      required
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="last_name">
                      <span className="text-red-500">*</span>Last Name
                    </label>
                    <input
                      id="last_name"
                      maxLength={80}
                      name="last_name"
                      size={20}
                      pattern="^[A-Z][a-zA-Z\-\. ]+$"
                      type="text"
                      title="Please enter only alphabets and starting with uppercase"
                      required
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="phone">
                      <span className="text-red-500">*</span>Phone Number
                    </label>
                    <input
                      id="phone"
                      type="tel"
                      pattern="^(?:\+?1\s*(?:[.-]\s*)?)?(?:\([2-9]\d{2}\)|[2-9]\d{2})\s*(?:[.-]\s*)?\d{3}\s*(?:[.-]\s*)?\d{4}$"
                      //onInput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      title="Please enter proper phone number"
                      maxLength={30}
                      name="phone"
                      size={20}
                      required
                    />
                    <span>'+' not needed</span>
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="email">
                      <span className="text-red-500">*</span>Email
                    </label>
                    <input id="email" maxLength={80} name="email" size={20} type="email" required />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Country:
                    </div>
                    <select
                      id="Country__c"
                      name="Country__c"
                      title="Country"
                      className="experiencedCountry"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required>
                      <option value="">--None--</option>

                      <option value="AFG">Afghanistan</option>
                      <option value="ALA">Aland Islands</option>
                      <option value="DZA">Algeria</option>
                      <option value="AND">Andorra</option>
                      <option value="AGO">Angola</option>
                      <option value="AIA">Anguilla</option>
                      <option value="ATA">Antarctica</option>
                      <option value="ATG">Antigua and Barbuda</option>
                      <option value="ARG">Argentina</option>
                      <option value="ARM">Armenia</option>
                      <option value="ABW">Aruba</option>
                      <option value="AUS">Australia</option>
                      <option value="AUT">Austria</option>
                      <option value="AZE">Azerbaijan</option>
                      <option value="BHS">Bahamas</option>
                      <option value="BHR">Bahrain</option>
                      <option value="Bali">Bali</option>
                      <option value="BGD">Bangladesh</option>
                      <option value="BRB">Barbados</option>
                      <option value="BLR">Belarus</option>
                      <option value="BEL">Belgium</option>
                      <option value="BLZ">Belize</option>
                      <option value="BEN">Benin</option>
                      <option value="BMU">Bermuda</option>
                      <option value="BTN">Bhutan</option>
                      <option value="BOL">Bolivia</option>
                      <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                      <option value="BIH">Bosnia and Herzegovina</option>
                      <option value="BWA">Botswana</option>
                      <option value="BVT">Bouvet Island</option>
                      <option value="BRA">Brazil</option>
                      <option value="IOT">British Indian Ocean Territory</option>
                      <option value="BRN">Brunei Darussalam</option>
                      <option value="BGR">Bulgaria</option>
                      <option value="BFA">Burkina Faso</option>
                      <option value="BDI">Burundi</option>
                      <option value="KHM">Cambodia</option>
                      <option value="CMR">Cameroon</option>
                      <option value="CAN">Canada</option>
                      <option value="CPV">Cape Verde</option>
                      <option value="CYM">Cayman Islands</option>
                      <option value="CAF">Central African Republic</option>
                      <option value="TCD">Chad</option>
                      <option value="CHL">Chile</option>
                      <option value="CHN">China</option>
                      <option value="CXR">Christmas Island</option>
                      <option value="CCK">Cocos (Keeling) Islands</option>
                      <option value="COL">Colombia</option>
                      <option value="COM">Comoros</option>
                      <option value="COD">Congo, the Democratic Republic of the</option>
                      <option value="COK">Cook Islands</option>
                      <option value="CRI">Costa Rica</option>
                      <option value="HRV">Croatia</option>
                      <option value="CUB">Cuba</option>
                      <option value="CUW">Curaçao</option>
                      <option value="CYP">Cyprus</option>
                      <option value="CZE">Czech Republic</option>
                      <option value="DNK">Denmark</option>
                      <option value="DJI">Djibouti</option>
                      <option value="DMA">Dominica</option>
                      <option value="DOM">Dominican Republic</option>
                      <option value="ECU">Ecuador</option>
                      <option value="EGY">Egypt</option>
                      <option value="SLV">El Salvador</option>
                      <option value="ENG">England</option>
                      <option value="GNQ">Equatorial Guinea</option>
                      <option value="ERI">Eritrea</option>
                      <option value="EST">Estonia</option>
                      <option value="ETH">Ethiopia</option>
                      <option value="FLK">Falkland Islands (Malvinas)</option>
                      <option value="FRO">Faroe Islands</option>
                      <option value="FJI">Fiji</option>
                      <option value="FIN">Finland</option>
                      <option value="FRA">France</option>
                      <option value="GUF">French Guiana</option>
                      <option value="PYF">French Polynesia</option>
                      <option value="ATF">French Southern Territories</option>
                      <option value="GAB">Gabon</option>
                      <option value="GMB">Gambia</option>
                      <option value="GEO">Georgia</option>
                      <option value="DEU">Germany</option>
                      <option value="GHA">Ghana</option>
                      <option value="GIB">Gibraltar</option>
                      <option value="GRC">Greece</option>
                      <option value="GRL">Greenland</option>
                      <option value="GRD">Grenada</option>
                      <option value="GLP">Guadeloupe</option>
                      <option value="GTM">Guatemala</option>
                      <option value="GGY">Guernsey</option>
                      <option value="GIN">Guinea</option>
                      <option value="GNB">Guinea-Bissau</option>
                      <option value="GUY">Guyana</option>
                      <option value="HTI">Haiti</option>
                      <option value="HMD">Heard Island and McDonald Islands</option>
                      <option value="VAT">Holy See (Vatican City State)</option>
                      <option value="HND">Honduras</option>
                      <option value="HKG">Hong Kong</option>
                      <option value="HUN">Hungary</option>
                      <option value="ISL">Iceland</option>
                      <option value="IND">India</option>
                      <option value="IDN">Indonesia</option>
                      <option value="IRN">Iran, Islamic Republic of</option>
                      <option value="IRQ">Iraq</option>
                      <option value="IRL">Ireland</option>
                      <option value="IMN">Isle of Man</option>
                      <option value="ISR">Israel</option>
                      <option value="ITA">Italy</option>
                      <option value="JAM">Jamaica</option>
                      <option value="JPN">Japan</option>
                      <option value="JEY">Jersey</option>
                      <option value="JOR">Jordan</option>
                      <option value="KAZ">Kazakhstan</option>
                      <option value="KEN">Kenya</option>
                      <option value="KIR">Kiribati</option>
                      <option value="PRK">Korea, Democratic People’s Republic of</option>
                      <option value="KOR">Korea, Republic of</option>
                      <option value="KWT">Kuwait</option>
                      <option value="KGZ">Kyrgyzstan</option>
                      <option value="LAO">Lao People’s Democratic Republic</option>
                      <option value="LVA">Latvia</option>
                      <option value="LBN">Lebanon</option>
                      <option value="LSO">Lesotho</option>
                      <option value="LBR">Liberia</option>
                      <option value="LBY">Libya</option>
                      <option value="LIE">Liechtenstein</option>
                      <option value="LTU">Lithuania</option>
                      <option value="LUX">Luxembourg</option>
                      <option value="MAC">Macao</option>
                      <option value="MKD">Macedonia, the former Yugoslav Republic of</option>
                      <option value="MDG">Madagascar</option>
                      <option value="MWI">Malawi</option>
                      <option value="MYS">Malaysia</option>
                      <option value="MDV">Maldives</option>
                      <option value="MLI">Mali</option>
                      <option value="MLT">Malta</option>
                      <option value="MTQ">Martinique</option>
                      <option value="MRT">Mauritania</option>
                      <option value="MUS">Mauritius</option>
                      <option value="MYT">Mayotte</option>
                      <option value="MEX">Mexico</option>
                      <option value="MDA">Moldova, Republic of</option>
                      <option value="MCO">Monaco</option>
                      <option value="MNG">Mongolia</option>
                      <option value="MNE">Montenegro</option>
                      <option value="MSR">Montserrat</option>
                      <option value="MAR">Morocco</option>
                      <option value="MOZ">Mozambique</option>
                      <option value="MMR">Myanmar</option>
                      <option value="NAM">Namibia</option>
                      <option value="NRU">Nauru</option>
                      <option value="NPL">Nepal</option>
                      <option value="NLD">Netherlands</option>
                      <option value="NCL">New Caledonia</option>
                      <option value="NZL">New Zealand</option>
                      <option value="NIC">Nicaragua</option>
                      <option value="NER">Niger</option>
                      <option value="NGA">Nigeria</option>
                      <option value="NIU">Niue</option>
                      <option value="NFK">Norfolk Island</option>
                      <option value="NOR">Norway</option>
                      <option value="OMN">Oman</option>
                      <option value="PAK">Pakistan</option>
                      <option value="PSE">Palestinian Territory, Occupied</option>
                      <option value="PAN">Panama</option>
                      <option value="PNG">Papua New Guinea</option>
                      <option value="PRY">Paraguay</option>
                      <option value="PER">Peru</option>
                      <option value="PHL">Philippines</option>
                      <option value="PCN">Pitcairn</option>
                      <option value="POL">Poland</option>
                      <option value="PRT">Portugal</option>
                      <option value="PRI">Puerto Rico</option>
                      <option value="QAT">Qatar</option>
                      <option value="REU">Reunion</option>
                      <option value="ROU">Romania</option>
                      <option value="RUS">Russian Federation</option>
                      <option value="RWA">Rwanda</option>
                      <option value="BLM">Saint Barthelemy</option>
                      <option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value="KNA">Saint Kitts and Nevis</option>
                      <option value="LCA">Saint Lucia</option>
                      <option value="MAF">Saint Martin (French part)</option>
                      <option value="SPM">Saint Pierre and Miquelon</option>
                      <option value="VCT">Saint Vincent and the Grenadines</option>
                      <option value="WSM">Samoa</option>
                      <option value="SMR">San Marino</option>
                      <option value="STP">Sao Tome and Principe</option>
                      <option value="SAU">Saudi Arabia</option>
                      <option value="SCT">Scotland</option>
                      <option value="SEN">Senegal</option>
                      <option value="SRB">Serbia</option>
                      <option value="SYC">Seychelles</option>
                      <option value="SLE">Sierra Leone</option>
                      <option value="SGP">Singapore</option>
                      <option value="SXM">Sint Maarten (Dutch part)</option>
                      <option value="SVK">Slovakia</option>
                      <option value="SVN">Slovenia</option>
                      <option value="SLB">Solomon Islands</option>
                      <option value="SOM">Somalia</option>
                      <option value="ZAF">South Africa</option>
                      <option value="SGS">South Georgia and the South Sandwich Islands</option>
                      <option value="SSD">South Sudan</option>
                      <option value="ESP">Spain</option>
                      <option value="LKA">Sri Lanka</option>
                      <option value="SDN">Sudan</option>
                      <option value="SUR">Suriname</option>
                      <option value="SJM">Svalbard and Jan Mayen</option>
                      <option value="SWE">Sweden</option>
                      <option value="CHE">Switzerland</option>
                      <option value="SYR">Syrian Arab Republic</option>
                      <option value="TWN">Taiwan, Province of China</option>
                      <option value="TJK">Tajikistan</option>
                      <option value="TZA">Tanzania, United Republic of</option>
                      <option value="THA">Thailand</option>
                      <option value="TLS">Timor-Leste</option>
                      <option value="TGO">Togo</option>
                      <option value="TKL">Tokelau</option>
                      <option value="TON">Tonga</option>
                      <option value="TTO">Trinidad and Tobago</option>
                      <option value="TUN">Tunisia</option>
                      <option value="TUR">Turkey</option>
                      <option value="TKM">Turkmenistan</option>
                      <option value="TCA">Turks and Caicos Islands</option>
                      <option value="TUV">Tuvalu</option>
                      <option value="UGA">Uganda</option>
                      <option value="UKR">Ukraine</option>
                      <option value="ARE">United Arab Emirates</option>
                      <option value="GBR">United Kingdom</option>
                      <option value="USA">United States</option>
                      <option value="URY">Uruguay</option>
                      <option value="UZB">Uzbekistan</option>
                      <option value="VUT">Vanuatu</option>
                      <option value="VEN">Venezuela, Bolivarian Republic of</option>
                      <option value="VNM">Viet Nam</option>
                      <option value="VGB">Virgin Islands, British</option>
                      <option value="WLS">Wales</option>
                      <option value="WLF">Wallis and Futuna</option>
                      <option value="ESH">Western Sahara</option>
                      <option value="YEM">Yemen</option>
                      <option value="ZMB">Zambia</option>
                      <option value="ZWE">Zimbabwe</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="city">
                      <span className="text-red-500">*</span>City
                    </label>
                    <input id="00N80000004S5hx" maxLength={50} name="00N80000004S5hx" size={20} required />
                  </div>

                  <div className="flex flex-col gap-1">
                    <div>
                      {isStateRequired && <span className="text-red-500 experiencedStateLabel">*</span>}
                      State
                    </div>
                    <select
                      id="00N80000004xYsV"
                      name="00N80000004xYsV"
                      title="Address State"
                      className="experiencedState"
                      required={isStateRequired}
                      disabled={!isStateRequired}>
                      <option value="">--None--</option>
                      <option value="ALASKA">ALASKA</option>
                      <option value="ALBERTA">ALBERTA</option>
                      <option value="ARIZONA">ARIZONA</option>
                      <option value="ARKANSAS">ARKANSAS</option>
                      <option value="BRITISH COLUMBIA">BRITISH COLUMBIA</option>
                      <option value="CALIFORNIA">CALIFORNIA</option>
                      <option value="COLORADO">COLORADO</option>
                      <option value="CONNECTICUT">CONNECTICUT</option>
                      <option value="DELAWARE">DELAWARE</option>
                      <option value="DISTRICT OF COLUMBIA">DISTRICT OF COLUMBIA</option>
                      <option value="FLORIDA">FLORIDA</option>
                      <option value="GEORGIA">GEORGIA</option>
                      <option value="HAWAII">HAWAII</option>
                      <option value="IDAHO">IDAHO</option>
                      <option value="ILLINOIS">ILLINOIS</option>
                      <option value="INDIANA">INDIANA</option>
                      <option value="IOWA">IOWA</option>
                      <option value="KANSAS">KANSAS</option>
                      <option value="KENTUCKY">KENTUCKY</option>
                      <option value="LOUISIANA">LOUISIANA</option>
                      <option value="MAINE">MAINE</option>
                      <option value="MANITOBA">MANITOBA</option>
                      <option value="MARYLAND">MARYLAND</option>
                      <option value="MASSACHUSETTS">MASSACHUSETTS</option>
                      <option value="MICHIGAN">MICHIGAN</option>
                      <option value="MINNESOTA">MINNESOTA</option>
                      <option value="MISSISSIPPI">MISSISSIPPI</option>
                      <option value="MISSOURI">MISSOURI</option>
                      <option value="MONTANA">MONTANA</option>
                      <option value="NEBRASKA">NEBRASKA</option>
                      <option value="NEVADA">NEVADA</option>
                      <option value="NEW BRUNSWICK">NEW BRUNSWICK</option>
                      <option value="NEW HAMPSHIRE">NEW HAMPSHIRE</option>
                      <option value="NEW JERSEY">NEW JERSEY</option>
                      <option value="NEW MEXICO">NEW MEXICO</option>
                      <option value="NEW YORK">NEW YORK</option>
                      <option value="NEWFOUNDLAND and LABRADOR">NEWFOUNDLAND and LABRADOR</option>
                      <option value="NORTH CAROLINA">NORTH CAROLINA</option>
                      <option value="NORTH DAKOTA">NORTH DAKOTA</option>
                      <option value="NORTHWEST TERRITORIES">NORTHWEST TERRITORIES</option>
                      <option value="NOVA SCOTIA">NOVA SCOTIA</option>
                      <option value="OHIO">OHIO</option>
                      <option value="OKLAHOMA">OKLAHOMA</option>
                      <option value="ONTARIO">ONTARIO</option>
                      <option value="OREGON">OREGON</option>
                      <option value="PALAU">PALAU</option>
                      <option value="PENNSYLVANIA">PENNSYLVANIA</option>
                      <option value="PRINCE EDWARD ISLAND">PRINCE EDWARD ISLAND</option>
                      <option value="PUERTO RICO">PUERTO RICO</option>
                      <option value="QUEBEC">QUEBEC</option>
                      <option value="RHODE ISLAND">RHODE ISLAND</option>
                      <option value="SASKATCHEWAN">SASKATCHEWAN</option>
                      <option value="SOUTH CAROLINA">SOUTH CAROLINA</option>
                      <option value="SOUTH DAKOTA">SOUTH DAKOTA</option>
                      <option value="TENNESSEE">TENNESSEE</option>
                      <option value="TEXAS">TEXAS</option>
                      <option value="UTAH">UTAH</option>
                      <option value="VERMONT">VERMONT</option>
                      <option value="VIRGINIA">VIRGINIA</option>
                      <option value="VIRGIN ISLANDS">VIRGIN ISLANDS</option>
                      <option value="WASHINGTON">WASHINGTON</option>
                      <option value="WEST VIRGINIA">WEST VIRGINIA</option>
                      <option value="WISCONSIN">WISCONSIN</option>
                      <option value="WYOMING">WYOMING</option>
                      <option value="YUKON">YUKON</option>
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="zip">
                      <span className="text-red-500">*</span>Zip Code
                    </label>
                    <input
                      type="number"
                      id="00N80000004wiIG"
                      // onInput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxLength={5}
                      name="00N80000004wiIG"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Which best describes you?:
                    </div>
                    <select id="00N8b00000HSvk5" name="00N8b00000HSvk5" title="Which best describes you?" required>
                      <option value="">--None--</option>
                      <option value="I run my own business">I run my own business</option>
                      <option value="I am a travel agent who was/ is employed by a company">
                        I am a travel agent who was / is employed by a company
                      </option>
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="00N80000004STEu">
                      <span className="text-red-500">*</span>Annual Gross Sales
                    </label>
                    <input type="text" id="00N80000004STEu" maxLength={20} size={20} name="00N80000004STEu" required />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="00N8b00000HSuFb">
                      <span className="text-red-500">*</span>Years of Travel Experience?
                    </label>
                    <input type="number" id="00N8b00000HSuFb" maxLength={5} name="00N8b00000HSuFb" required />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>GDS Experience:
                    </div>
                    <select id="00N8b00000HSuFU" name="00N8b00000HSuFU" title="GDS Experience" required>
                      <option value="">--None--</option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Describe Your Business:
                    </div>
                    <Select
                      id="00N8b00000HSuFT"
                      isMulti
                      name="00N8b00000HSuFT"
                      placeholder=""
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          background: '#F5F5F5',
                          border: 'none',
                          height: '3rem'
                        }),
                        indicatorSeparator: (baseStyles) => ({
                          ...baseStyles,
                          background: '#F5F5F5'
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          width: '20px'
                        }),
                        dropdownIndicator: (baseStyles) => ({
                          ...baseStyles,
                          color: '#000',
                          padding: '0px 10px 10px 0px'
                        })
                      }}
                      required
                      options={optionsDescribeBusiness}
                    />
                  </div>

                  {/* <div className="flex flex-col gap-1">
                    <div>Current Memberships:</div>
                    <Select
                      id="00N8b00000HSuFS"
                      isMulti
                      name="00N8b00000HSuFS"
                      //title="Current Memberships"
                      placeholder=""
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          background: '#F5F5F5',
                          border: 'none'
                        }),
                        indicatorSeparator: (baseStyles) => ({
                          ...baseStyles,
                          background: '#F5F5F5'
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          width: '20px'
                        }),
                        dropdownIndicator: (baseStyles) => ({
                          ...baseStyles,
                          color: '#000',
                          padding: '0px'
                        })
                      }}
                      options={optionsMembership}
                    />
                  </div> */}

                  {/* <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Best Time To Reach You?:
                    </div>
                    <select id="00N8b00000HSuFQ" name="00N8b00000HSuFQ" title="Best Time To Reach You?" required>
                      <option value="">--None--</option>
                      <option value="Anytime">Anytime</option>
                      <option value="Morning">Morning</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Evening">Evening</option>
                    </select>
                  </div> */}

                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>What is your decision timeframe?:
                    </div>
                    <select id="00N8b00000HSuFZ" name="00N8b00000HSuFZ" title="What is your decision timeframe?" required>
                      <option value="">--None--</option>
                      <option value="Immediately">Immediately</option>
                      <option value="Under 30 days">Under 30 days</option>
                      <option value="Under 60 days">Under 60 days</option>
                      <option value="Just looking">Just looking</option>
                    </select>
                  </div>

                  {/* <div className="flex flex-col gap-1">
                    <label htmlFor="company">
                      <span className="text-red-500">*</span>Company
                    </label>
                    <input id="company" maxLength={40} name="company" size={20} type="text" required />
                  </div> */}

                  <div className="flex flex-col gap-1">
                    <div>
                      {' '}
                      <span className="text-red-500">*</span> Additional Comments:
                    </div>
                    <textarea
                      id="00N30000000j7EO"
                      name="00N30000000j7EO"
                      rows={3}
                      className="!h-32"
                      placeholder="Tell us a little about yourself and your business:"
                      required
                      wrap="soft"></textarea>
                  </div>

                  <div className="flex-col gap-1 hidden">
                    <label htmlFor="recordType">Lead Record Type</label>
                    <select
                      id="recordType"
                      name="recordType"
                      required
                      value="0128b000000paDY"
                      onChange={(e) => console.log(e.target.value)}>
                      <option value="">--None--</option>
                      <option value="012800000003bro">General Lead</option>
                      <option value="0128b000000paDY">Global Travel Collection</option>
                      <option value="012800000003brj">Referral Lead</option>
                      <option value="012340000007lN7">TLFG Leads</option>
                      <option value="0120y000000i8et">TLI Corporate Customer</option>
                      <option value="0120y000000i8cv">TLInternational</option>
                      <option value="0123400000046pF">TLOT Leads</option>
                    </select>
                  </div>

                  <div className="flex-col gap-1 hidden">
                    <label htmlFor="lead_source">Lead Source</label>
                    <select id="lead_source" name="lead_source" required value={leadSource} onChange={(e) => setLeadSource(e.target.value)}>
                      <option value="">--None--</option>
                      <option value="globaltravelcollection.com">globaltravelcollection.com</option>
                      <option value="intheknowexperiences.com">intheknowexperiences.com</option>
                      <option value="tzell.com">tzell.com</option>
                      <option value="protravelinc.com">protravelinc.com</option>
                      <option value="globaltravelcollection.co.uk" selected>
                        globaltravelcollection.co.uk
                      </option>
                    </select>
                  </div>
                  <div className="flex-col gap-1 hidden">
                    <div>What is your travel experience level?:</div>
                    <select
                      id="00N8b00000HSuFa"
                      name="00N8b00000HSuFa"
                      title="What is your travel experience level?"
                      required
                      onChange={(e: any) => {
                        console.log(e);
                      }}
                      value="Experienced in Travel Industry">
                      <option value="">--None--</option>
                      <option value="None">None</option>
                      <option value="New to Travel Industry">New to Travel Industry</option>
                      <option value="Experienced in Travel Industry">Experienced in Travel Industry</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <ReCAPTCHA sitekey="6LcYiA0lAAAAAPi397xVpvupIrsMGs6FCIL3L9Bc" onChange={onRecaptchChange} />
              </div>
              <div className="flex justify-center mt-10">
                <button
                  type="submit"
                  className="w-auto py-2 font-normal text-center text-white bg-black border border-black hover:font-semibold px-7 hover:cursor-pointer">
                  {' '}
                  Get in Touch
                </button>
              </div>
            </form>
          )}

          {formType === 'New' && (
            <form
              action={formSubmitUrl}
              method="POST"
              onSubmit={handleSubmitNew}
              id="newForm"
              className="justify-center mx-auto w-full sf-form  flex flex-col gap-12">
              <input type="hidden" name="oid" value="00D300000000U26" />
              <input type="hidden" name="retURL" value={thankYouNew} />

              <div className="flex flex-col md:flex-row gap-6 w-full items-start">
                <div className="flex flex-col gap-6 justify-center flex-1 w-full">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="first_name">
                      <span className="text-red-500">*</span>First Name
                    </label>
                    <input
                      id="first_name"
                      maxLength={40}
                      name="first_name"
                      size={20}
                      type="text"
                      pattern="^[A-Z][a-zA-Z\-\. ]+$"
                      title="Please enter only alphabets and starting with uppercase"
                      required
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="last_name">
                      <span className="text-red-500">*</span>Last Name
                    </label>
                    <input
                      id="last_name"
                      maxLength={80}
                      name="last_name"
                      size={20}
                      pattern="^[A-Z][a-zA-Z\-\. ]+$"
                      type="text"
                      title="Please enter only alphabets and starting with uppercase"
                      required
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="phone">
                      <span className="text-red-500">*</span>Phone Number
                    </label>
                    <input
                      id="phone"
                      type="tel"
                      pattern="^(?:\+?1\s*(?:[.-]\s*)?)?(?:\([2-9]\d{2}\)|[2-9]\d{2})\s*(?:[.-]\s*)?\d{3}\s*(?:[.-]\s*)?\d{4}$"
                      // onInput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      title="Please enter proper phone number"
                      maxLength={30}
                      name="phone"
                      size={20}
                      required
                    />
                    <span>'+' not needed</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="email">
                      <span className="text-red-500">*</span>Email
                    </label>
                    <input id="email" maxLength={80} name="email" size={20} type="email" required />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Country:
                    </div>
                    <select
                      id="Country__c"
                      name="Country__c"
                      title="Country"
                      className="newCountry"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required>
                      <option value="">--None--</option>

                      <option value="AFG">Afghanistan</option>
                      <option value="ALA">Aland Islands</option>
                      <option value="DZA">Algeria</option>
                      <option value="AND">Andorra</option>
                      <option value="AGO">Angola</option>
                      <option value="AIA">Anguilla</option>
                      <option value="ATA">Antarctica</option>
                      <option value="ATG">Antigua and Barbuda</option>
                      <option value="ARG">Argentina</option>
                      <option value="ARM">Armenia</option>
                      <option value="ABW">Aruba</option>
                      <option value="AUS">Australia</option>
                      <option value="AUT">Austria</option>
                      <option value="AZE">Azerbaijan</option>
                      <option value="BHS">Bahamas</option>
                      <option value="BHR">Bahrain</option>
                      <option value="Bali">Bali</option>
                      <option value="BGD">Bangladesh</option>
                      <option value="BRB">Barbados</option>
                      <option value="BLR">Belarus</option>
                      <option value="BEL">Belgium</option>
                      <option value="BLZ">Belize</option>
                      <option value="BEN">Benin</option>
                      <option value="BMU">Bermuda</option>
                      <option value="BTN">Bhutan</option>
                      <option value="BOL">Bolivia</option>
                      <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                      <option value="BIH">Bosnia and Herzegovina</option>
                      <option value="BWA">Botswana</option>
                      <option value="BVT">Bouvet Island</option>
                      <option value="BRA">Brazil</option>
                      <option value="IOT">British Indian Ocean Territory</option>
                      <option value="BRN">Brunei Darussalam</option>
                      <option value="BGR">Bulgaria</option>
                      <option value="BFA">Burkina Faso</option>
                      <option value="BDI">Burundi</option>
                      <option value="KHM">Cambodia</option>
                      <option value="CMR">Cameroon</option>
                      <option value="CAN">Canada</option>
                      <option value="CPV">Cape Verde</option>
                      <option value="CYM">Cayman Islands</option>
                      <option value="CAF">Central African Republic</option>
                      <option value="TCD">Chad</option>
                      <option value="CHL">Chile</option>
                      <option value="CHN">China</option>
                      <option value="CXR">Christmas Island</option>
                      <option value="CCK">Cocos (Keeling) Islands</option>
                      <option value="COL">Colombia</option>
                      <option value="COM">Comoros</option>
                      <option value="COD">Congo, the Democratic Republic of the</option>
                      <option value="COK">Cook Islands</option>
                      <option value="CRI">Costa Rica</option>
                      <option value="HRV">Croatia</option>
                      <option value="CUB">Cuba</option>
                      <option value="CUW">Curaçao</option>
                      <option value="CYP">Cyprus</option>
                      <option value="CZE">Czech Republic</option>
                      <option value="DNK">Denmark</option>
                      <option value="DJI">Djibouti</option>
                      <option value="DMA">Dominica</option>
                      <option value="DOM">Dominican Republic</option>
                      <option value="ECU">Ecuador</option>
                      <option value="EGY">Egypt</option>
                      <option value="SLV">El Salvador</option>
                      <option value="ENG">England</option>
                      <option value="GNQ">Equatorial Guinea</option>
                      <option value="ERI">Eritrea</option>
                      <option value="EST">Estonia</option>
                      <option value="ETH">Ethiopia</option>
                      <option value="FLK">Falkland Islands (Malvinas)</option>
                      <option value="FRO">Faroe Islands</option>
                      <option value="FJI">Fiji</option>
                      <option value="FIN">Finland</option>
                      <option value="FRA">France</option>
                      <option value="GUF">French Guiana</option>
                      <option value="PYF">French Polynesia</option>
                      <option value="ATF">French Southern Territories</option>
                      <option value="GAB">Gabon</option>
                      <option value="GMB">Gambia</option>
                      <option value="GEO">Georgia</option>
                      <option value="DEU">Germany</option>
                      <option value="GHA">Ghana</option>
                      <option value="GIB">Gibraltar</option>
                      <option value="GRC">Greece</option>
                      <option value="GRL">Greenland</option>
                      <option value="GRD">Grenada</option>
                      <option value="GLP">Guadeloupe</option>
                      <option value="GTM">Guatemala</option>
                      <option value="GGY">Guernsey</option>
                      <option value="GIN">Guinea</option>
                      <option value="GNB">Guinea-Bissau</option>
                      <option value="GUY">Guyana</option>
                      <option value="HTI">Haiti</option>
                      <option value="HMD">Heard Island and McDonald Islands</option>
                      <option value="VAT">Holy See (Vatican City State)</option>
                      <option value="HND">Honduras</option>
                      <option value="HKG">Hong Kong</option>
                      <option value="HUN">Hungary</option>
                      <option value="ISL">Iceland</option>
                      <option value="IND">India</option>
                      <option value="IDN">Indonesia</option>
                      <option value="IRN">Iran, Islamic Republic of</option>
                      <option value="IRQ">Iraq</option>
                      <option value="IRL">Ireland</option>
                      <option value="IMN">Isle of Man</option>
                      <option value="ISR">Israel</option>
                      <option value="ITA">Italy</option>
                      <option value="JAM">Jamaica</option>
                      <option value="JPN">Japan</option>
                      <option value="JEY">Jersey</option>
                      <option value="JOR">Jordan</option>
                      <option value="KAZ">Kazakhstan</option>
                      <option value="KEN">Kenya</option>
                      <option value="KIR">Kiribati</option>
                      <option value="PRK">Korea, Democratic People’s Republic of</option>
                      <option value="KOR">Korea, Republic of</option>
                      <option value="KWT">Kuwait</option>
                      <option value="KGZ">Kyrgyzstan</option>
                      <option value="LAO">Lao People’s Democratic Republic</option>
                      <option value="LVA">Latvia</option>
                      <option value="LBN">Lebanon</option>
                      <option value="LSO">Lesotho</option>
                      <option value="LBR">Liberia</option>
                      <option value="LBY">Libya</option>
                      <option value="LIE">Liechtenstein</option>
                      <option value="LTU">Lithuania</option>
                      <option value="LUX">Luxembourg</option>
                      <option value="MAC">Macao</option>
                      <option value="MKD">Macedonia, the former Yugoslav Republic of</option>
                      <option value="MDG">Madagascar</option>
                      <option value="MWI">Malawi</option>
                      <option value="MYS">Malaysia</option>
                      <option value="MDV">Maldives</option>
                      <option value="MLI">Mali</option>
                      <option value="MLT">Malta</option>
                      <option value="MTQ">Martinique</option>
                      <option value="MRT">Mauritania</option>
                      <option value="MUS">Mauritius</option>
                      <option value="MYT">Mayotte</option>
                      <option value="MEX">Mexico</option>
                      <option value="MDA">Moldova, Republic of</option>
                      <option value="MCO">Monaco</option>
                      <option value="MNG">Mongolia</option>
                      <option value="MNE">Montenegro</option>
                      <option value="MSR">Montserrat</option>
                      <option value="MAR">Morocco</option>
                      <option value="MOZ">Mozambique</option>
                      <option value="MMR">Myanmar</option>
                      <option value="NAM">Namibia</option>
                      <option value="NRU">Nauru</option>
                      <option value="NPL">Nepal</option>
                      <option value="NLD">Netherlands</option>
                      <option value="NCL">New Caledonia</option>
                      <option value="NZL">New Zealand</option>
                      <option value="NIC">Nicaragua</option>
                      <option value="NER">Niger</option>
                      <option value="NGA">Nigeria</option>
                      <option value="NIU">Niue</option>
                      <option value="NFK">Norfolk Island</option>
                      <option value="NOR">Norway</option>
                      <option value="OMN">Oman</option>
                      <option value="PAK">Pakistan</option>
                      <option value="PSE">Palestinian Territory, Occupied</option>
                      <option value="PAN">Panama</option>
                      <option value="PNG">Papua New Guinea</option>
                      <option value="PRY">Paraguay</option>
                      <option value="PER">Peru</option>
                      <option value="PHL">Philippines</option>
                      <option value="PCN">Pitcairn</option>
                      <option value="POL">Poland</option>
                      <option value="PRT">Portugal</option>
                      <option value="PRI">Puerto Rico</option>
                      <option value="QAT">Qatar</option>
                      <option value="REU">Reunion</option>
                      <option value="ROU">Romania</option>
                      <option value="RUS">Russian Federation</option>
                      <option value="RWA">Rwanda</option>
                      <option value="BLM">Saint Barthelemy</option>
                      <option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value="KNA">Saint Kitts and Nevis</option>
                      <option value="LCA">Saint Lucia</option>
                      <option value="MAF">Saint Martin (French part)</option>
                      <option value="SPM">Saint Pierre and Miquelon</option>
                      <option value="VCT">Saint Vincent and the Grenadines</option>
                      <option value="WSM">Samoa</option>
                      <option value="SMR">San Marino</option>
                      <option value="STP">Sao Tome and Principe</option>
                      <option value="SAU">Saudi Arabia</option>
                      <option value="SCT">Scotland</option>
                      <option value="SEN">Senegal</option>
                      <option value="SRB">Serbia</option>
                      <option value="SYC">Seychelles</option>
                      <option value="SLE">Sierra Leone</option>
                      <option value="SGP">Singapore</option>
                      <option value="SXM">Sint Maarten (Dutch part)</option>
                      <option value="SVK">Slovakia</option>
                      <option value="SVN">Slovenia</option>
                      <option value="SLB">Solomon Islands</option>
                      <option value="SOM">Somalia</option>
                      <option value="ZAF">South Africa</option>
                      <option value="SGS">South Georgia and the South Sandwich Islands</option>
                      <option value="SSD">South Sudan</option>
                      <option value="ESP">Spain</option>
                      <option value="LKA">Sri Lanka</option>
                      <option value="SDN">Sudan</option>
                      <option value="SUR">Suriname</option>
                      <option value="SJM">Svalbard and Jan Mayen</option>
                      <option value="SWE">Sweden</option>
                      <option value="CHE">Switzerland</option>
                      <option value="SYR">Syrian Arab Republic</option>
                      <option value="TWN">Taiwan, Province of China</option>
                      <option value="TJK">Tajikistan</option>
                      <option value="TZA">Tanzania, United Republic of</option>
                      <option value="THA">Thailand</option>
                      <option value="TLS">Timor-Leste</option>
                      <option value="TGO">Togo</option>
                      <option value="TKL">Tokelau</option>
                      <option value="TON">Tonga</option>
                      <option value="TTO">Trinidad and Tobago</option>
                      <option value="TUN">Tunisia</option>
                      <option value="TUR">Turkey</option>
                      <option value="TKM">Turkmenistan</option>
                      <option value="TCA">Turks and Caicos Islands</option>
                      <option value="TUV">Tuvalu</option>
                      <option value="UGA">Uganda</option>
                      <option value="UKR">Ukraine</option>
                      <option value="ARE">United Arab Emirates</option>
                      <option value="GBR">United Kingdom</option>
                      <option value="USA">United States</option>
                      <option value="URY">Uruguay</option>
                      <option value="UZB">Uzbekistan</option>
                      <option value="VUT">Vanuatu</option>
                      <option value="VEN">Venezuela, Bolivarian Republic of</option>
                      <option value="VNM">Viet Nam</option>
                      <option value="VGB">Virgin Islands, British</option>
                      <option value="WLS">Wales</option>
                      <option value="WLF">Wallis and Futuna</option>
                      <option value="ESH">Western Sahara</option>
                      <option value="YEM">Yemen</option>
                      <option value="ZMB">Zambia</option>
                      <option value="ZWE">Zimbabwe</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="city">
                      <span className="text-red-500">*</span>City
                    </label>
                    <input id="00N80000004S5hx" maxLength={50} name="00N80000004S5hx" size={20} required />
                  </div>
                </div>
                <div className="flex flex-col gap-6 justify-center flex-1 w-full">
                  <div className="flex flex-col gap-1">
                    <div>
                      {isStateRequired && <span className="text-red-500 newStateLabel">*</span>}
                      State:
                    </div>
                    <select
                      id="00N80000004xYsV"
                      name="00N80000004xYsV"
                      title="Address State"
                      className="newState"
                      required={isStateRequired}
                      disabled={!isStateRequired}>
                      <option value="">--None--</option>
                      <option value="ALABAMA">ALABAMA</option>
                      <option value="ALASKA">ALASKA</option>
                      <option value="ALBERTA">ALBERTA</option>
                      <option value="ARIZONA">ARIZONA</option>
                      <option value="ARKANSAS">ARKANSAS</option>
                      <option value="BRITISH COLUMBIA">BRITISH COLUMBIA</option>
                      <option value="CALIFORNIA">CALIFORNIA</option>
                      <option value="COLORADO">COLORADO</option>
                      <option value="CONNECTICUT">CONNECTICUT</option>
                      <option value="DELAWARE">DELAWARE</option>
                      <option value="DISTRICT OF COLUMBIA">DISTRICT OF COLUMBIA</option>
                      <option value="FLORIDA">FLORIDA</option>
                      <option value="GEORGIA">GEORGIA</option>
                      <option value="HAWAII">HAWAII</option>
                      <option value="IDAHO">IDAHO</option>
                      <option value="ILLINOIS">ILLINOIS</option>
                      <option value="INDIANA">INDIANA</option>
                      <option value="IOWA">IOWA</option>
                      <option value="KANSAS">KANSAS</option>
                      <option value="KENTUCKY">KENTUCKY</option>
                      <option value="LOUISIANA">LOUISIANA</option>
                      <option value="MAINE">MAINE</option>
                      <option value="MANITOBA">MANITOBA</option>
                      <option value="MARYLAND">MARYLAND</option>
                      <option value="MASSACHUSETTS">MASSACHUSETTS</option>
                      <option value="MICHIGAN">MICHIGAN</option>
                      <option value="MINNESOTA">MINNESOTA</option>
                      <option value="MISSISSIPPI">MISSISSIPPI</option>
                      <option value="MISSOURI">MISSOURI</option>
                      <option value="MONTANA">MONTANA</option>
                      <option value="NEBRASKA">NEBRASKA</option>
                      <option value="NEVADA">NEVADA</option>
                      <option value="NEW BRUNSWICK">NEW BRUNSWICK</option>
                      <option value="NEW HAMPSHIRE">NEW HAMPSHIRE</option>
                      <option value="NEW JERSEY">NEW JERSEY</option>
                      <option value="NEW MEXICO">NEW MEXICO</option>
                      <option value="NEW YORK">NEW YORK</option>
                      <option value="NEWFOUNDLAND and LABRADOR">NEWFOUNDLAND and LABRADOR</option>
                      <option value="NORTH CAROLINA">NORTH CAROLINA</option>
                      <option value="NORTH DAKOTA">NORTH DAKOTA</option>
                      <option value="NORTHWEST TERRITORIES">NORTHWEST TERRITORIES</option>
                      <option value="NOVA SCOTIA">NOVA SCOTIA</option>
                      <option value="OHIO">OHIO</option>
                      <option value="OKLAHOMA">OKLAHOMA</option>
                      <option value="ONTARIO">ONTARIO</option>
                      <option value="OREGON">OREGON</option>
                      <option value="PALAU">PALAU</option>
                      <option value="PENNSYLVANIA">PENNSYLVANIA</option>
                      <option value="PRINCE EDWARD ISLAND">PRINCE EDWARD ISLAND</option>
                      <option value="PUERTO RICO">PUERTO RICO</option>
                      <option value="QUEBEC">QUEBEC</option>
                      <option value="RHODE ISLAND">RHODE ISLAND</option>
                      <option value="SASKATCHEWAN">SASKATCHEWAN</option>
                      <option value="SOUTH CAROLINA">SOUTH CAROLINA</option>
                      <option value="SOUTH DAKOTA">SOUTH DAKOTA</option>
                      <option value="TENNESSEE">TENNESSEE</option>
                      <option value="TEXAS">TEXAS</option>
                      <option value="UTAH">UTAH</option>
                      <option value="VERMONT">VERMONT</option>
                      <option value="VIRGINIA">VIRGINIA</option>
                      <option value="VIRGIN ISLANDS">VIRGIN ISLANDS</option>
                      <option value="WASHINGTON">WASHINGTON</option>
                      <option value="WEST VIRGINIA">WEST VIRGINIA</option>
                      <option value="WISCONSIN">WISCONSIN</option>
                      <option value="WYOMING">WYOMING</option>
                      <option value="YUKON">YUKON</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="zip">
                      <span className="text-red-500">*</span>Zip Code
                    </label>
                    <input
                      type="number"
                      id="00N80000004wiIG"
                      //onInput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxLength={5}
                      name="00N80000004wiIG"
                      required
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="00N8b00000Ju2Mi">
                      {' '}
                      <span className="text-red-500">*</span>What is your Current Occupation?
                    </label>
                    <input id="00N8b00000Ju2Mi" maxLength={255} name="00N8b00000Ju2Mi" size={20} type="text" required />
                  </div>
                  {/* <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Best Time To Reach You?:
                    </div>
                    <select id="00N8b00000HSuFQ" name="00N8b00000HSuFQ" title="Best Time To Reach You?" required>
                      <option value="">--None--</option>
                      <option value="Anytime">Anytime</option>
                      <option value="Morning">Morning</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Evening">Evening</option>
                    </select>
                  </div> */}
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>What is Your Decision timeframe?:
                    </div>
                    <select id="00N8b00000HSuFZ" name="00N8b00000HSuFZ" title="What is your decision timeframe?" required>
                      <option value="">--None--</option>

                      <option value="Immediately">Immediately</option>
                      <option value="Under 30 days">Under 30 days</option>
                      <option value="Under 60 days">Under 60 days</option>
                      <option value="Just looking">Just looking</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      {' '}
                      <span className="text-red-500">*</span>Additional Comments:
                    </div>
                    <textarea
                      id="00N30000000j7EO"
                      name="00N30000000j7EO"
                      className="!h-32"
                      rows={3}
                      placeholder="Tell us a little about yourself and your desire to become an independent travel advisor."
                      required
                      wrap="soft"></textarea>
                  </div>
                  <div className=" flex-col gap-1 hidden">
                    <label htmlFor="recordType">Lead Record Type</label>
                    <select
                      id="recordType"
                      name="recordType"
                      required
                      value="0128b000000paDY"
                      onChange={(e) => console.log(e.target.value)}>
                      <option value="">--None--</option>
                      <option value="012800000003bro">General Lead</option>
                      <option value="0128b000000paDY">Global Travel Collection</option>
                      <option value="012800000003brj">Referral Lead</option>
                      <option value="012340000007lN7">TLFG Leads</option>
                      <option value="0120y000000i8et">TLI Corporate Customer</option>
                      <option value="0120y000000i8cv">TLInternational</option>
                      <option value="0123400000046pF">TLOT Leads</option>
                    </select>
                  </div>
                  <div className=" flex-col gap-1 hidden">
                    <label htmlFor="lead_source">Lead Source</label>
                    <select id="lead_source" name="lead_source" required value={leadSource} onChange={(e) => setLeadSource(e.target.value)}>
                      <option value="">--None--</option>
                      <option value="globaltravelcollection.com">globaltravelcollection.com</option>
                      <option value="intheknowexperiences.com">intheknowexperiences.com</option>
                      <option value="tzell.com">tzell.com</option>
                      <option value="protravelinc.com">protravelinc.com</option>
                      <option value="globaltravelcollection.co.uk" selected>
                        globaltravelcollection.co.uk
                      </option>
                    </select>
                  </div>
                  <div className=" flex-col gap-1 hidden">
                    <div>What is your travel experience level?:</div>
                    <select
                      id="00N8b00000HSuFa"
                      name="00N8b00000HSuFa"
                      title="What is your travel experience level?"
                      value="New to Travel Industry"
                      onChange={(e: any) => {
                        console.log(e);
                      }}
                      required>
                      <option value="">--None--</option>
                      <option value="None">None</option>
                      <option value="New to Travel Industry">New to Travel Industry</option>
                      <option value="Experienced in Travel Industry">Experienced in Travel Industry</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <ReCAPTCHA sitekey="6LcYiA0lAAAAAPi397xVpvupIrsMGs6FCIL3L9Bc" onChange={onRecaptchChange} />
              </div>
              <div className="flex justify-center mt-10">
                <button
                  type="submit"
                  className="w-auto py-2 font-normal text-center text-white bg-black border border-black hover:font-semibold px-7 hover:cursor-pointer">
                  {' '}
                  Get in Touch
                </button>
              </div>
            </form>
          )}

          {formType !== undefined && (
            <section className="mx-2 md:mx-10" id="privacy">
              *Global Travel Collection takes your security very seriously. Please review our{' '}
              <a href="https://internova.com/privacy/" className="font-bold cursor-pointer">
                Privacy Policy
              </a>{' '}
              to learn more about how we protect you and your data. By submitting this information, you are agreeing to receive periodic
              e-mails from Global Travel Collection related to our luxury travel services from which you can unsubscribe at any time.
            </section>
          )}
        </section>
      </article>
    </>
  );
}

export default JoinUs;
