/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { AgentDetailViewModel, MediaEntity } from '../../../helpers/types';
import Modal from 'react-modal';
import { modalCustomStyles, modalGalleryCustomStyles } from '../../../helpers/constants';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import AgentAlbum from './AgentAlbum';
import AgentStories from './AgentStories';

interface TravelExperienceProps {
  data: AgentDetailViewModel;
}

function AllTravelExperience({ data }: TravelExperienceProps) {
  // eslint-disable-next-line no-unused-vars

  return (
    <>
      <div className="flex flex-col gap-4 pb-4">
        <AgentStories data={data} title="All My Travel Experiences" />
        <AgentAlbum data={data} title="All My Travel Albums" />
      </div>
    </>
  );
}

export default AllTravelExperience;
