import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import StarRatings from 'react-star-ratings';
import APIService from '../../services/services';
import { AdvertismentViewModel, AgentSearchBuildUrlParameters, AgentSearchParameters, AgentViewModel } from '../../helpers/types';
import Destination from '../layout/sidebar/Destination';
import Interest from '../layout/sidebar/Interest';
import State from '../layout/sidebar/State';
import Supplier from '../layout/sidebar/Supplier';
import Language from '../layout/sidebar/Language';
import queryString from 'query-string';
import { agentSearchFilterToParam, agentSearchQueryToFilter, callMe, getStateName } from '../../helpers/functions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import DestinationExpert from '../../components/DestinationExpert';
import AgentSearchFooter from '../layout/AgentSearchFooter';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import EmailForm from '../layout/EmailForm';
import PhoneForm from '../layout/PhoneForm';
import AppointmentForm from '../layout/AppointmentForm';

import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { Hero } from '../../components/Hero';

interface Props {
  type?: string;
}

function Agents({ type }: Props) {
  const appState = useContext(AppContext);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [agents, setAgents] = useState<AgentViewModel[]>([]);
  const [ads, setAds] = useState<AdvertismentViewModel[]>([]);
  const [totalAgents, setTotalAgents] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [agentName, setAgentName] = useState<string>('');
  const [expertise, SetExpertise] = useState<string>('');

  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  //const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState<AgentSearchParameters | undefined>();

  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [selectedAgent, setSelectedAgent] = useState<AgentViewModel | null>(null);
  const [title, setTitle] = useState<string>('');

  const parsed = queryString.parse(window.location.search);

  const getAgents = () => {
    if (filters) {
      if (type !== undefined && type === 'ITKE') {
        filters.agencyId = 643392;
      }
      setShowFilter(false);
      setLoading(true);
      APIService()
        .gtcService.fetchAgents(filters)
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setAds(res.data.ads);
            if (agents.length === 0 || filters?.currentPage === 1) setAgents(res.data.agents);
            else {
              setAgents([...agents.concat(res.data.agents)]);
            }
            setTotalAgents(res.data.totalAgents);
          } else {
            setAgents([]);
            setAds([]);
            setTotalAgents(0);
          }
        });
    }
  };

  const getMoreLinks = () => (
    <>
      {(filters?.destination ||
        filters?.agentState ||
        filters?.agentMetro ||
        filters?.agentCity ||
        filters?.agentMetro ||
        filters?.interest ||
        filters?.agentSupplier) && (
        <div className="text-base h-screen">
          <p className="font-bold text-lg">
            Sorry, it doesn&apos;t look like we have any agents matching your exact search.
            <br />
            Try simplifying your search by selecting one of the options below:
          </p>
          {filters?.destination ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctDestination=${encodeURIComponent(filters?.destination)}`}>
                {`Travel agents who book travel to ${filters?.destination}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentState ? (
            <div>
              <a className="text-orange hover:text-black font-bold" href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}`}>
                {`Travel agents located in ${getStateName(filters?.agentState)}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentMetro ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}&slctMetro=${encodeURIComponent(filters?.agentMetro)}`}>
                {`Travel agents located in ${filters?.agentMetro} Metro`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentCity ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}${
                  filters?.agentMetro.length > 0 ? `&slctMetro=${encodeURIComponent(filters?.agentMetro)}` : ''
                }&slctCity=${encodeURIComponent(filters?.agentCity)}`}>
                {`Travel agents located in ${filters?.agentCity}, ${filters?.agentState}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.interest ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(filters?.interest)}`}>
                {`${filters?.interest} Travel agents `}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentSupplier ? (
            <div>
              <a
                className="text-orange hover:text-black font-bold"
                href={`/find-an-elite-travel-agent?slctSupplier=${encodeURIComponent(filters?.agentSupplier)}`}>
                {`Travel agents working with ${filters?.agentSupplier}`}
              </a>
            </div>
          ) : (
            ''
          )}
          <div>
            <a className="text-orange hover:text-black font-bold" href="/find-an-elite-travel-agent">
              Restart your search for the perfect travel agent.
            </a>
          </div>
        </div>
      )}
    </>
  );

  const getMoreMessage = () =>
    totalAgents === 0 && (
      <>
        {filters?.nearby === 'true' ? (
          <>
            {"- Sorry, it doesn't look like we have any agents near you. Please"}{' '}
            <span className="underline cursor-pointer font-bold" onClick={() => navigate(`/find-an-elite-travel-agent`)}>
              start a new search
            </span>{' '}
            {'to try to find the perfect agent for you'}
          </>
        ) : (
          <>
            {'- Please click'}{' '}
            <span className="underline cursor-pointer font-bold" onClick={() => navigate(`/find-an-elite-travel-agent?nearby=true`)}>
              here
            </span>{' '}
            {'for a list of advisors near you.'}
          </>
        )}
      </>
    );
  const constructTitle = () => {
    if (!filters) setTitle('Luxury Travel agents');
    else {
      var base = 'Luxury Travel agents';

      if (filters.agentState !== '' || filters.agentMetro !== '' || filters.agentCity !== '') {
        base = base.concat(' in');
      }

      var cityMetro = '';
      if (filters.agentMetro !== '' && filters.agentCity === '') {
        cityMetro = ` ${filters.agentMetro},`;
      }

      if (filters.agentCity !== '') {
        cityMetro = ` ${filters.agentCity},`;
      }
      base = base.concat(cityMetro);

      var state = '';
      if (filters.agentState !== '') {
        state = cityMetro === '' ? getStateName(filters.agentState) : filters.agentState;
        base = base.concat(' ' + state);
      }

      if (filters.agentName && filters.agentName !== '') {
        base = base.concat(` named ${filters.agentName}`);
      }
      if ((filters.interest && filters.interest !== '') || (filters.destination && filters.destination !== '')) {
        base = base.concat(` specializing in `);
      }
      if (filters.destination && filters.destination !== '') {
        base = base.concat(`${filters.destination}`);
      }
      if (filters.interest && filters.interest !== '') {
        base = base.concat(` ${filters.destination !== '' ? 'and' : ''} ${filters.interest}`);
      }
      if (filters.agentSupplier && filters.agentSupplier !== '') {
        base = base.concat(` who work with ${filters.agentSupplier}`);
      }
      if (filters.agentZip && filters.agentZip !== '') {
        base = base.concat(` located near ${filters.agentZip}`);
      }

      setTitle(base);
    }
  };

  const ad = (index: number) => {
    if (agents.length < 3 && ads.length > 0) {
      return index === agents.length ? (
        <a href={ads[0].adUrl + ads[0].adTracking} target="_blank" rel="noreferrer" className="cursor-pointer mx-4">
          <img className="mx-auto flex md:hidden" src={ads[0].mobileImageUrl} />
          <img className="mx-auto hidden md:flex" src={ads[0].imageUrl} />
        </a>
      ) : undefined;
    }

    const showAd = index % 3 === 0 || agents?.length < 3;
    if (showAd) {
      const key = index < 3 ? 0 : index / 3 - 1;
      return key < ads.length ? (
        <a href={ads[key].adUrl + ads[key].adTracking} target="_blank" rel="noreferrer" className="cursor-pointer mx-4">
          <img className="mx-auto flex md:hidden" src={ads[key].mobileImageUrl} />
          <img className="mx-auto hidden md:flex" src={ads[key].imageUrl} />
        </a>
      ) : undefined;
    }
    return undefined;
  };

  const onCall = (agent: AgentViewModel) => {
    setSelectedAgent(agent);
    setModalOpen('Phone');
    callMe(agent);
  };

  // useEffect(() => {
  //   setFilters(agentSearchQueryToFilter(parsed as unknown as AgentSearchBuildUrlParameters, currentPage));
  // }, [currentPage]);

  let location = useLocation();
  useEffect(() => {
    constructTitle();
    if (filters) {
      getAgents();
    }
  }, [filters]);

  useEffect(() => {
    setAgentName('');
    SetExpertise('');
    Modal.setAppElement('#root');
    const parsed = queryString.parse(searchParams.toString());

    setFilters(agentSearchQueryToFilter(parsed as unknown as AgentSearchBuildUrlParameters, filters?.currentPage || 1));
  }, [searchParams]);

  const renderFilter = () => (
    <section className="my-4 flex flex-col gap-4 mx-4">
      <div className="font-bold">WHERE WOULD YOU LIKE TO GO?</div>
      <div className="flex flex-col md:flex-row justify-evenly md:gap-10 gap-2 md:mx-4">
        <div className="flex-1">
          <div className="relative flex w-full flex-wrap items-stretch ">
            <input
              type="search"
              className="h-10 relative m-0 -mr-0.5 placeholder:text-gray-500  block w-[1px] min-w-0 flex-auto  bg-gray-100 focus:bg-white border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-700  dark:focus:border-primary"
              placeholder="Search by Agent Name"
              aria-label="Search"
              value={agentName}
              onKeyDown={(event) => {
                if (filters && event.key === 'Enter') {
                  setFilters({ ...filters, agentName: agentName, currentPage: 1 });
                  navigate(`/find-an-elite-travel-agent?txtAgentName=${agentName}`);
                }
              }}
              onChange={(e: any) => {
                setAgentName(e.target.value);
              }}
            />

            <button
              className="h-10 bg-black relative z-[2] flex items-center  px-4 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-gray-700 
            focus:bg-gray-800  active:bg-gray-800 active:shadow-lg  border border-solid border-black"
              type="button"
              id="button-addon1"
              onClick={() => {
                if (filters) {
                  setFilters({ ...filters, agentName: agentName, currentPage: 1 });
                  navigate(`/find-an-elite-travel-agent?txtAgentName=${agentName}`);
                }
                // navigate(0);
              }}>
              <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                <path
                  fillRule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex-1">
          <div className="relative  flex w-full flex-wrap items-stretch">
            <div className="flex-1">
              <DestinationExpert
                callback={(dest: string) => {
                  if (filters) {
                    setFilters({ ...filters, destination: dest, currentPage: 1 });
                    navigate(`/find-an-elite-travel-agent?slctDestination=${encodeURIComponent(dest)}`);
                  }
                }}
              />
            </div>
            <button
              className="h-10 bg-black relative z-[2] flex items-center  px-4 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-gray-700 
            focus:bg-gray-800  active:bg-gray-800 active:shadow-lg border border-solid border-black"
              type="button"
              id="button-addon1">
              <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                <path
                  fillRule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex-1">
          <div className="relative flex w-full flex-wrap items-stretch">
            <select
              className="h-10 relative m-0  block w-[1px] min-w-0 flex-auto  bg-gray-100 focus:bg-white border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6]
             text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600
             placeholder:text-gray-500
             dark:focus:border-primary"
              aria-label="Search Travel Interest Experts"
              onChange={(e: any) => {
                if (filters) {
                  setFilters({ ...filters, interest: encodeURIComponent(e.target.value), currentPage: 1 });
                  navigate(`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(e.target.value)}`);
                  SetExpertise(e.target.value);
                }
                // navigate(0);
              }}>
              <option value="">Travel Interest Experts</option>
              {appState.specialities.map((x) => (
                <option value={x.interest} key={x.interestId} selected={x.interest === expertise}>
                  {x.interest}
                </option>
              ))}
            </select>

            <button
              className="h-10 bg-black relative z-[2] flex items-center px-4 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-gray-700 
            focus:bg-gray-800  active:bg-gray-800 active:shadow-lg border border-solid border-black"
              type="button"
              id="button-addon1">
              <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                <path
                  fillRule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
  const renderBreadCrumb = () => {
    var base = '/find-an-elite-travel-agent?';
    var BcLevel = 0;
    var BcState = '';
    var BcMetro = '';
    var BcCity = '';
    var BcDest = '';
    var BcInt = '';
    var BcSupl = '';

    if (filters?.agentState) {
      BcLevel = 1;

      let url = queryString.stringify({ slctState: filters.agentState });
      BcState = ` <a href="${base + url}" className="capitalize" title="Agents who are located in ${getStateName(
        filters.agentState
      )}">${getStateName(filters.agentState)}</a> >`;
    }

    if (filters?.agentMetro) {
      BcLevel = 2;

      let url = queryString.stringify({
        slctState: filters.agentState,
        slctMetro: filters.agentMetro
      });
      BcMetro = ` <a href="${base + url}" className="capitalize" title="Agents in the ${filters.agentMetro}">${filters.agentMetro}</a> >`;
    }

    if (filters?.agentCity) {
      BcLevel = 3;

      let url = queryString.stringify({
        slctState: filters.agentState,
        slctMetro: filters.agentMetro,
        slctCity: filters.agentCity
      });
      BcCity = ` <a href="${base + url}" className="capitalize" title="Agents who are based out of ${filters.agentCity}">
      ${filters.agentCity}</a> >`;
    }
    if (filters?.destination) {
      BcLevel = 4;

      let url = queryString.stringify({
        slctState: filters.agentState,
        slctMetro: filters.agentMetro,
        slctCity: filters.agentCity,
        slctDestination: filters.destination
      });
      BcDest = ` <a href="${base + url}" className="capitalize" title="Agents who have expertise in ${filters.destination}">
      ${filters.destination}</a> >`;
    }

    if (filters?.interest) {
      BcLevel = 5;

      let url = queryString.stringify({
        slctState: filters.agentState,
        slctMetro: filters.agentMetro,
        slctCity: filters.agentCity,
        slctDestination: filters.destination,
        slctInterest: filters.interest
      });
      BcInt = ` <a href="${base + url}" className="capitalize" title="Agents who have specialize in ${filters.interest}">
      ${filters.interest}</a> >`;
    }
    if (filters?.agentSupplier) {
      BcLevel = 6;

      let url = queryString.stringify({
        slctState: filters.agentState,
        slctMetro: filters.agentMetro,
        slctCity: filters.agentCity,
        slctDestination: filters.destination,
        slctInterest: filters.interest,
        slctSupplier: filters.agentSupplier
      });
      BcSupl = ` <a href="${base + url}" className="capitalize" title="Agents who work with ${filters.agentSupplier}">
      ${filters.agentSupplier}</a> >`;
    }

    // last breadcrumb remove link
    switch (BcLevel) {
      case 1:
        BcState = `<div className="capitalize">${getStateName(filters?.agentState || '')} </div> >`;
        break;
      case 2:
        BcMetro = `<div className="capitalize">${filters?.agentMetro} </div> >`;
        break;
      case 3:
        BcCity = `<div className="capitalize">${filters?.agentCity} </div> >`;
        break;
      case 4:
        BcDest = `<div className="capitalize">${filters?.destination} </div> >`;
        break;
      case 5:
        BcInt = `<div className="capitalize">${filters?.interest} </div> >`;
        break;
      case 6:
        BcSupl = `<div className="capitalize">${filters?.agentSupplier} </div> >`;
        break;
    }

    let breadCrumbs = ''.concat(BcState, BcMetro, BcCity, BcDest, BcInt, BcSupl);
    if (breadCrumbs?.length > 1) breadCrumbs = breadCrumbs.substring(0, breadCrumbs.length - 1);
    else if (breadCrumbs === '' || breadCrumbs?.length == 0) breadCrumbs = 'All Agents';

    breadCrumbs = `<a href="/find-an-elite-travel-agent" className="capitalize">Home</a> > ` + breadCrumbs;

    return <div className="mx-2 flex flex-row gap-1 text-sm text-gtc2 mb-4" dangerouslySetInnerHTML={{ __html: breadCrumbs }}></div>;
  };
  const renderSideBar = () => (
    <div className="flex flex-col gap-2  border-gtc3 border-r">
      <Destination
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          if (val === 0) {
            var url = queryString.exclude(location.search, ['slctDestination', 'nearby']);

            if (url.includes('?')) {
              url = url.replace('?', '');
            }
            goTo(url);
            if (filters) setFilters({ ...filters, destination: '', currentPage: 1 });
          } else {
            var params = queryString.parse(location.search);

            params = { ...params, slctDestination: val };
            let url = queryString.stringify(params);
            goTo(url);

            if (filters) setFilters({ ...filters, destination: val, currentPage: 1 });
          }

          //navigate(0);
        }}></Destination>
      <Interest
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          var params = queryString.parse(location.search);

          params = { ...params, slctInterest: val };
          let url = queryString.stringify(params);
          goTo(url);

          if (filters) setFilters({ ...filters, interest: val, currentPage: 1 });
        }}></Interest>
      <State
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(type: any, val: any) => {
          if (type === 'state') {
            if (val === 0) {
              let url = queryString.exclude(location.search, ['slctState', 'slctMetro', 'slctCity']);

              if (url.includes('?')) {
                url = url.replace('?', '');
              }
              goTo(url);
              if (filters) setFilters({ ...filters, agentState: '', agentCity: '', agentMetro: '', currentPage: 1 });
            } else {
              let params = queryString.parse(location.search);

              params = {
                ...(delete params.slctCity && delete params.slctMetro && params),
                slctState: val
              };
              let url = queryString.stringify(params);
              goTo(url);
              if (filters) setFilters({ ...filters, agentState: val, currentPage: 1 });
            }
          } else if (type === 'metro') {
            if (val === 0) {
              let url = queryString.exclude(location.search, ['slctMetro', 'slctCity']);

              if (url.includes('?')) {
                url = url.replace('?', '');
              }
              goTo(url);
              if (filters) setFilters({ ...filters, agentCity: '', agentMetro: '', currentPage: 1 });
            } else {
              let params = queryString.parse(location.search);

              params = { ...(delete params.slctCity && params), slctMetro: val };
              console.log(params);
              let url = queryString.stringify(params);
              goTo(url);
              if (filters) setFilters({ ...filters, agentMetro: val, currentPage: 1 });
            }
          } else if (type === 'city') {
            if (val === 0) {
              let url = queryString.exclude(location.search, ['slctCity']);

              if (url.includes('?')) {
                url = url.replace('?', '');
              }
              goTo(url);
              if (filters) setFilters({ ...filters, agentCity: '', currentPage: 1 });
            } else {
              let params = queryString.parse(location.search);

              params = { ...params, slctCity: val };
              let url = queryString.stringify(params);
              goTo(url);
              if (filters) setFilters({ ...filters, agentCity: val, currentPage: 1 });
            }
          }
          //navigate(0);
        }}></State>

      <a href="/find-an-elite-travel-agent?slctState=UK">
        <div
          className="my-2 text-[1.1rem] font-semibold text-base
         text-black  py-3 px-1 md:px-0 md:py-0">
          UK Agents
        </div>
      </a>
      <Supplier
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          var params = queryString.parse(location.search);

          params = { ...params, slctSupplier: val };
          let url = queryString.stringify(params);
          navigate(`/find-an-elite-travel-agent?${url}`);
          if (filters) setFilters({ ...filters, agentSupplier: val, currentPage: 1 });

          //  navigate(0);
        }}></Supplier>
      <Language
        destination={filters?.destination}
        interest={filters?.interest}
        city={filters?.agentCity}
        state={filters?.agentState}
        language={filters?.agentLanguage}
        metroRegion={filters?.agentMetro}
        zip={filters?.agentZip}
        supplier={filters?.agentSupplier}
        callback={(val: any) => {
          var params = queryString.parse(location.search);

          params = { ...params, slctLanguage: val };
          let url = queryString.stringify(params);
          navigate(`/find-an-elite-travel-agent?${url}`);

          if (filters) setFilters({ ...filters, agentLanguage: val, currentPage: 1 });

          //navigate(0);
        }}></Language>
    </div>
  );

  const goTo = (url: string) => {
    var param = url.replace('?nearby=true&', '');
    param = param.replace('nearby=true&', '');
    param = param.replace('nearby=true', '');
    navigate(`/find-an-elite-travel-agent?${param.replace('?', '')}`);
  };

  const activeFilters = useMemo(() => {
    var selectedFilters: any[] = [];
    if (filters !== undefined) {
      const keys = Object.keys(filters);

      keys.forEach((key) => {
        if (filters[key as keyof AgentSearchParameters] !== '' && !['agentId', 'useZipBiasing', 'currentPage'].includes(key))
          selectedFilters.push({
            param: agentSearchFilterToParam(key),
            key: key.replace('A', '1'),
            value: filters[key as keyof AgentSearchParameters]
          });
      });
    }

    selectedFilters = selectedFilters.reverse();

    if (selectedFilters.length === 0)
      return (
        <div className="flex flex-row gap-2 items-center mb-6 mt-2 md:mt-0 ">
          <div className="justify-center md:justify-start flex-1">ACTIVE FILTERS | All agents</div>
        </div>
      );
    else {
      return (
        <div className="flex flex-col md:flex-row gap-2 items-center mb-6 mt-2 md:mt-0 ">
          <div> ACTIVE FILTERS | </div>
          {selectedFilters
            .filter((x) => x.key.toLowerCase() !== 'nearby' && x.key.toLowerCase() !== 'agencyid')
            .map((x) => {
              let filter = x.key;
              let value = x.value;

              if (filter.toLowerCase() === 'agentlanguage') {
                filter = 'Language';
              }
              if (filter.toLowerCase() === 'agentstate') {
                filter = 'Agent State';
                value = getStateName(value);
              }
              if (filter.toLowerCase() === 'agentmetro') {
                filter = 'Metro Region';
              }
              if (filter.toLowerCase() === 'agentcity') {
                filter = 'Agent City';
              }

              if (filter.toLowerCase() === 'agentsupplier') {
                filter = 'Supplier Partner';
              }

              return (
                <React.Fragment key={x.key}>
                  {filter === 'Metro Region' && selectedFilters.some((x) => x.key.toLowerCase() === 'agentcity') ? (
                    <></>
                  ) : (
                    <div
                      className="cursor-pointer bg-gtc6 px-3 py-1 flex flex-row gap-2 items-center max-w-full hover:bg-gtc3"
                      onClick={() => {
                        var url = queryString.exclude(
                          location.search,
                          x.param === 'slctState' ? [x.param, 'slctMetro', 'slctCity'] : [x.param]
                        );
                        goTo(url);
                        if (filters) {
                          x.param === 'slctState'
                            ? setFilters({
                                ...filters,
                                agentState: '',
                                agentMetro: '',
                                agentCity: '',
                                currentPage: 1
                              })
                            : setFilters({ ...filters, agentState: '', currentPage: 1 });
                        }

                        //navigate(0);
                      }}>
                      <div>
                        <span className="capitalize">{filter}</span>: {value}
                      </div>
                      <i role="presentation" className="fa-solid fa-times fa-sm mt-0.5 text-black font-bold"></i>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      );
    }
  }, [filters]);

  const constructMetaTitle = () => {
    if (Array.from(searchParams).length === 0) return 'Find a Trusted Luxury Travel Agent | Global Travel Collection';
    else {
      return `${filters?.destination}  Travel Agents ${filters?.interest ? `for ${filters?.interest}` : ''} 
      ${filters?.agentState ? `in` : ''}
      ${filters?.agentMetro && !filters?.agentCity ? `${filters?.agentMetro},` : ''}
      ${filters?.agentCity ? ` ${filters?.agentCity},` : ''}
      ${filters?.agentState ? `${getStateName(filters?.agentState)}` : ''} | Page ${filters?.currentPage || 1} | Global Travel Collection`;
    }
  };
  const constructMetaDesc = () => {
    if (Array.from(searchParams).length === 0)
      return 'These trusted agents can provide you with personalized recommendations, detailed knowledge of destinations and exclusive deals & amenities for your next trip.';
    else {
      return `There are ${totalAgents} Luxury Agents in ${filters?.agentState} who specialize in 
      ${filters?.destination} ${filters?.interest} who can provide recommendations, knowledge and exclusive deals.`;
    }
  };
  const constructAgentAlt = (agent: AgentViewModel) => {
    if (Array.from(searchParams).length === 0) return `${agent.fullName} Luxury Travel Agent ${agent.city},${agent.state}`;
    else {
      return `Luxury Travel ${filters?.destination} ${filters?.interest} Agent ${agent.city},${agent.state}`;
    }
  };

  return (
    <>
      <Helmet>
        <meta name="title" content={constructMetaTitle()} />
        <meta name="description" content={constructMetaDesc()} />
        <title>{constructMetaTitle()}</title>
        {type === 'ITKE' && <link rel="stylesheet" href="/styles/itke.css" />}
      </Helmet>
      {type !== 'ITKE' && <Hero title="FIND AN ADVISOR" img="/images/new-hero/findadvisor.webp" />}

      <div className="mx-auto relative 2xl:container ">
        {renderFilter()}
        {renderBreadCrumb()}
        <button
          className="py-2 font-normal text-center text-white bg-black border-white
          border-l-4 border-r-4 z-10 border-t-4
            md:hidden  my-4 sticky top-0
                hover:font-semibold px-7 hover:cursor-pointer uppercase w-full flex flex-row gap-2 justify-center items-center"
          onClick={() => setShowFilter(!showFilter)}>
          <i role="presentation" className="fa-solid fa-search"></i>
          <div> Filter Results</div>
        </button>

        {showFilter && <div className="absolute bg-white w-full z-10 left-0 right-0 px-4">{renderSideBar()}</div>}
        {!showFilter && (
          <section className="flex flex-row gap-4 mx-0">
            <div className="hidden md:flex w-1/4 pl-4">{renderSideBar()}</div>
            <div className="flex flex-col gap-3  text-center md:text-left" aria-label="Agent search" role="main">
              <h1 className="uppercase text-xl md:text-3xl">{title}</h1>
              <div>
                THERE ARE {totalAgents} AGENTS MATCHING THIS SEARCH {totalAgents === 0 && !loading && getMoreMessage()}
              </div>
              <div>
                These trusted agents are a part of Internova Travel Group, an International family of travel brands, and can provide you
                with personalized recommendations, detailed knowledge of destinations and exclusive deals & amenities for your next trip.
              </div>
              {activeFilters}
              <div className="flex flex-col gap-10 mb-4  text-sm md:text-base">
                {loading && (
                  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
                    <div className="text-center text-white text-xl font-semibold">Searching...</div>
                  </div>
                )}
                {totalAgents === 0 && !loading && filters?.nearby !== 'true' && getMoreLinks()}
                {agents.map((agent: AgentViewModel, index: number) => {
                  return (
                    <React.Fragment key={agent.id}>
                      <div className="mx-2 bg-gtc6 flex flex-col gap-6 md:gap-2 pb-2 shadow-md">
                        <div className="flex flex-col md:flex-row gap-2">
                          <div className="md:flex flex-row gap-2">
                            <div style={{ width: '40%' }} className="md:hidden float-left mr-2">
                              <a href={agent.agentDetailsUrl}>
                                <img
                                  src={agent.photoUrl}
                                  className="hover:opacity-40 ease-in-out duration-500 w-full h-auto"
                                  alt={constructAgentAlt(agent)}
                                />
                              </a>
                            </div>
                            <a href={agent.agentDetailsUrl} className="hidden md:block">
                              <img
                                src={agent.photoUrl}
                                className="w-40 hover:opacity-40 ease-in-out duration-500"
                                alt={constructAgentAlt(agent)}
                              />
                            </a>
                            <div className="flex md:hidden flex-col gap-1 text-left pr-3">
                              <a href={agent.agentDetailsUrl}>
                                <div className="font-thin uppercase text-xl">{agent.fullName}</div>
                              </a>
                              <div className="text-base uppercase font-bold">{agent.location}</div>
                              <div className="text-lg uppercase font-thin">{agent.title}</div>
                              <div className="flex flex-row gap- items-center">
                                {agent.rating > 0 && (
                                  <StarRatings
                                    rating={agent.rating}
                                    starRatedColor="#ff6d41"
                                    // changeRating={this.changeRating}
                                    numberOfStars={5}
                                    starDimension="16"
                                    starSpacing="2px"
                                    name="rating"
                                  />
                                )}

                                {agent.reviewsCount > 0 && <div className="mt-1 text-xs ml-2">({agent.reviewsCount} reviews)</div>}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2 md:gap-6 flex-1 px-2 md:pr-2 md:pl-0">
                            <div className="hidden md:flex flex-row justify-between">
                              <div className="hidden md:flex flex-col gap-1 mt-2">
                                <a href={agent.agentDetailsUrl}>
                                  <div className="font-base uppercase text-2xl">{agent.fullName}</div>
                                </a>
                                <div className="text-sm uppercase">{agent.title}</div>
                                <div className="text-sm uppercase  font-bold">{agent.location}</div>
                              </div>
                              <div>
                                <div className="hidden md:flex flex-row gap-1">
                                  <a
                                    href={agent.agentDetailsUrl}
                                    className="bg-gtc7 hover:bg-black w-20 h-12 flex justify-center items-center cursor-pointer">
                                    <i role="presentation" className="fa-solid fa-user fa-2xl text-white"></i>
                                  </a>
                                  {agent.hasEmail && (
                                    <button
                                      className={[
                                        'w-20 h-12 flex justify-center items-center',
                                        agent.isOffline ? 'cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-gtc7 hover:bg-black '
                                      ].join(' ')}
                                      disabled={agent.isOffline}
                                      title={`Email ${agent.fullName}`}
                                      onClick={() => {
                                        setSelectedAgent(agent);
                                        setModalOpen('Email');
                                      }}
                                      aria-label={`Email ${agent.fullName}`}
                                      role="button"
                                      tabIndex={0}>
                                      <i role="presentation" className="fa-solid fa-envelope fa-2xl text-white"></i>
                                    </button>
                                  )}
                                  {agent.hasPhone && (
                                    <button
                                      className={[
                                        'w-20 h-12 flex justify-center items-center',
                                        agent.isOffline ? 'cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-gtc7 hover:bg-black '
                                      ].join(' ')}
                                      disabled={agent.isOffline}
                                      title={`Call ${agent.fullName}`}
                                      onClick={() => onCall(agent)}
                                      aria-label={`Call ${agent.fullName}`}
                                      role="button"
                                      tabIndex={0}>
                                      <i role="presentation" className="fa-solid fa-phone fa-2xl text-white"></i>
                                    </button>
                                  )}
                                  {/* {agent.hasVideoAppointment && (
                                    <button
                                      className={[
                                        'w-20 h-12 flex justify-center items-center',
                                        agent.isOffline
                                          ? 'cursor-not-allowed bg-gray-400'
                                          : 'cursor-pointer bg-gtc7 hover:bg-black '
                                      ].join(' ')}
                                      disabled={agent.isOffline}
                                      title={`Meet ${agent.fullName}`}
                                      onClick={() => {
                                        setSelectedAgent(agent);
                                        setModalOpen('Meet');
                                      }}>
                                      <i role="presentation" className="fa-solid fa-calendar-days fa-2xl text-white"></i>
                                    </button>
                                  )} */}
                                </div>
                                <div className="hidden md:flex flex-row gap-2 my-2 justify-center items-center">
                                  {agent.rating > 0 && (
                                    <StarRatings
                                      rating={agent.rating}
                                      starRatedColor="#ff6d41"
                                      // changeRating={this.changeRating}
                                      numberOfStars={5}
                                      starDimension="20"
                                      starSpacing="2px"
                                      name="rating"
                                    />
                                  )}

                                  {agent.reviewsCount > 0 && <div className="mt-1">({agent.reviewsCount} reviews)</div>}
                                </div>
                              </div>
                            </div>
                            {agent.isOffline && (
                              <div className="flex-1 flex flex-row pr-5 justify-center">
                                <div className="font-bold text-base md:text-lg">{agent.isOfflineMsg}</div>
                              </div>
                            )}
                            <div className="flex flex-row gap-0 md:gap-4 justify-between text-sm md:text-base text-left mt-1 md:mt-0">
                              <div className="flex-1 flex flex-col pr-5">
                                <div className="font-bold">DESTINATIONS</div>
                                <div className="text-base">{agent.destinations.join(', ')}</div>
                              </div>
                              <div className="flex-1  flex flex-col ">
                                <div className="font-bold">SPECIALTIES</div>
                                <div className="text-base">{agent.interests.join(', ')}</div>
                              </div>
                            </div>
                            <div className="flex md:hidden flex-row gap-1 justify-end">
                              <a
                                href={agent.agentDetailsUrl}
                                className="bg-gtc7 hover:bg-black w-16 h-10 flex justify-center items-center cursor-pointer">
                                <i role="presentation" className="fa-solid fa-user fa-xl text-white"></i>
                              </a>
                              {agent.hasEmail && (
                                <button
                                  className={[
                                    ' w-16 h-10 flex justify-center items-center',
                                    agent.isOffline ? 'cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-gtc7 hover:bg-black '
                                  ].join(' ')}
                                  disabled={agent.isOffline}
                                  title={`Email ${agent.fullName}`}
                                  onClick={() => {
                                    setSelectedAgent(agent);
                                    setModalOpen('Email');
                                  }}>
                                  <i role="presentation" className="fa-solid fa-envelope fa-xl text-white"></i>
                                </button>
                              )}
                              {agent.hasPhone && (
                                <button
                                  className={[
                                    ' w-16 h-10 flex justify-center items-center',
                                    agent.isOffline ? 'cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-gtc7 hover:bg-black '
                                  ].join(' ')}
                                  disabled={agent.isOffline}
                                  title={`Call ${agent.fullName}`}
                                  onClick={() => onCall(agent)}>
                                  <i role="presentation" className="fa-solid fa-phone fa-xl text-white"></i>
                                </button>
                              )}
                              {/* {agent.hasVideoAppointment && (
                                <button
                                  className={[
                                    ' w-16 h-10 flex justify-center items-center',
                                    agent.isOffline
                                      ? 'cursor-not-allowed bg-gray-400'
                                      : 'cursor-pointer bg-gtc7 hover:bg-black '
                                  ].join(' ')}
                                  disabled={agent.isOffline}
                                  title={`Meet ${agent.fullName}`}
                                  onClick={() => {
                                    setSelectedAgent(agent);
                                    setModalOpen('Meet');
                                  }}>
                                  <i role="presentation" className="fa-solid fa-calendar-days fa-xl text-white"></i>
                                </button>
                              )} */}
                            </div>
                          </div>
                        </div>
                        {agent.topReviewName !== '' && (
                          <div className="flex flex-col gap-1 p-2  text-center md:text-left px-4 md:px-2">
                            <div className="font-bold"> {agent.topReviewName} says</div>
                            <em className="px-1" style={{ fontSize: '16px' }}>
                              {'"'}
                              {agent.topReviewText.substring(0, 225).trimEnd()}
                              {'"...'}
                              <a href={`${agent.agentDetailsUrl}?s=review`}>Read More</a>
                            </em>
                          </div>
                        )}
                      </div>
                      {ads.length > 0 && <>{ad(index + 1)}</>}
                    </React.Fragment>
                  );
                })}
                {(totalAgents > agents.length || (totalAgents > 20 && agents.length <= 20)) && (
                  <div className="flex justify-center">
                    <button
                      className="py-2 font-normal text-center text-white bg-black border border-black
                hover:font-semibold px-7 hover:cursor-pointer uppercase w-full"
                      onClick={() =>
                        //@ts-ignore
                        setFilters({ ...filters, currentPage: filters.currentPage + 1 })
                      }>
                      Show me more agents
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        <div className="m-4">
          <AgentSearchFooter />
        </div>
      </div>

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {selectedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...selectedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && <PhoneForm agent={selectedAgent} callback={() => setModalOpen('Email')} />}
              {modalOpen === 'Email' && <EmailForm agent={selectedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Agents;
