/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, createContext, useEffect } from 'react';
import { Interests } from '../helpers/types';

import APIService from '../services/services';

type AppContextType = {
  destinations: any[];
  specialities: Interests[];
  states: any[];
};

const AppContext = createContext<AppContextType>({
  destinations: [],
  specialities: [],
  states: []
});

const AppContextProvider = (props: any) => {
  const [appState, setAppState] = useState<AppContextType>({
    destinations: [],
    specialities: [],
    states: []
  });

  const fetchLookups = async () =>
    Promise.all([
      APIService()
        .gtcService.fetchInterests()
        .then((response) => response),
      APIService()
        .gtcService.fetchDestinations()
        .then((response) => response)
    ]);

  useEffect(() => {
    fetchLookups().then((result) => {
      setAppState({
        ...appState,
        //states: result[0].responseStatus === 0 ? [] : result[0].data,
        specialities: result[0].responseStatus === 0 ? [] : result[0].data,
        destinations: result[1].responseStatus === 0 ? [] : result[1].data
      });
    });
  }, []);

  return <AppContext.Provider value={appState}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
